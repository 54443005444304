
.h_750 {
    height: 750px;
    display: flex;
    flex-direction: column;
}
.h_730 {
    height: 730px;
    display: flex;
    flex-direction: column;
}
.h_700 {
    height: 700px;
    display: flex;
    flex-direction: column;
}
.h_675 {
    height: 675px;
    display: flex;
    flex-direction: column;
}
.h_1180 {
    height: 1180px;
    display: flex;
    flex-direction: column;
}
.h_1170 {
    height: 1170px;
    display: flex;
    flex-direction: column;
}
.h_1150 {
    height: 1150px;
    display: flex;
    flex-direction: column;
}

.funSoratCover {
     position: fixed;
     top: 92px;
     bottom: 0;
     left: 0;
     right: 0;
     background: transparent;
     /* background: #00000082; */
     z-index: 999;
 }
 
.funSoratTable tr td {
    border: solid 1px rgb(255, 215, 0);
    position: relative;
    background: chocolate;
    cursor: pointer;
}

.funSoratTable {
    color: #fff;
    height: 450px;
    width: 100%;
    float: left;
    text-align: center;
    border: solid 3px rgb(255, 215, 0);
    border-spacing: 0px;
    z-index: 137;
    position: relative; 
    box-shadow: 8px 8px 0px 0px rgb(0 0 0 / 30%);
    margin-top: 40px;
}

.funSoratTable tr:nth-child(1) td:nth-child(1) {
    background: #61ffda !important;
}

.funSoratTable tr:nth-child(1) td:nth-child(even), .funSoratTable tr:nth-child(2) td:nth-child(even), .funSoratTable tr:nth-child(3) td:nth-child(odd) {
    background: radial-gradient(#0d2c5f, #0055e0);
}

.funSoratTable tr:nth-child(1) td:nth-child(odd), .funSoratTable tr:nth-child(2) td:nth-child(odd), .funSoratTable tr:nth-child(3) td:nth-child(even) {
    background: radial-gradient(#024c6b, #06abef);
}

.fun_chips {
    width: 20%;
    float: left;
}
.inSoratTable {
    width: 55%;
    float: left;
    padding: 15px;
    margin-top: 30px;
}
.inSoratWheel {
    width: 45%;
    float: left;

}
.inSoratLeft {
    width: calc(100% - 150px);
    float: left;
}
.inSoratRight {
    width: 150px;
    float: left;
}

.fun_btns {
    width: 20%;
    padding: 10px;
    padding-right: 20px;
}

.indSoratBln .funBal {
    background: transparent;
    text-align: center;
    color: #fff;
    padding: 1px;
    font-size: 25px;
    margin-bottom: 5px;
    flex-grow: 1;
    display: flex;
    height: 80px;
    justify-content: center;
}
.indSoratBln {display: flex;}
.indSoratBln .funBal div:nth-child(1) {
    background: linear-gradient(#2cff00, #007634);
    padding: 0px;
    border-radius: 50px 0px 0px 50px;
    width: 224px;
    padding-top: 15px;
    box-shadow: 1px 1px 9px 8px rgb(0 0 0 / 30%);
}

.indSoratBln .funBal div:nth-child(2) {
    background: linear-gradient(#fffe00, #ff5200);
    padding: 4px 0px;
    color: #000;
    width: 300px;
    border-radius: 0px 50px 50px 0px;
    padding-top: 15px;
    box-shadow: 1px 1px 9px 8px rgb(0 0 0 / 30%);
}

.chips .col-6 {
    padding: 5px;
}

.chips .col-3 {
    padding: 5px !important;
}
.timer {
    background: radial-gradient(red, #ff8100);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    padding-top: 6px;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    border: solid 4px #d60000dd;
}

button.bt_1_bdr.p_2 {
    background: linear-gradient(#4a0000, #b74949, #4a0000);
    border: none;
    border-radius: 5px;
    padding: 2px;
}

.FGAarntRightButtonPanel{ 
    right: 20px;
    top: 20vh;  
}
  
.FGAarntRightButtonPanel button{
    font-size: 13px; 
}
 

.FGAarntRightButtonPanel label{
    color: #fff;
    background-color: #f40037;
    margin-top: 5px;
    float: left; 
}
.FGAarntRightButtonPanel input{
    margin-top: 5px;
    background: #070a0e;
    border: none;
    color: #fff;
    text-align: center;
    float: left;
    padding:5px;
    width: 50px;
} 
.history.soratHis ul {
    width: 150px;
    margin-left: -22px;
    font-size: 45px;
    display: flex;
    flex-wrap: wrap;
}

.FGAfunSoratTimer .history.soratHis ul {
    width: 100%;
    margin-left: -22px;
    font-size: 40px;
    display: flex;
    flex-wrap: wrap;
    float: left;
    justify-content: space-evenly;
}
.history ul {
    all: unset;
    width: 100%;
    display: flex;
    margin-bottom: 15px;
}

.history ul li img { width: 100%; }
.history ul li:nth-child(odd)  { background: radial-gradient(#fb9172, #e5400f); }
.history ul li:nth-child(even)  { background: radial-gradient(#61be00, #142700); }
.history ul li {
    width: 70px;
    height: 70px;
    float: left;
    list-style: none; 
    padding: 0px;
    border: solid 1px #ffd700;
    border-radius: 50%;
    padding: 5px !important;
}
.userLbl img { width: 45px; margin-right: 5px; }
.userLbl {
    background: #000000a8;
    border-radius: 5px;
    padding: 15px;
    width: 292px;
    margin-bottom: 15px;
    color: #fff;
    border: solid 1px #ffffff36;
    font-size: 28px;
    display: flex;
    height: 87px; 
    justify-content: flex-start;
    align-items: center;
}
.titleImg_sorat {height: 110px;margin-top: 1vh;
animation: sotat_name_Light 0.8s infinite;
} 
@keyframes sotat_name_Light {
    0% {
      border: dotted 0.8vh #fcfcfc;
    }
    50% {
      border: dotted 0.8vh #ff0;
    }
    100% {
      border: dotted 0.8vh #ffffff;
    }
  }
.funSoratTable tr td img.tabImg {
    margin-bottom: -5px;
}
.history {
    font-size: 22px;
}
.fun_btns button.bt_1_bdr {
    margin-bottom: 15px;
}
.timer.timerinactive {background: radial-gradient(#565656, #757575); border: solid 4px #949494dd; }

.goldCoin img{ width: 100% ;}
.leftVal .goldCoin, .cntrVal .goldCoin {
    left: -25px;
}

.goldCoin {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.goldCoin span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 24px;
    margin: auto;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.goldCoin_sorat img{ width: 100% ;}
.FGAfunSoratTimer .boxLeft .goldCoin_sorat ,  .FGAfunSoratTimer .boxCrnr .goldCoin_sorat {
    left: -36px;
}
.goldCoin_sorat {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 1px 1px 9px 8px rgb(0 0 0 / 50%);
  border-radius: 50%; 
}
.goldCoin_sorat span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
img.chp_img {
    width: 120px;
    transition: all 0.5s;
    margin-bottom: 7px;
}
img.chp_img:hover { cursor: pointer; transform: scale(1.15); }
.fd.chips.box_clm .col-6:nth-last-child(1) img {
    width: 30%;
}
.fd.chips.box_clm .col-6:nth-last-child(1) {
    max-width: 100%;
    flex: 0 0 100%;
}
 

.soratTableCoverForTake {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    z-index: 999;
    display: none;
}



.contFlex{
    display: flex; /* add -webkit and -ms prefixes yourself */
    flex-direction: column;
    height: 100vh;
}
.box_clm{
    flex-grow: 1;
}
.boxRight.d_block {display: block;}
.boxRight.d_none {display: none;}

.boxCntr.active {
    color: #FBEC0F;
    animation: winBox 0.5s infinite;
}

@keyframes winBox {
    0% { box-shadow: inset 0 0 143px #fff900 }
    50% { box-shadow: inset 0 0 0px #fff900; }
    100% { box-shadow: inset 0 0 143px #fff900 }
    
}
.betXiMG_Marq, .betXiMG {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    border: solid 2px #61be00;
    display: flex;
    background: #ffbd00;
    justify-content: center;
    box-shadow: inset 0px 0px 13px rgb(0 0 0 / 50%);
    top: 0;
    bottom: 0;
}
div#marqueeTag .soratMarque img {
    height: 100px;
    width: 100px;
    margin-top: 20px;
}
.betXiMG { display: none;}
 .betXiMG img { 
    transition: all 0.5s;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100px; 
    width: 120px; 
    transition: all 0.5s;
}
/* .betXiMG img { display: none; } */
.betXiMG img.activeImg { display: block; }
.fd.forAmsu {background: #ddd;
text-align: center;
margin-top: 5px;
font-size: 17px;
font-weight: bold;
border-radius: 3px;
}
img.tabImg {
    /* height: 130px; */
    height: 170px;

}
 
 
.F_Sorat_ins .ins {
    width: 100%;
    float: left;
    background: linear-gradient( #a7a7a7, #fff);
    border: solid 1px #ddd;
    font-size: 31px;
    color: #000;
    padding: 10px;
}
ul.FS_History li span {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    color: #ffcc3c;
    text-shadow: 1px 1px 4px #000;
    font-weight: bold;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
ul.FS_History li {
    position: relative;
    text-align: center;
    display: flex;
    padding: 4px;
}

.zin_360{
    z-index: 1020;
}

.zin_1050{
    z-index: 1050;
}
.boxTop {
    width: calc(100% - 20px);
    height: 20px;
    /* background: #f00; */
    background: transparent;
    font-size: 0px;
    margin-bottom: 10px;
    position: absolute;
    z-index: 90;
    margin: auto;
    left: 0;
    right: 0;
    top: -10px;
}
.boxRight p.goldCoin {
    left: -2px;
}
.boxRight p.goldCoin_sorat {
    left: -38px;
}
 
 
marquee.soratMarque {
    height: 75%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
marquee.soratMarque img {
    padding: 0.1vw 2vw;
    margin-right: 25px;
}
 
 
 
#soratWheelContainer {margin: auto;
    box-shadow: 1px 1px 36px 23px rgb(97 255 218 / 35%);
    border-radius: 50%;
}
#soratWheelContainerOuter .konvajs-content, #funSoratWheelContainer .konvajs-content {
    margin: auto;
} 

.loaderSorat {
    width: 50px;
    height: 50px;
    position: fixed;
    background: #000;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}
.FGAfunSoratTimer .timer_Sec {
    width: 150px !important;
    height: 150px !important;
    font-size: 75px !important;
}
.fd.fun_trg_timer_btns {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.userLbl.soratUser {width: 440px;}
.soratBx {
    display: flex;
    align-items: flex-end; 
}
.flex_clmn {
    flex-direction: column;
}
.indSoratChips {
    display: flex;
    justify-content: space-around;
}
.indSoratBtns {
    display: flex;
}

.indSoratBtns button:hover {
    background: linear-gradient(#ff5200, #fffe00);
}
.indSoratBtns button:nth-child(odd) {
    background: linear-gradient(#fffe00, #ff5200);
    border: solid 6px #ff4600;
}
.indSoratBtns button:nth-child(even) {
    background: linear-gradient(#2cff00, #007634);
    border: solid 5px #00ff29;
}

.indSoratBtns button {
    margin-right: 10px;
    color: #000;
    text-transform: uppercase;
    font-size: 30px;
    transition: all 0.5s;
    padding: 4px;
    height: 100px;
    font-weight: bold;
    border-radius: 88px;
    box-shadow: 7px 11px 0px 0px rgb(0 0 0 / 36%);
}
.indSoratChips .chipSorat img.chp_img  {width: 100px;}
#soratWheelContainerOuter {
    width:100%;
    height:100%; 
    position:absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: linear-gradient(45deg, #ffe000, #ff9800, #ffe500);
    border-radius: 50%;
}

.soratWheelContainerDots {
    width: 97%;
    height: 97%;
    border: dotted 18px #ff8d00;
    border-radius: 50%;
    margin-left: 13px;
    margin-top: 12px;
    animation: soratDots 0.5s infinite;
}

@keyframes soratDots {
    0%{ border: dotted 18px #ff8d00; }
    50%{ border: dotted 18px rgb(255, 230, 0); }
    100%{ border: dotted 18px #ff8d00; }
}

.FGAfunSoratTimer img.chp_img {
    width: 120px;
    transition: all 0.5s;
    margin-bottom: 7px;
    box-shadow: 1px 6px 6px 7px rgb(0 0 0 / 50%) !important;
    border-radius: 50%;
    margin-top: 2px;
}
.FGAfunSoratTimer img.chp_img.active {
    border: solid 3px #ffba00;
    box-shadow: 1px 2px 8px 10px rgb(233 255 0 / 50%) !important;
}

.indSoratTitle img.title {
    width: 850px;
    margin-bottom: 23px;
}

.soratUser {
    width: 300px;
    background:linear-gradient(#fffe00, #ff5200);
    font-size: 32px;
    text-align: center;
    border-radius: 50px;
    padding: 13px;
    font-weight: bold;
    border: solid 5px #ff5000;
    box-shadow: 1px 1px 1px 4px rgb(0 0 0 / 20%);
    float: left;
    height: 77px;
}

div.SoratTimer_Sec {
    width: 300px;
    background: linear-gradient(#2cff00, #007634);
    font-size: 32px;
    text-align: center;
    border-radius: 50px;
    padding: 13px;
    font-weight: bold;
    border: solid 5px #00ff29;
    box-shadow: 1px 1px 1px 4px rgb(0 0 0 / 20%);
    float: right;
    height: 77px;
}

.F_Sorat_ins {
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden;
    padding: 0px 5px;
    bottom: 0;
    background: linear-gradient(#0446b1, #011b47);
    color: #fff;
    padding: 15px;
    font-size: 30px;
    margin-top: 50px;
    border-radius: 30px;
    height: 100px;
    justify-content: center;
    align-items: center;
    border: solid 4px #0061ff;
    display: flex;
    box-shadow: 8px 8px 0px 0px rgb(0 0 0 / 50%);
}
.soratMarqueJack {
    width: 230px;
    height: 230px;
    background: #ffe600;
    border-radius: 50%;
    position: relative;
    margin: auto;
    top: 255px;
    padding-top: 14px;
    box-shadow: 1px 1px 9px 4px rgb(0 0 0 /50%);
}

.FGAfunSoratTimer .winPop span {
    font-size: 60px;
}
.FGAfunSoratTimer .winPop {
    /* display: none; */
    position: fixed;
    width: 300px;
    height: 300px;
    background: linear-gradient(45deg, #ffa400, #ffe400);
    border-radius: 50%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #000;
    padding: 10px;
    transition: all 0.5s;
    animation: soratWinPop 3s 1; 
    box-shadow: 0px 0px 115px 42px rgb(0 0 0);
}

@keyframes soratWinPop {
    0% {transform: scale(0); opacity: 0;}
    20% {transform: scale(1); opacity: 1;}
    80% {transform: scale(1); opacity: 1;}
    90% {transform: translate(-650px, -430px ); }
    100% {transform: scale(0); opacity: 0;}
}



.FGAfunSoratTimer .winPop .popBorder {
    text-align: center;
    width: 100%;
    height: 100%;
    border: dotted 8px #e38a00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 50px;
}

.indSoratBtns button:disabled {
    background: linear-gradient(#8d8d8d, #0c0c0c);
    border: solid 6px #1e1e1e;
}
.winSorat {
    animation: winSoratBalance 0.5s 1;
}
@keyframes winSoratBalance {
    0%{transform: scale(1); }
    50%{transform: scale(1.8); }
    100%{transform: scale(1); }
}


.SoratTimer_Sec.timerYelloBorder { 
    animation: borderAnim 1s infinite;
}
@keyframes borderAnim {
    from {border: solid 4px #fffe00dd; }
    to {border: solid 4px #d60000dd; } 
}
.soratHidPid {
    display: flex;
    /* all: unset; */
    justify-content: space-evenly;
    width: 100%;
    float: left;
    margin-top: 20px;
}
.soratHidPid li { list-style: none; color: #fff;font-size: 28px;}


.ballBg {
    position: absolute;
    width: 480px;
    height: 480px;
    left: 0;
    right: 0;
    margin: auto;
}



img.rollBall {
    position: absolute;
    left: 0;
    right: 0;
    transition: all 1s;
    transform: translateY(-240px);
    top: 0;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 35px;
}


.bollWrap {
    width: 480px;
    height: 480px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.wheelBorder{
    margin: auto;
    
}

.funsoratWheelWraper {
    width: 1000px;
    height: 480px;
    position: relative;
    margin: auto;
}


.wheelAnimParent {
    width: 740px;
    height: 740px;
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    -webkit-animation:fs_spin 25s linear infinite;
    -moz-animation:fs_spin 25s linear infinite;
    animation:fs_spin 25s linear infinite;
    
}
/* @-moz-keyframes fs_spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes fs_spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes fs_spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } } */

.wheelAnim img { width: 100%;}
.wheelAnim {
    position: relative;
    width: 650px;
    height: 650px;
    margin: auto;
    border-radius: 50%;
    top: 45px;
}

.wheelAnimOne {
    width: 740px;
    height: 740px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    /* transform: scale(1.4, 0.62);
    margin-top: -23px; */
}

.fst_wheelBorder {
    margin: auto;
    position: absolute;
    z-index: 9;
    width: 727px;
    top: 8px;
    left: 6px;
}

.ftt_wheelBorder img {
    width: 769px;
    height: 835px;
  }

.z_1024{
    z-index: 1024;
}

.fst_chip0 {
    position: absolute;
    color: black;
    font-size: 25px;
    left: 57px;
    top: 34px;
    margin: 0px;
}
.fst_chip1 {
    color: black;
    position: absolute;
    top: 34px;
    font-size: 25px;
    margin: 0px;
    left: 180px;
}
  
.fst_chip2 {
    color: black;
    position: absolute;
    left: 301px;
    font-size: 25px;
    margin: 0px;
    top: 34px;
}

.fst_chip3 {
    color: black;
    position: absolute;
    top: 34px;
    font-size: 25px;
    margin: 0px;
    left: 424px;
}
.fst_chip4 {
    color: black;
    position: absolute;
    top: 34px;
    font-size: 25px;
    margin: 0px;
    left: 543px;
}
.fst_chip5 {
    color: black;
    position: absolute;
    right: 430px;
    top: 34px;
    font-size: 25px;
    margin: 0px;
}
  
.fst_chip6 {
    color: black;
    position: absolute;
    right: 302px;
    top: 34px;
    font-size: 25px;
    margin: 0px;
}
.fst_chip7 {
    color: black;
    position: absolute;
    right: 178px;
    top: 34px;
    font-size: 25px;
    margin: 0px;
}


.chipImg{
    cursor: pointer;
  }

.tooTip{
    position: relative;
    display: flex;
}

.fst_overlay_text {
    text-align: center;
    position: relative;
    top: 20%;
    right: 0%;
    transform: translate(0%, -210%);
    font-size: 25px;
    bottom: 24%;
    color:black !important ;
}
.fstbordercover {
    position: relative;
    top: 856px;
    left: 5px;
    /* background: red; */
    width: 1500px;
    height: 35px;
    z-index: 500;
}