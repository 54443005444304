  

#jacksorBetterBgContainer button:active{
    scale: 0.8;
    opacity: 1;
  }

  
#jacksorBetterBgContainer button:disabled{
    scale: 1;
    opacity: 0.5;
  }
  
  .betButtonImagesPrt{
    width: 100%;
  }

  .autoholdButton{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 5%;
    height: 8%;
    bottom: 3%;
    left: 6%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoholdButtonPrt{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 16%;
    height: 8%;
    bottom: 3%;
    left: 3%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoholdButtonLnd{
    position: absolute;
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 8%;
    height: 12%;
    bottom: 3%;
    left: 3%;
    background-image: linear-gradient(to left, #094d57, #00abb4, #094d57);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButton{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 5%;
    height: 8%;
    position: absolute;
    bottom: 3%;
    left: 12%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButtonPrt{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 16%;
    height: 8%;
    position: absolute;
    bottom: 3%;
    right: 3%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .autoplayButtonLnd{
    border: 2px solid #7fd6e1a1;
    border-radius: 10%;
    width: 8%;
    height: 12%;
    position: absolute;
    bottom: 3%;
    right: 3%;
    background-image: linear-gradient(to left, #e30a3a, #d52e53e3, #e30a3a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .autoplayButton{
    animation: blink normal 1s infinite ease-in-out;
  } */

  .borderGlow{
    animation: borderBlinkJs 1s infinite cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 0px 40px #ff8d00f5;
  }

  tbody div{
    position: absolute;
    position: absolute;
    width: 100%;
    height: 12%;
  }

  .glowPayoutInfo{
    animation: borderBlinkJs 1s infinite cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0px 0px 40px #ff8d00f5;
  }
  @keyframes borderBlinkJs {
    0% {
      border:4px solid rgb(255, 60, 0);
      
    }
    50% {
      border: transparent;
    }
    100% {
      border: 4px solid rgb(255, 255, 0);
    }
  }
  
  .chipTextGlow{
    /* animation: chipTextBlink 1s infinite ease-in-out; */
    animation: chipTextBlink 1s infinite cubic-bezier(0, 0, 0.2, 1) ;
  }
  @keyframes chipTextBlink {
    0% {
      color: rgb(255, 255, 0);
      
    }
    50% {
      border-color:rgb(255, 255, 0);
    }
    100% {
      color: rgb(255, 255, 0);
    }
  }

  .payoutContainer{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 50%;
    top: 6%;
    z-index: 3;
    line-height: 1;
  }

  .payoutContainerPrt{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 70%;
    top: 20%;
    z-index: 3;
    line-height: 1;
  }
  .payoutContainerLnd{
    position: absolute;
    color: aliceblue;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 50%;
    top: 8%;
    z-index: 3;
    line-height: 1;
  }


  .tableContainer{
    width: 100%;
    font-size: 30px;
    /* font-size: 25px; */
  }

  /* table tr:hover {
    border: 2px solid orange;
    /* background-color: #f0f0f0; */
  /* } */ */

  #jacksorbetterTable table {
    border-collapse: collapse;
    border-spacing: 5;
  }
#jacksorbetterTable table tr td{
  padding: 3px;
  padding-left: 2%;
  text-align: center;
}
#jacksorbetterTable table tr td:first-child{
  text-align: left;
}

.tablerow0{
  background-image: linear-gradient(45deg, #424243,#3e1881, #180834);
  border-radius: 10px;
  
}
.tablerow1{
  background-image: linear-gradient(45deg, #62686d,#2e6dd177, #2a2749);
  
}
.tablerow2{
  background-image: linear-gradient(45deg, #62686d,#23c574e3, #035458);
  
}
.tablerow3{
  background-image: linear-gradient(45deg, #62686d,#1cd3cae3, #007454);
  
}
.tablerow4{
  background-image: linear-gradient(45deg, #62686d,#925f859f, #00c588);
  
}
.tablerow5{
  background-image: linear-gradient(45deg, #62686d,#c5236fe3, #a2dc76);
  
}
.tablerow6{
  background-image: linear-gradient(45deg, #62686d,#9ac523e3, #f9c160);
  
}
.tablerow7{
  background-image: linear-gradient(45deg, #62686d,#c57a23e3, #ee6c43);
  
}
.tablerow8{
  background-image: linear-gradient(45deg, #62686d,#d34040e3, #ee0049);

}

.errorPopupContainerwindows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 35%;
  height: 296px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  /* visibility: visible;
  opacity: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorPopupContainerPrt {
  width: 60%;
 
}

.popupbetshighPortrait{
  position: absolute;
  top: 780px;
  left: 145px;
  width: 900px;
  height: 450px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 1500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupbetshighLndsp{
  position: absolute;
  top: 400px;
  right: 0;
  left: 0;
  margin: auto;
  width: 900px;
  height: 450px;
  background: hsl(0deg 0% 97% / 82%);
  z-index: 1500;
  box-shadow: 20px 20px 5px #0c0303;
  border-radius: 30px;
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}

.errorPopupExitIcon{
  position: absolute;
  right: 2%;
  top: 2%;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 100;
}
.errorPopupTitle{
  position: relative;
  right: 2%;
  top: 6%;
  font-size: 40px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 100;
  margin-bottom: 6%;
}

.errorPopupInfo{
  position: relative;
  font-size: 30px;
  color: #230303;
  font-weight: bold;
  font-family: "Poppins";
  text-align: center;
  line-height: 1;
}

.coverContainer{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #04040487;
  z-index: 20;
}
.betChipscoverContainer{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #d5ebe400;
  z-index: 20;
}

.autoLimitContainer{
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.autoLimitContainer button{
  font-size: 40px;
  border: 2px solid #ff000052;
  padding: 4%;
  border-radius: 100px;
  margin-bottom: 10%;
}
.autoLimitContainer button:first-child{
  background-color: #00abb471;
}
.autoLimitContainer button:last-child{
  background-color: #00b46fa1;
}

.menuOptionContainer{
  position: relative;
  background: azure;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10%; */
  border-radius: 100px;
  height: 40%;
  top: 10%;
  left: 1%;
  z-index: 21;
}
.menuOptionContainerPrt{

  position: relative;
  background: azure;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10%; */
  border-radius: 50px;
  height: 15%;
  top: 6%;
  left: 1%;
  z-index: 21;



  /* position: relative;
  background: azure;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8%;
  height: 7%;
  width: 69%;
  top: 3%;
  left: 0%;
  right: 0;
  margin: auto;
  z-index: 21; */
}

.menuOptionContainer button{
  padding: 10px;
  border-radius: 46%;
  color: #ffffff;
  height: 20%;
  font-size: 40px;
  width: 82%;
}
.menuOptionContainerPrt button{
  padding: 10px;
  border-radius: 46%;
  color: #ffffff;
  height: 30%;
  font-size: 40px;
  width: 90%;
  margin-bottom: 10%;
}

.menuOptionContainer button:first-child{
  margin-bottom: 20px;
  
  
}
.menuOptionContainer button:last-child{
  
}

.borderColor{
  border: 2px solid #131D50;
  color: #131D50 !important;

}
.backgroundColor{
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
}


.helpMainContainer{
  border: 2px solid #3c45be;
  width: 80%;
  position: relative;
  left: 8%;
  height: 70%;
  top: -30%;
  bottom: 0;
  margin: auto;
  overflow: auto;
  font-size: 25px;
  color: #ffffff;
  line-height: 1.5;
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
  border-radius: 25px;
  padding: 2%;
  z-index: 21;
}
.helpMainContainerPrt{

  border: 2px solid #3c45be;
  width: 95%;
  position: relative;
  left: 0%;
  height: 40%;
  top: 8%;
  bottom: 0;
  margin: auto;
  overflow: auto;
  font-size: 25px;
  color: #ffffff;
  line-height: 1.5;
  background-image: linear-gradient(#040610, #131D50, #0E1E33);
  border-radius: 25px;
  padding: 2%;
  z-index: 21;


      /* border: 2px solid #3c45be;
      width: 100%;
      position: relative;
      left: 3%;
      height: 35%;
      top: 25%;
      bottom: 0;
      margin: auto;
      overflow: auto;
      font-size: 25px;
      color: #ffffff;
      line-height: 1.5;
      background-image: linear-gradient(#040610, #131D50, #0E1E33);
      border-radius: 25px;
      padding: 2%;
      z-index: 21; */
}

#GameHelpContent ol,#GameHelpContent p{
  margin: 0 0 0 50px;

}

.helpMainContainer table ,.helpMainContainerPrt table{
  border-collapse: separate;
  border-spacing: 10px;
  width: 100%;
  height: auto;
  font-size: 34px;
  font-family: 'Poppins';
  margin: 10px 0;
  line-height: 1 !important;
}

.helpMainContainer tbody,.helpMainContainerPrt tbody{
  width: 100%;
  text-align: center;
  font-size: 34px;
  color: white;
  font-family: 'Poppins';
  position: relative;
  top: 50px;
}

.helpMainContainer tbody td{
text-align: center !important;
}


tbody div::before {
  /* position: absolute;
  width: 100%;
  height: 20px;
  background: #f5f5f5; 
  border-radius: 0 0 50% 50%; 
  z-index: 1;
  overflow: hidden; */
  content: "";
  position: absolute;
  left: -3%;
  height: 90%;
  width: 3%;
  background: rgba(221, 113, 113, 0.301);
  border-radius: 50% 0% 0% 50%;
}

tbody div::after {
  /* content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, 0.1); */

  content: "";
  position: absolute;
  left: 99%;
  height: 90%;
  width: 3%;
  background: rgba(96, 183, 209, 0.37);
  border-radius: 0% 50% 50% 0%;
}

.winPopupForJrbtIntl{
  visibility: hidden;
  opacity: 0;
}

.winPopupJrb {
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  margin: auto;
  width: 100%;
  max-width: 400px;
  height: 120px;
  border: solid 6px yellow;
  border-radius: 50px;
  /* animation: blink normal 1s infinite ease-in-out; */
  animation: borderBlink 0.3s infinite ease-in-out;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  
}
@keyframes borderBlink {
  0% {
      border-color: yellow;
  }
  50% {
      border-color: transparent;
  }
  100% {
      border-color: yellow;
  }
}

.winamtMpJrb{
  color: #ffffff;
  font-size: 40px;
  text-align: center;
}

.payOutHand{
  position: absolute;
  left: 0%;
  right: 0%;
  margin: auto;
  font-size: 40px;
  color: #fff;
  text-align: center;
  background: linear-gradient(45deg, #ff6f61, #ffba08);
  /* background: linear-gradient(45deg, #ffba08, #ff6f61); */
  /* background: radial-gradient(circle, #42a5f5, #1e88e5, #1565c0); */
  /* background: linear-gradient(to right, #ff6f61, #ffba08, #6bcbef, #9c27b0); */
  /* border-image-source: linear-gradient(45deg, #42a5f5, #ff6f61); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  border-radius: 50px;
  padding: 14px;
}
.payOutHandWds{
  top: 75%;
}
.payOutHandPrt{
  top: 75%;
}
.payOutHandLnd{
  top: 82%;
}