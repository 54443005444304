.jacksCardContainer span{
        position: absolute;
        left: 0;
        right: 0;
        bottom: -60%;
        margin: auto;
        width: 100%;
        text-align: center;
        pointer-events: none;
    
}

.jacksCardContainer button:active{
    scale: 1 !important;
}
.jacksCardContainer button{
   width: 100%;
}