.FGAfunTargetTimerClose{
        position:absolute;
        top:10px;
        right:10px;
       
}
.FGAfunTargetTimerClose button{
    width:40px;
    height:40px;
    border:2px solid rgba(255, 230, 85, 0.5);
    border-radius:8px;
    background-color:#ff0000;
    font-size:30px;
}
.FGAfunTargetTimerClose button:hover{
    background-color: blueviolet;
}