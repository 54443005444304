/* MAIN-CONTAINER */

.bingo_cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1758px;
  height: 1090px; 
  z-index: 300;
}

.bngo_betglow {
  width: 420px;
  position: absolute; 
  z-index: -1; 
  right: -23px;
} 

.bingo-container { 
  background: url(../../../../../../assets/games/bingo/bingo_bg.png);
  background-size: 100% 100%;  
}
 

.praveen {
  background: rgb(0, 255, 149);
  animation: tras;

}

@keyframes tras {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.bngo_winamnt {
  position: absolute;
  color: black;
  top: 58%;
  left: 41.6%;
  font-size: 36px;
  font-weight: bold;
  width: 163px;
  height: 50px;
  text-align: center;
  /* background: red; */
}

.betpannel_highlight {
  background: linear-gradient(#FFFF00, #FFB900)
}

.bngo_popup_back {
  display: none;
  position: fixed;
  background: #2b2a2a52;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0px;
  z-index: 999;
}

.bingo-panel-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


/* TOP-BUTTON-PANEL-CONTAINER */

.top-button-pannel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  position: relative;
  /* top: 33px; */
  /* background: red; */
}

.score-container {  
  position: relative;
  background: url(../../../../../../assets/games/bingo/score_pannel.png);
  background-size: 100% 100%;
  height: 120px;
}
.total_bet {  
  position: relative;
  background: url(../../../../../../assets/games/bingo/bet_pannel.png);
  background-size: 100% 100%;
  height: 120px;
}

 
.winnerpanel_txt {
  position: absolute;
  font-size: 35px;
  color: #ffffff; 
  font-family: "SAGONABOOKBOLD";
  width: 200px;
  text-align: center; 
  z-index: 5; 
  right: 73px; 
  top: 15px;
}

.bngobnscontainer {
  /* width: 198px; */
  /* height: 50px; */
  /* background: red; */
  position: fixed !important;
  top: 189px;
  right: 226px;
  text-align: center;
  /* padding: 10px; */
  /* left: 5px; */
}

.score_panneltxt {
  position: absolute;
  font-size: 35px;
  color: #ffffff; 
  font-family: "SAGONABOOKBOLD"; 
  width: 100%;
  text-align: center; 
  margin-top: 10px;
}

.game-title {
  position: relative;
  height: 120px;
}

.winnerpanel{
  position: relative;
  width: 100%;
  height: 120px;
}

.winner-asset {
  position: absolute; 
  height: 120px;
}

/* MIDDLE-BUTTON-PANEL-CONTAINER */


.bet-panel-container {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Bluestar-Medium';

}


/* LEFT-BUTTON-PANEL-CONTAINER */

.left-button-panel {
  width: 70%;
}

.rightpannelbngo {
  position: absolute;
  top: 5px;
  left: 1749px;
}

.bet-houses-container {
  width: 100%;
  height: 960px;
  position: absolute;
  font-family: 'Bluestar-Medium';
}

.innertble {
  background: black;
  width: 2343px;
}

.bet-numbers-comtainer {
  display: flex;
  flex-direction: column;
  align-items: center;

}
#uniqueRowId0 {
  position: absolute;
  top: 48px;
  left: -19px;
}
#uniqueRowId1 {
  position: absolute;
  left: -19px;
  top: 49px;
}

#uniqueRowId2 {
  position: absolute;
  left: -19px;
  top: 49px;
}

#uniqueRowId3 {
  position: absolute;
  top: -90px;
  left: -20px;
  /* width: 76px; */
}

#uniqueRowId4 {
  position: absolute;
  left: -19px;
  top: -90px;
}
#uniqueRowId5 {
  position: absolute;
  left: -17px;
  top: -88px;
  /* height: 189px; */
}

.BetHouse1 {
  width: 535px;
  height: 485px;
  position: absolute;
  top: -3px;
}

.BetHouse2 {
  position: absolute;
  top: -3px;
  width: 535px;
  height: 485px;
}

.BetHouse3 {
  position: absolute;
  top: -3px;
  width: 535px;
  height: 485px;
}

.BetHouse4 {
  position: absolute;
  top: -139px;
  width: 535px;
  height: 485px;
}
.BetHouse5 {
  position: absolute;
  top: -139px;
  width: 535px;
  height: 485px;
}

.BetHouse6 {
  position: absolute;
  top: -139px;
  width: 535px;
  height: 485px;
}



.bet-button-bng {
  position: relative;
  top: -5px;
  /* left: 194px; */
  right: 40px;
  z-index: 5;
  width: 50px;
}

.numbers-table-container {
  width: 33%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 484px;
}

.bng-bet-amount {
  color: #ffffff;
  position: relative;
  z-index: 1;
  right: 39px;
  top: -17px;
  text-align: center;
}

.bonus-label-heading {
  position: absolute;
  color: #ffffff;
  font-size: 45px;
  font-weight: bold;
  font-family: "SAGONABOOKBOLD";
  white-space: nowrap;
  top: 385px;
  left: 63px;
  width: 450px;
  height: 60px;
  /* background: antiquewhite; */
  text-align: center;
}

.bingo_bnsamnt {
  position: absolute;
  font-size: 50px;
  font-family: "SAGONABOOKBOLD";
  width: 255px;
  color: #ffffff;
  text-align: center;
  transform: translate(-412px, 505px);
}

.bnsfontupdate {
  position: absolute;
  top: -1px;
  left: -194px;
  font-size: 35px;
  font-family: "poppins";
  /* background: red; */
  width: 195px;
  text-align: center;
  transform: translate(2px, -2px);
}

.bnsfontupdatepanjabi {
  position: absolute;
  top: 5px;
  left: -186px;
  font-size: 25px;
  font-family: "poppins";
  /* background: red; */
  width: 195px;
  text-align: center;
}

.bnsfontupdatehindimarathi {
  position: absolute;
  top: 5px;
  left: -182px;
  font-size: 25px;
  font-family: "poppins";
  /* background: red; */
  width: 195px;
  text-align: center;
}

/* #bngo_dup {
  position: absolute;
  bottom: -141px;
  right: -443px;
  z-index: 0;
  width: 587px;
  background: transparent;
} */
.dupglowtelugu {
  position: absolute;
  bottom: -141px;
  right: -409px;
  z-index: 0;
  width: 587px;
  background: transparent;
}

.dupglowbengali {
  position: absolute;
  bottom: -138px !important;
  right: -402px !important;
  z-index: 0;
  width: 577px !important;
  background: transparent !important;

}

.dupglowenglish {
  position: absolute;
  bottom: 492px !important;
  right: -378px !important;
  z-index: 0;
  width: 553px !important;
  background: transparent;
}

.bnsfontupdatehindi {
  position: absolute;
  top: 2px;
  left: -182px;
  font-size: 25px;
  font-family: "poppins";
  /* background: red; */
  width: 195px;
  text-align: center;

}

#betButtonbngo0 {
  cursor: pointer;
}

#betButtonbngo1 {
  cursor: pointer;

}

#betButtonbngo2 {
  cursor: pointer;

}

#betButtonbngo3 {
  position: absolute;
  cursor: pointer;
  top: 457px;
  left: 193px;
}

#betButtonbngo4 {
  position: absolute;
  cursor: pointer;
  top: 457px;
  left: 716px;
}

#betButtonbngo5 {
  position: absolute;
  cursor: pointer;
  top: 457px;
  right: 285px;
}

.numbers-table-image {
  width: 420px;
  transform: scale(1.3, 1.05);
  transform-origin: center;
  position: relative;
  top: -45px;
}

.numbers-table-imagebottom {
  width: 420px;
  transform: scale(1.3, 1.05);
  transform-origin: center;
  position: relative;
  top: -12px;
}

/* Old code  */
/* .uniqueTableId {
  position: relative;
  top: -456px;
  right: 43px;
  width: 105%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: 'Bluestar-Medium';  
} */
/* Old code  */

.uniqueTableId {
  /* position: relative;
  top: 62px;
  left: 15px;
  width: 470px;
  display: flex;
  justify-content: center;
 
  z-index: 1;
  font-family: 'Bluestar-Medium';
  height: 408px; */
}

.uniqueTableId tr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Bluestar-Medium';

}

.uniqueTableId td {
  width: 16%;
  /* height: 217%; */
  height: 75px;
  /* text-align: center; */
  padding: 0px;
  border-color: initial;
  border: 1px solid #ffdd30;
  margin: 3px;
  font-family: 'Bluestar-Medium';

}

.uniqueTableId td:after {
  /* border-bottom: 4px solid; */
  border-color: linear-gradient(#ffdd30, #ffee47, #dc7521);
  /* psuedo element positioning */
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  /* tell the borowser its border width is included with its element size, so it will not overlap with td border */
  box-sizing: border-box;
}

.disablebingo_buttons {
  cursor: no-drop;
}

.bingo_stars0 {
  position: absolute; 
  bottom: 197px;
  right: 762px; 
  width: 47px;
}

.dupbnbtn {
  position: relative;
  top: 66px;
  left: 64px;
  background: transparent;
  border: none;
  z-index: 10;
  width: 460px;
  height: 155px;
  display: flex;
}

.bngo_animation {
  width: 100%;
  position: absolute;
  top: 1%;
  left: -5%;
  height: 100%;
  transform: scale(2, 2);
  z-index: 990;

}

.bingo_stars1 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 700px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars2 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 638px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars3 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 566px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars4 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 494px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars5 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 422px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars6 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 350px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars7 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 278px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars8 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 206px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars9 {
  position: absolute;
  /* top: 5px; */
  bottom: 197px;
  right: 134px;
  /* left: 5px; */
  width: 47px;
}

.bingo_stars10 {
  position: absolute; 
  bottom: 197px;
  right: 62px; 
  width: 47px;
}

.bingo_stars11 {
  position: absolute; 
  bottom: 197px;
  right: -10px; 
  width: 47px;
}

.bingo_stars12 {
  position: absolute; 
  bottom: 197px;
  right: -82px; 
  width: 47px;
}

.bingo_stars13 {
  position: absolute; 
  bottom: 197px;
  right: -154px; 
  width: 47px;
}

.bing_starcontainer {
  position: relative;
  top: 33px;
  left: -120px;
}

.bingostar-1 {
  position: absolute;
  top: 4px;
  left: 19%;
  margin-top: 27px;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-2 {
  position: absolute;
  top: 4px;
  left: 33%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-3 {
  position: absolute;
  top: 4px;
  left: 47%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-4 {
  position: absolute;
  top: 4px;
  left: 61%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-5 {
  position: absolute;
  top: 4px;
  left: 75%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-6 {
  position: absolute;
  top: 4px;
  left: 89%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-7 {
  position: absolute;
  top: 4px;
  left: 103%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-8 {
  position: absolute;
  top: 78px;
  left: 26%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-9 {
  position: absolute;
  top: 78px;
  left: 40%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}


.bingostar-10 {
  position: absolute;
  top: 78px;
  left: 54%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-11 {
  position: absolute;
  top: 78px;
  left: 68%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-12 {
  position: absolute;
  top: 78px;
  left: 82%;
  margin: 5%;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
}

.bingostar-13 {
  position: absolute;
  top: 78px;
  left: 96%;
  width: 45px;
  height: 45px;
  margin: 5%;
  background-repeat: no-repeat;
}

@keyframes scaleUpbingo {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

/* .dupbuttoncontainer {
  position: absolute;
  top: 623px;
  left: 1712px;
  width: 410px;
  height: 187px;
  background: red;
} */
.bingo_starblink {
  animation: scaleUpbingo 0.5s ease-in-out infinite alternate;
}

.bngo_bupbtnglow {
  position: relative;
  top: -63px;
  z-index: -1;
  width: 584px;
  height: 255px;
  top: -62px;
  left: -54px;
}

.blinkeffectbngobtns {
  animation: blink normal 1s infinite ease-in-out;
}

.betbutton_glow {
  position: absolute;
  bottom: -345px;
  right: -79px;
  width: 387px;
  background: transparent;

}

@keyframes blinkscaling {
  0% {
    transform: scale(5);
  }

  50% {
    transform: scale(10.15);
  }

  100% {
    transform: scale(5);
  }
}

.winblinkamount {
  width: 100px;

}

.blink_winamount {
  /* height: 58px;
  width: 151px; */
  /* margin-left: -17px; */
  display: inline-block;
  animation: blinkwinnums 1s steps(3, start) infinite;
}

@keyframes blinkwinnums{
  0% {
    transform: scale(0.5);
   
  }

  50% {
    transform: scale(1.1);
    
  }

  100% {
    transform: scale(0.5);
    
  }

}
@keyframes blink-animation {
  /* to {
    visibility: hidden;
  } */

  0% {
    transform: scale(0.5);
    background-color: rgb(247, 0, 255);
  }

  50% {
    transform: scale(1.1);
    background-color: transparent;
  }

  100% {
    transform: scale(0.5);
    background-color: rgb(255, 136, 0);
  }
}

.uniqueTableId tr {
  /* display: flex; */
  /* flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Bluestar-Medium'; */

}

/* old code */

/* .uniqueTableId td {
  width: 16%; 
  height: 75px; 
  padding: 0px;
  border-color: initial;
  border: 1px solid #ffdd30;
  margin: 3px;
  font-family: 'Bluestar-Medium';
} */

/* old code */

.uniqueTableId td {
  height: 78px;
  text-align: center;
  padding: 0px;
  border-color: initial;
  border: 1px solid #ffdd30;
  margin: 3px;
  font-family: 'Bluestar-Medium';
  font-size: 60px;
  color: white;
  position: relative;
  width: 93px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.uniqueTableId td:after {
  /* border-bottom: 4px solid; */
  border-color: linear-gradient(#ffdd30, #ffee47, #dc7521);
  /* psuedo element positioning */
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  /* tell the borowser its border width is included with its element size, so it will not overlap with td border */
  box-sizing: border-box;
}

.bngbutton {
  width: 500px;
  height: 500px;
  background-color: green;
}

/* #secondchip_spin{
  display: block;
} */

.bingoNums {
  font-size: 63px;
  font-family: Bluestar-Medium;
  box-sizing: border-box;
  /* margin-top: -6px; */
  color: #F2EFDF;
  position: absolute;
  font-weight: medium;
  text-align: center;
  width: -21%;
  height: 75px;
  width: 103px;
}

.background-color0 {
  color: white;
  background: linear-gradient(#e93400, #f9a233);

}

.background-color1 {
  color: white;
  background: linear-gradient(#7a0026, #ff1549);
}

.background-color2 {
  color: white;
  font-family: "Queen Love Stories free Regular";
  background: linear-gradient(#004b85, #157fff);
}

.background-color3 {
  color: white;
  background: linear-gradient(#005524, #00920b);
}

.background-color4 {
  color: white;
  background: linear-gradient(#37007f, #490080);
}

.background-color5 {
  color: white;
  background: linear-gradient(#910000, #e10000);
}




/* RIGHT-BUTTON-PANEL-CONTAINER */


.right-button-panel {
  width: 30%;
}

.bet-result-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.bonus-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0%;
}

.bonus-container span {

  font-size: 35px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 20px 0 10px;
  font-family: Quattrocento sans-serif;


}

.tablesfisrt {
  position: absolute;
  top: 15px;
  left: 19px;
  height: 468px;
  width: 408px;
}

.tablesecond {
  position: absolute;
  top: 13px;
  left: 626px;
  height: 468px;
  width: 408px;
}

.tabletrd {
  position: absolute;
  top: 11px;
  left: 1225px;
  height: 468px;
  width: 408px;
}

.tablefour {
  position: absolute;
  top: 510px;
  left: 18px;
  height: 468px;
  width: 408px;
}

.tablefive {
  position: absolute;
  top: 511px;
  left: 619px;
  height: 468px;
  width: 408px;
}

.tablesix {
  position: absolute;
  top: 508px;
  left: 1223px;
  height: 468px;
  width: 408px;
}

.buttonsbngobannel {
  position: absolute;
  top: 813px;
  left: 1356px;
}

.chip-result-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 206px;
  margin-bottom: 37px;
  position: absolute;
  top: 941px;
  left: 31px;
}

.bng-chip-result-container {
  margin-right: 5%;
  width: 28%;
  right: 23%;
}


.bingo-chip-result0 {
  position: absolute;
  /* top: 969px; */
  font-size: 110px;
  font-family: "LisbethBold";
  font-weight: bold;
  width: 180px;
  text-align: center;
  /* height: 73px; */
  /* right: 2163px; */
  color: #bb1b00;
  opacity: 1;
  text-transform: uppercase;
}

.bingo-chip-result1 {
  position: absolute;
  /* top: 970px; */
  font-size: 110px;
  font-family: "LisbethBold";
  font-weight: bold;
  width: 180px;
  /* height: 72px; */
  /* right: 1977px; */
  text-align: -webkit-center;
  color: #9B004E;
  opacity: 1;
  text-transform: uppercase;
}

.bingo-chip-result2 {
  position: absolute;
  /* top: 970px; */
  font-size: 110px;
  font-family: "LisbethBold";
  font-weight: bold;
  /* right: 1797px; */
  width: 180px;
  text-align: center;
  /* height: 72px; */
  color: #0071BC;
  opacity: 1;
  text-transform: uppercase;
}

.bingo-chip-result3 {
  position: absolute;
  /* top: 975px; */
  font-size: 100px;
  font-family: "LisbethBold";
  font-weight: normal;
  /* right: 1602px; */
  text-align: -webkit-center;
  color: #1B8904;
  opacity: 1;
  width: 180px;
  /* height: 71px; */
  text-transform: uppercase;
  font-weight: bold;
}

.bingo-chip-result4 {
  position: absolute;
  /* top: 970px; */
  font-size: 110px;
  font-family: "LisbethBold";
  font-weight: bold;
  /* right: 1412px; */
  color: #46097C;
  text-align: center;
  opacity: 1;
  width: 180px;
  /* height: 71px; */
  text-transform: uppercase;
}
.bingo-chip-result5 {
  position: absolute;
  opacity: 1;
  width: 180px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 150px;
  top: 9px !important;
}

.bgnoblnctxt {
  position: absolute;
  /* top: -39px; */
  /* font-weight: bold; */
  font-family: "SAGONABOOKBOLD";
  /* left: 63px; */
  font-size: 38px;
  color: yellow;
  width: 210px;
  text-align: center;
  padding-bottom: 15px;
  bottom: 0;
  left: 110px;
  /* right: 0; */
  /* top: 0; */
  /* background: green; */
}

.total_bettext {
  position: absolute;
  font-size: 33px;
  color: #ffffff;
  font-family: "SAGONABOOKBOLD";
  width: 220px;
  text-align: center;
  margin-top: 16px;
  /* background: aqua; */
  left: 64px;
  /* justify-content: center; */
  /* align-content: center; */
  /* align-items: center; */
  /* display: flex; */
}
.ttlbet_val {
  position: absolute; 
  font-family: "SAGONABOOKBOLD";
  font-size: 38px;
  color: yellow;
  width: 210px;
  text-align: center;
  padding-bottom: 15px;
  bottom: 0;
  left: 75px;
}
 

.totalbetbingo {
  position: absolute;
  font-family: "rockwell";
  font-size: 29px;
  font-weight: bold;
  color: #ffffff;
  /* top: -57px; */
  /* background: red; */
  width: 215px;
  text-align: center;
  /* right: 512px; */
  /* transform: translate(1px, 7px); */
  top: 6px;
}



.user_pannel {
  position: absolute;
  /* top: 5px; */
  bottom: -45px;
  left: 25px;
  /* left: 1770px; */
  width: 350px;
  height: 100px;
}

.user_id {
  position: absolute;
  font-family: "SAGONABOOKBOLD";
  font-size: 28px;
  color: #ffffff;
  bottom: -14px;
  left: 105px;
  width: 240px;
  /* background: bisque; */
}

.prevbtn {
  position: absolute;
  top: 50px;
  left: 50px;
}

@keyframes opacity {
  0% {
    opacity: 0.5;
    transform: translate(0px, -20px);
  }

  /* 20%{
        opacity: 0.5;
        transform: translate(0px,-100px);
    }
    40%{
        opacity: 0.5;
        transform: translate(0px,0px);
    }
    60%{
        opacity: 0.5;
        transform: translate(0px,100px);
    } */
  100% {
    opacity: 0.5;
    transform: translate(0px, 20px);
  }
}

.bngo_Cover {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  background: transparent;
  /* background: #00000082; */
  z-index: 300;
}

.bngoresult_anim1 {
  position: absolute;
  /* top: 507px;
  left: -3172px; */
  z-index: 1;
  /* WIDTH: 152px; */
}

.bngoresult_anim2 {
  position: absolute;
  /* top: 505px;
  left: -2954px; */
  z-index: 1;
}

.bngoresult_anim3 {
  position: absolute;
  /* top: 505px;
  left: -2775px; */
  z-index: 1;
}

.bngoresult_anim4 {
  position: absolute;
  /* top: 519px;
  left: -2595px; */
  z-index: 1;
}

.bngoresult_anim5 {
  position: absolute;
  /* top: 490px;
  left: -2393px; */
  z-index: 1;
}

.bngoresult_anim6 {
  position: absolute;
  /* top: 495px;
  left: -2194px; */
  z-index: 1;
}

.bngo_winamt {
  position: absolute;
  font-size: 38px;
  font-family: "SAGONABOOKBOLD";
  /* top: -39px; */
  color: yellow;
  /* left: 1405px; */
  text-align: center;
  width: 215px;
  /* background: green; */
  bottom: 15px;
  left: 118px;
}

.bngo_betprevtake {
  position: absolute;
  color: #fdd21c;
  /* right: 70px; */
  /* background: red; */
  width: 222px;
  text-align: center;
  font-family: "Ice kingdom";
  font-size: 45px;
  font-weight: bold;
  /* transform: translate(3px, 1px); */
  /* bottom: 41px; */
}

.bngo_bupbtn {
  width: 480px;
  position: absolute;
}

.bngo_betbtn:disabled {
  cursor: no-drop;
  opacity: 0.5;
  background: transparent;
  border: none;
}

.betbngoButton:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.bngo_clear:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.doublbngo_btn:disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.dupbnbtn:disabled {
  cursor: no-drop;
  opacity: 0.6;
}

.double_heading {
  position: absolute;
  /* bottom: 43px; */
  /* right: 100px; */
  font-family: "Ice kingdom";
  font-weight: bold;
  /* left: 10px; */
  width: 163px;
  text-align: center;
  color: #fdd21c;
  font-size: 45px;
  /* cursor: auto; */
  pointer-events: none;
}

/* .bingo-chip-result{
    opacity: 0.5;
  } */


.take-start-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-top: 10px;
}

.take-container {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}

.d-up-heading {
  font-size: 35px;
  position: absolute;
  top: 46px;
  z-index: 28;
  left: 107px;
  width: 268px;
  color: purple;
  font-weight: bold;
  font-family: "SAGONABOOKBOLD";
  text-shadow: 0px 0px 0px #654c512e;
  text-align: center;
  /* background: green; */
}

.take-start-container img {
  width: 300px;
}


.take-start-container-heading {
  pointer-events: none;
}

/* BOTTOM-CONTAINER */


.map-container {
  cursor: pointer;
}

/* .infotext-exit-container {
  display: flex;
  flex-direction: row;
  
  justify-content: center;
  position: relative;
  top: 193px;
  height: 13%;
 
} */
.normaltext {
  font-size: 18px;
  position: absolute;
  top: 456px;
  font-family: "poppins";
  font-weight: bold;
  /* left: 163px; */
  right: 103px;
  text-align: center;
  /* background: red; */
  color: red;
  opacity: 1;
  /* animation: opacity 105ms ease-in-out 0.2s 20; */
  height: 63px;
  width: 71px;
}

.bnstext {
  font-size: 38px;
  position: absolute;
  top: 440px;
  font-family: "poppins";
  font-weight: bold;
  /* left: 163px; */
  right: 101px;
  text-align: center;
  /* background: red; */
  color: red;
  opacity: 1;
  /* animation: opacity 105ms ease-in-out 0.2s 20; */
  height: 63px;
  width: 71px;
}
 
.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 46%; 
  left: -271px;
  position: relative;
}

.bottom-container-infotext-img {
  position: relative;
  width: 100%;
  bottom: -63%;
}

.bngclear_text {
  position: relative;
  font-size: 72px;
  font-family: "Ice kingdom";
  font-weight: bold;
  font-family: "Ice kingdom";
  color: #fdd21c;
  font-size: 45px;
  /* top: -93px; */
  /* left: -7px; */
}

.bngo_pid_val {
  position: absolute;
  top: 156px;
  font-family: "SAGONABOOKBOLD";
  left: 64px;
  font-size: 35px;
  color: #ffffff;
}

.btnsnodrop {
  opacity: 0.6;
  pointer-events: none;
  cursor: no-drop !important;
}
 
  
.bngo_Coverbtns {
  position: absolute;
  width: 1180px;
  height: 180px;
  right: 5px; 
  z-index: 2;
  bottom: 3px;
  display: none;
}

.infotext-heading {
  color: #ffffff;
  font-size: 31px;
  position: absolute;
  top: 474px;
  /* background: red; */
  font-family: "SAGONABOOKBOLD";
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 88px;
  text-align: center;
  justify-content: center;
}
.bottom-container-exit-img {
  width: 80px;
  position: relative;
  /* top: 115px; */
  /* left: 77px; */
  height: 80px;
  /* float: right; */
  /* align-items: end; */
  /* margin: auto; */
}

.win-amount0 {
  position: relative;
  top: -2px;
  left: 366px;
  font-family: "poppins";
  /* background: red; */
  width: 125px;
  color: #ffffff;
  font-size: 43px;
  text-align: center;
  z-index: 1;
}

.win-amount1 {
  position: relative;
  top: -2px;
  left: 367px;
  font-family: "poppins";
  /* background: red; */
  width: 111px;
  color: #ffffff;
  text-align: center;
  font-size: 43px;
  z-index: 1;
}

.win-amount2 {
  position: relative;
  top: -2px;
  left: 365px;
  font-family: "poppins";
  /* background: red; */
  width: 125px;
  color: #ffffff;
  font-size: 43px;
  text-align: center;
  z-index: 1;
}

.win-amount3 {
  position: relative;
  top: -139px;
  left: 362px;
  color: #ffffff;
  font-family: "poppins";
  /* background: red; */
  width: 115px;
  font-size: 43px;
  text-align: center;
  z-index: 1;
}

.win-amount4 {
  position: relative;
  top: -139px;
  left: 368px;
  color: #ffffff;
  font-family: "poppins";
  /* background: red; */
  width: 122px;
  font-size: 43px;
  text-align: center;
  z-index: 1;
}

.win-amount5 {
  position: relative;
  top: -139px;
  left: 367px;
  color: #ffffff;
  font-family: "poppins";
  /* background: red; */
  width: 119px;
  font-size: 43px;
  text-align: center;
  z-index: 1;
}

/* .glblbnsimage {
  position: absolute;
  top: 437px;
  left: 98px;
  width: 400px;
} */

.bngobets0 {
  position: absolute;
  top: 0px;
  color: #ffffff;
  font-weight: bold;
  font-size: 43px;
  text-align: center;
  z-index: 1; 
  left: 74px;
  width: 110px; 
  font-family: 'poppins';
}

.bngobets1 {
  position: absolute;
  color: #ffffff;
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  top: 0px;
  z-index: 1;
  /* background: palevioletred; */
  width: 112px;
  left: 72px;
  font-family: 'poppins';
}
.bngobets2 {
  position: absolute;
  top: 0px;
  color: #ffffff;
  font-weight: bold;
  font-size: 42px;
  width: 111px;
  text-align: center;
  z-index: 1;
  /* background: palevioletred; */
  left: 74px;
  font-family: 'poppins';
}

.bngobets3 {
  position: absolute;
  top: -137px;
  color: #ffffff;
  font-weight: bold;
  font-size: 43px;
  width: 115px;
  text-align: center;
  font-family: 'poppins';
  z-index: 1;
  /* background: palevioletred; */
  left: 70px;
}

.bngobets4 {
  position: absolute;
  top: -137px;
  color: #ffffff;
  font-weight: bold;
  font-size: 43px;
  width: 150px;
  text-align: center;
  z-index: 1;
  /* background: palevioletred; */
  left: 51px;
  font-family: 'poppins';
}

.bngobets5 {
  position: absolute;
  top: -137px;
  color: #ffffff;
  font-weight: bold;
  font-size: 43px;
  width: 144px;
  text-align: center;
  z-index: 1;
  /* background: palevioletred; */
  left: 54px;
  font-family: 'poppins';
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.blink {
  animation: blink 1s infinite;
  /* Adjust the animation duration as needed */
}

.bettextbngochnge {
  color: gold;
}

.bingo_animation {
  position: absolute;
  /* top: 568px;
  left: -3489px; */
  z-index: 1;
}

.bingo_animationschip {
  position: absolute;
  /* top: 584px;
  left: -3239px; */
  z-index: 1;
  /* width: 1000px; */
}

.winpannel_num {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #004b85;
  font-size: 50px;
  font-weight: bold;
}

.bingo_animationthrchip {
  position: absolute;
  /* top: 593px;
  left: -3058px; */

  z-index: 1;
}

.bingo_animationfrthchip {
  position: absolute; 
  z-index: 1;
}

.bingo_animationfive {
  position: absolute;
  /* top: 575px;
  left: -2717px; */
  z-index: 1; 
}

.numAnimSize{
  width: 185px;
  height: 145px;
}

.bingorollinggifs {
  /* height: 945px;
  width: 2599px; */
  /* background: red; */
  position: absolute;
}

.bingorollinggise {
  /* height: 900px;
  width: 2479px; */
  /* background: red; */
  position: absolute;
}

.bingorollinggitrd {
  /* height: 883px;
  width: 2500px; */
  /* background: red; */
}

.bingorollinggifrth {
  /* height: 948px;
  width: 2500px; */
  /* background: red; */
}

.bingorollinggifive {
  /* height: 921px;
  width: 2592px; */
  /* background: red; */
}

.bingorollinggisix {
  /* height: 932px; */
  /* width: 2583px; */
}

.bingo_animationsix {
  position: absolute;
  /* top: 570px;
  left: -2516px; */
  z-index: 1;
}
.addglowtowintable0, .addglowtowintable1, .addglowtowintable2 {
  position: absolute;
  width: 325px;
  height: 155px;
  top: -670px;
  right: -9px;
  animation: blink normal 1s infinite ease-in-out;
  display: none;
}
.addglowtowintable3, .addglowtowintable4, .addglowtowintable5 {
  position: absolute;
  width: 325px;
  height: 157px;
  top: -187px;
  right: -10px;
  animation: blink normal 1s infinite ease-in-out;
  z-index: 2;
  display: none;
}
 .ft_glowBetBox { 
  position: relative;
  /* border: solid 0px rgb(255, 251, 0); */
  box-shadow: 1px 1px 10px 15px rgb(255, 251, 20) !important;
  color: #FBEC0F;
}
/* #uniqueRowId0 {
  position: absolute;
  top: 448px;
  left: 15px;
}

#uniqueRowId1 {
  position: absolute;
  left: 15px;
  top: 448px;
}

#uniqueRowId2 {
  position: absolute;
  left: 15px;
  top: 448px;
}

#uniqueRowId3 {
  position: absolute;
  top: 417px;
  left: 14px; 
}

#uniqueRowId4 {
  position: absolute;
  left: 14px;
  top: 417px;
}

#uniqueRowId5 {
  position: absolute;
  left: 14px;
  top: 417px; 
} */

.bngo_clear {
  position: relative;
  cursor: pointer; 
  background: transparent;
  z-index: 1;
  border: none;
  display: flex;
  width: 350px;
  height: 125px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bingoclearbtn {
  width: 350px;
  position: absolute;

}

#resultspinchip0 {
  /* position: absolute;
  top: 971px;
  left: -2285px; */
  width: 187px;
  height: 150px;
}

#resultspinchip1 {
  /* position: absolute;
  top: 971px;
  left: -2093px; */
  width: 187px;
  height: 150px;
}

#resultspinchip2 {
  /* position: absolute;
  top: 971px;
  left: -1902px; */
  width: 187px;
  height: 150px;
}

#resultspinchip3 {
  /* position: absolute;
  top: 971px;
  left: -1710px; */
  width: 187px;
  height: 150px;
}

#resultspinchip4 {
  /* position: absolute;
  top: 971px;
  left: -1516px; */
  width: 187px;
  height: 150px;
}

#resultspinchip5 {
  width: 187px;
  height: 150px;
}

.doublbngo_btn { 
  background-color: transparent; 
  border: none;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 350px;
  height: 125px;
}

.double_button_bingo {
  width: 350px;
  position: absolute;
}

.uniquetbody0 {
  width: 50%;
  height: 50%;
}

.bngo_betbtn {
  position: relative;
  background: transparent;
  border: none;
  z-index: 1;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 350px;
  height: 125px;
  align-items: center;
}

.bingobetbtn {
  width: 350px;

}

.addCursorPointer_bngo {
  cursor: pointer;
}

.emptyPointer_bngo {
  cursor: none !important;
}

.exiglowenglish {
  position: absolute !important;
  top: 128px !important;
  right: 74px !important;
}

.exit-heading {
  font-size: 40px;
  position: relative;
  font-family: "Ice kingdom";
  font-weight: bold;
  width: 80px;
  text-align: center;
  background: linear-gradient(#ff0, #ff0, #ff0, #fbf800, #ffbf00);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  pointer-events: none;
  top: 0%;
  text-wrap: wrap;
  left: 0%;
  display: inline-block;
  align-content: center;
  /* transform: translate(-76%, 105%); */
}

.bng-win-amount {
  color: #ffffff;
  position: relative;
  z-index: 1;
  right: -169px;
  top: -291px;
  text-align: center;
}

.lottery-number0 {
  position: absolute;
  color: white;
  font-size: 68px;
  font-weight: bold;
  top: 27%;
  left: 73%;
  animation: bingonumanim 150ms ease-in-out 0.1s infinite;
  opacity: 0.7;


}

.bingo_exitBtn {
  position: relative;
  /* align-items: center; */
  /* align-content: center; */
  /* display: flex; */
  /* margin: auto; */
  /* top: 0; */
  width: 80px;
  height: 80px;
  /* background: green; */
  /* top: 0px; */
  /* right: 70px; */
  /* align-content: center; */
  /* justify-content: end; */
  /* align-items: unset; */
  /* float: right; */
  /* display: flex; */
  /* margin: auto; */
  /* right: 0; */
  /* left: 0; */
  /* overflow: hidden; */
}

.betPanelPos {
  float: left;
}

.normltext {
  position: absolute;
  /* top: 1018px !important; */
  /* left: -1290px !important; */
  font-family: "LisbethBold";
  font-size: 40px;
  font-weight: bold;
  width: 180px;
  color: #FF0000;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 43px;
  /* left: 0px; */
  /* right: 3px; */
  align-content: center;
  padding-left: 6px;
  height: 135px;
}

.bnsxval {
  position: absolute;
  top: 969px;
  left: -1283px;
  font-family: LisbethBold !important;
  font-size: 110px;
  font-weight: bold;
  color: red;
}

.hhlthouses {
  display: block;
  /* animation: blink-animation 1s steps(4, start) infinite; */
  background: linear-gradient(#FFFF00, #FFB900);
  color: black;
  /* color: #000000; */
} 

.glowMatchNums{
  box-shadow: 1px 1px 10px 15px rgb(255, 251, 20) !important;
  animation: blink-animation 1s steps(4, start) infinite;
}

.resTxtFont {
  font-family: LisbethBold !important;
}

.resNumFont {
  font-family: LisbethBold !important;
}

.winGlowbngo {
  display: block;
  position: absolute;
  left: -6px;
  top: -12px;
  width: 420px;
  height: 140px;
  transform: rotate(-179deg);
  margin-top: 0px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 1s infinite ease-in-out;
}

.hidewinglowbngo {
  position: absolute;
  left: 1746px;
  top: 61px;
  width: 486px;
  height: 320px;
  margin-top: 0px;
  display: none;
}

.bingo_bonusImg {
  width: 135px;
  height: 135px;
}

div#resultimages5 {
  position: absolute;
  display: flex;
  left: -1279px;
  right: 0;
  align-items: center;
  align-content: center;
  top: 985px;
}

.houseBg {
  position: absolute;
  width: 92%;
  height: 74%;
  background: #12dd6e00;
  /* margin: 10px; */
  padding: 10px;
  left: 10px;
  z-index: 9;
  cursor: pointer;
  top: 5px;
}

.houseBg2 {
  position: absolute;
  width: 91%;
  height: 135%;
  background: #6b1dcf00;
  /* margin: 10px; */
  padding: 10px;
  left: 15px;
  z-index: 9;
  cursor: pointer;
  top: -123px;
}

.bingo_title_pos {
  top: -83px;
  position: absolute;
  left: -50px;
}

.leftPanel {
  position: relative;
  /* display: flex; */
  width: 100%;
  height: 80%;
  /* background: aliceblue; */
}
.rightPanel {
  position: relative;
  width: 100%;
  display: flex;
  height: 20%;
  margin-top: -30px;
  /* background: aquamarine; */
}

.resBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.resNumBg {
  display: flex;
}

.res1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.hideTable{
  opacity: 0;
}

img.bingoHouseCls {
  width: 1725px;
  left: 7px;
  position: absolute;
  height: 952px;
  /* display: none; */
  top: 4px;
/* background: green; */
  z-index: 99;
}

#bingoHouse{
  z-index: 9;
}

.row.houseRow {
  position: relative;
  width: 100%;
  height: 55%;
  justify-content: space-evenly; 
}



img.houseImg {
  width: 500px;
  height: 475px;
  position: relative;
}

.bigHos_0{
  background: url(../../../../../../assets/games/bingo/betpannel_1.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}
.bigHos_1{
  background: url(../../../../../../assets/games/bingo/betpannel_2.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}
.bigHos_2{
  background: url(../../../../../../assets/games/bingo/betpannel_3.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}
.bigHos_3{
  background: url(../../../../../../assets/games/bingo/betpannel_4.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}
.bigHos_4{
  background: url(../../../../../../assets/games/bingo/betpannel_5.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}
.bigHos_5{
  background: url(../../../../../../assets/games/bingo/betpannel_6.png);
  background-size: 100% 100%;  
  width: 500px;
  position: relative;
  display: flex;
}


.houseTable{ 
    border-collapse: collapse;
    width: 93%;
    position: relative;
    height: 85%;
    background: aquamarine;
    left: 15px;
    top: 57px;
}


.house_betAmt {
  position: absolute;
  /* background: antiquewhite; */
  width: 95px;
  height: 40px;
  left: 71px;
  top: 6px;
  text-align: center;
  align-items: center;  
  font-size: 30px;
  font-family: 'Poppins';
  color: white;
}

.house_winAmt {
  position: absolute;
  /* background: rgb(83, 224, 40); */
  width: 100px;
  height: 45px;
  right: 65px;
  top: 6px;
  text-align: center;
  align-items: center;
  /* display: flex; */
  font-size: 30px;
  font-family: 'Poppins';
  color: white;
}

.bingo_glowBetBox {
  position: relative;
  box-shadow: 1px 1px 10px 15px rgb(255, 251, 20) !important;
  color: #FBEC0F;
  border-radius: 10px;
}