.bgGold {
  background: url("../../../../../../assets/gold_coin.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.roulette {
  transition: all 0.5s;
}

.am_HistoryTbl {
  width: 100%;
  float: left;
  position: relative;
  left: -0%;
  font-size: 16px;
  margin-top: 15px;
}

.am_HistoryTbl tr td {
  color: #fff;
  background: linear-gradient(#d12708, #f15e00);
  text-align: center;
  padding: 4px 1px;
  border: solid 1px #fff;
}

.chp_img.active {
  border: solid 3px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}

.roulette table tr td div.mainVal.active {
  animation: actvDiv 0.5s infinite;
}

@keyframes actvDiv {
  0% {
    box-shadow: inset 0 0 20px 10px #fff700;
  }

  50% {
    box-shadow: inset 0 0 0px 0px #fff700;
  }

  100% {
    box-shadow: inset 0 0 20px 10px #fff700;
  }
}

.mainVal.amr_0 {
  width: 100%;
  height: 50%;
  float: left;
  position: absolute;
  border-bottom: solid 2px #ff0;
}

.mainVal.amr_1 {
  width: 100%;
  height: 50%;
  top: 50%;
  float: left;
  position: absolute;
  border-bottom: solid 2px #ff0;
}

.AMR_Cover {
  position: fixed;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  display: none;
}

.z_350 {
  z-index: 350;
}

.winPopAmrcRlt {
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px;
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: targetWin 2s 1;
  transform: translate(-2000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
.roultepointer{
  pointer-events: none;
}
.popBorder {
  display: flex;
  justify-content: center;
}

@keyframes targetWin {
  0% {
    transform: translate(-1000px, 0px);
  }

  20% {
    transform: translate(0px, 0px);
  }

  80% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(2000px, 0px);
  }
}

.FGAamericanRoulette {
  background: linear-gradient(45deg, #664e13, #9d7b1e, #544314);
}

.FGAamericanRoulette .table_arnt {
  margin-top: 0px;
}

.FGAamericanRoulette .fd.amrChipList {
  display: flex;
  flex-wrap: wrap;
}

.FGAamericanRoulette .fd.amrChipList .amrRltChips img {
  width: 70%;
  box-shadow: 1px 1px 13px 6px rgb(0 0 0 / 30%);
  border-radius: 50%;
}

.FGAamericanRoulette .fd.amrChipList .amrRltChips {
  width: 33.33%;
  float: left;
}

.FGAamericanRoulette .fd.amrChipList .amrRltChips img.activeChip {
  border: solid 5px #fff500;
  border-radius: 50%;
}

.FGAamericanRoulette .balanceBoard.fd {
  background: linear-gradient(45deg, #161616, #0e0e0e);
  color: #fff;
  border: solid 17px #664e13;
  box-shadow: 1px 1px 11px 2px rgb(0 0 0 /50%);
  padding: 25px;
  margin-bottom: 35px;
  line-height: 65px;
}

.amr_info {
  background: linear-gradient(45deg, #080808, #0e0e0e);
  color: #fff;
  border: solid 17px #664e13;
  box-shadow: 1px 1px 11px 2px rgb(0 0 0 /50%);
  padding: 25px;
  font-size: 28px;
}

.FGAamericanRoulette .amr_userLbl {
  background: linear-gradient(45deg, #080808, #0e0e0e);
  color: #fff;
  border: solid 17px #664e13;
  box-shadow: 1px 1px 11px 2px rgb(0 0 0 /50%);
  font-size: 23px;
  padding: 25px;
}

.FGAamericanRoulette .ar_btns button:disabled {
  background: linear-gradient(#404040, #666666, #343434);
}

.FGAamericanRoulette .ar_btns button {
  background: linear-gradient(#3c3c3c, #000000, #1e1e1e);
  margin-top: 20px;
  border: solid 3px #ffffff69;
}

.FGAamericanRoulette table.american_HistoryTbl tr td {
  background: linear-gradient(90deg, #999999, #c1c1c1);
}

.FGAamericanRoulette #amRoutTimerWheel {
  margin-top: 0px;
}

.amr_userLbl.hId {
  margin-top: -40px;
}

/****************** NEW WHEEL START ********************************/

* {
  box-sizing: border-box;
}

.ar_wheelAnim img {
  width: 100%;
}

.ar_wheelAnim {
  position: relative;
  width: 480px;
  height: 480px;
  margin: auto;
  border-radius: 50%;
}

.ar_wheelAnimOne {
  width: 480px;
  height: 480px;
  margin: auto;
  left: 0;
  right: 0;
  top: 7px;
  position: absolute;
  transform: scale(1.61, 0.74);
  margin-top: 17px;
}

.ar_wheelAnimParent {
  width: 480px;
  height: 480px;
  margin: auto;
  left: 0;
  right: 0;
  position: relative;
  -webkit-animation: ar_spin 25s linear infinite;
  -moz-animation: ar_spin 25s linear infinite;
  animation: ar_spin 25s linear infinite;

}

@-moz-keyframes ar_spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes ar_spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes ar_spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.ar_ballBg {
  position: absolute;
  width: 480px;
  height: 480px;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-100px);
}



img.ar_rollBall {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.5s;
  transform: translateY(-165px);
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  box-shadow: 2px 2px 9px -1px rgb(0 0 0 / 50%);
  border-radius: 50%;
}


.ar_bollWrap {
  width: 480px;
  height: 480px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* .ar_WheelWraper {
  width: 1250px;
  height: 560px;
  position: relative;
  margin: auto;
  transform: scale(0.8, 0.9);
  left: -150px;
} */

.ar_WheelWraper {
  width: 1250px;
  height: 560px;
  position: relative;
  margin: auto;
  transform: scale(0.8, 0.9);
  margin-top: 5px;
  left: -150px;
}


.ar_wheelBorder {
  margin: auto;
  position: absolute;

}

.ar_wheelBorder img {
  width: 1250px;
  height: 560px;
}

.ar_wheelPoll img {
  width: 1250px;
  height: 560px;
}

.ar_wheelBorder_out {
  margin: auto;
  position: absolute;
  width: 1250px;
  height: 560px;
  /* transform: scale(0.79, 0.8); */
  top: -1px;
}

.ar_wheelBorder_out img {
  width: 1250px;
  height: 560px;
}

.ar_wheelPoll {
  margin: auto;
  position: absolute;
  transform: scale(1.23, 1.2);
  top: 12px;
  left: -3px;
}

.ar_wheelPoll img {
  width: 1250px;
  height: 560px;
}


/****************** NEW WHEEL END ********************************/


.mainVal::after,
.boxCntr::after,
.btmVal::after,
.cntrVal::after,
.leftVal::after,
.boxLeft::after,
.boxBtm::after,
.boxCrnr::after,
.boxTop::after,
.boxRight::after {
  background: #58000000;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 90;
}

.ar_btns {
  width: 20%;
  padding: 10px;
  padding-right: 20px;
  /* margin-top: 95px; */
  /* z-index: 50; */
  z-index: 1;
}

#roulette {
  margin-top: 15px important;

}

.m_t_-40 {
  margin-top: -38px;
  /* margin-top: -40px important; */
}

.ar_close button {
  background: none !important;
  margin-top: 0px !important;
  border: none !important;
}

.ar_close button img {
  width: 100%;
  position: relative;
  left: 320px;
  z-index: 500;
  /* top: 10px; */
}

.ar_close-timer {}

.chips0 {
  position: absolute;
  color: red;
  font-size: 33px;
  right: 337px;
  top: 27px;
  margin: 0px;
}

.chips1 {
  color: red;
  position: absolute;
  right: 193px;
  top: 26px;
  font-size: 33px;
  margin: 0px;
}

.chips2 {
  color: red;
  position: absolute;
  right: 53px;
  top: 33px;
  font-size: 28px;
  margin: 0px;

}

.chips3 {
  color: red;
  position: absolute;
  right: 325px;
  top: 150px;
  font-size: 28px;
  margin: 0px;

}

.chips4 {
  color: red;
  position: absolute;
  right: 185px;
  top: 152px;
  font-size: 25px;
  margin: 0px;
}

.chips5 {
  color: red;
  position: absolute;
  right: 49px;
  top: 149px;
  font-size: 22px;
  margin: 0px;
}

.chips6 {
  color: red;
  position: absolute;
  right: 331px;
  top: 264px;
  font-size: 20px;
  margin: 0px;
}

.chips7 {
  color: red;
  position: absolute;
  left: 199px;
  top: 262px;
  font-size: 20px;
  margin: 0px;
}

.ar_overlay-text {
  text-align: center;
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(50%, -207%);
  font-size: 25px;
  color:black !important;
}

.chipImg {
  cursor: pointer;
}

.histTxt {
  text-align: center;
}

.arnt_payTable {
  position: absolute;
  top: 10px;
  right: 121px;
  width: 70px;
  height: 70px;
  z-index: 1099;
}

.arnt_winratio {
  position: absolute;
  top: 99px;
  right: 20px;
  width: 900px;
  z-index: 5;
}

.arnt_table {
  display: none;
}
.rouletteTableCover{
  position: absolute;
  width: 7000px;
  /* background: red; */
  height: 1500px;
}
