.FGAfunTargetTimerLeftButtonPanel{
    width: 30vw;
    height: 50px;
    position: absolute;
    left: 5px;
    top:  calc(80vh - 60px);
    display: flex;
}

.FGAfunTargetTimerLeftButtonPanel .buttonOne .value{
    width: 100%;
    height: 50%;
    background-color: #b52f3f;
    color: #ffffff;
    border: none;
    font-size: 18px;

}
.FGAfunTargetTimerLeftButtonPanel .buttonOne .label{
    width: 100%;
    height: 50%;
    background-color: #f40037;
    color: #ffffff;
    border: none;
    font-size: 14px;

}
.FGAfunTargetTimerLeftButtonPanel .buttonOne{
    width: calc(33% - 10px);
    min-width: 85px;
    height: 100%;
    border: 1px solid black;
    border-radius: 2px;
}
.FGAfunTargetTimerLeftButtonPanel .buttonTwo .value{
    width: 100%;
    height: 50%;
    background-color: #b52f3f;
    color: #ffffff;
    border: none;
    font-size: 18px;
    
}
.FGAfunTargetTimerLeftButtonPanel .buttonTwo .label{
    width: 100%;
    height: 50%;
    background-color: #f40037;
    color: #ffffff;
    border: none;
    font-size: 14px;
    
}
.FGAfunTargetTimerLeftButtonPanel .buttonTwo{
    width: calc(33% - 10px);
    min-width: 85px;
    height: 100%;
    border: 1px solid black;
    border-radius: 2px;
    margin-left: 5px;
}
.FGAfunTargetTimerLeftButtonPanel .buttonThree .value{
    width: 100%;
    height: 50%;
    background-color: #b52f3f;
    color: #ffffff;
    border: none;
    font-size: 18px;

}
.FGAfunTargetTimerLeftButtonPanel .buttonThree .label{
    width: 100%;
    height: 50%;
    background-color: #f40037;
    color: #ffffff;
    border: none;
    font-size: 14px;

}
.FGAfunTargetTimerLeftButtonPanel .buttonThree{
    width: calc(33% - 10px);
    min-width: 85px;
    height: 100%;
    border: 1px solid black;
    border-radius: 2px;
    margin-left: 5px;
}