
.funTargetNonTimerCover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    /* background: #00000082; */
    z-index: 300; 
}

.sorat_chips {
  width: 20%;
  float: left;
}
.sorat_table {
  width: 60%;
  float: left;
}
.sorat_btns {
  width: 20%;
  float: left;
  padding: 10px;
}
.sorat_leftPanl {
  width: 36%;
  display: flex;
  float: left;
}
.sorat_btnPanel {
  width: 36%;
  float: right;
  display: flex;
}
.btns-top {
  /* margin-top: -23vh; */
  margin-top: -8%;
  display: flex;
  justify-content: space-between ;
  align-items: center;
  padding: 0px 10px;
}
.funTarget_chips {
  margin-top: 200px;
}
.funTarget_chips .col-4 {
  margin-top: 2vh;
}
.fl_1 {
  padding: 0 5px;
  flex: 1;
}
/* .bottomFixFuntarget .sorat_leftPanl .funBal div:nth-child(1){
    background: #168f57;
    padding: 0px;
} */
/* .bottomFixFuntarget .sorat_leftPanl .funBal div:nth-child(2){
  background: #2fae74;
  padding: 4px 0px;
} */

.bottomFixFuntarget .sorat_leftPanl .funBal div:first-child {
  background: #168f57;
  padding: 0;
  /* height: 60px; */
  font-size: 30px;
}

.bottomFixFuntarget .sorat_leftPanl .funBal div:nth-child(2) {
  background: #2fae74;
  padding: 4px 0;
  /* height: 60px; */
  font-size: 30px;
}
 
.bottomFixFuntarget .sorat_leftPanl .funBal {
  background: transparent;
}
.bottomFixFuntarget button {
    background: linear-gradient(#005e33, #168f57);
    border: none;
    color: #fff;
    padding: 24px 20px;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: 30px;
    transition: all 0.5s;
    border: solid 3px #2fae74;
}
.btmPanel {
  display: flex;
  width: 100%;
  float: left;
  margin-top: 20px;
}
.panelTabs {
  padding: 12px 10px;
  background: linear-gradient(#fba629, #fef580, #fba82c);
  margin-left: 15px;
  margin-right: 15px;
  flex: 1 1;
  height: 200px;
  border-radius: 8px;
  margin-top: -16px;
  box-shadow: 4px 5px 18px -7px rgb(0 0 0/50%);
  position: relative;
}
/* .panelTabs:hover {
  background: linear-gradient(#ff9933, #ff9933, #ffffcc);
} */
.funTrgtNon .pan {
  background-repeat: no-repeat;
  background-position: center;
  height: 175px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  position: relative;
}
.pan1 {font-size: 80px;}
.pan2 {color: #000;font-size: 40px;}
.pan1, .pan2 {
  height: 50%;
  text-align: center;
  vertical-align: text-top;
  font-weight: 500;
  position: relative;
}
img.sqBox {
  position: absolute;
  height: 171px;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.col-4.Select img {
  border: solid 3px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}

/* .panelTabs:hover [class*="pan"] {
  color: #f00;
} */

@keyframes trgtBdr {
  0% {
    border: dotted 5px #f00;
  }
  50% {
    border: dotted 5px #ff0;
  }
  100% {
    border: dotted 5px #f00;
  }
}

#FTwheelContainer {
  width: 100%;
  height: 100%;
  position: absolute;  
  /* border: 1px solid red; */
}
#FTwheelContainerOuter {
  width: 100%;
  height: 100%;
  position: absolute;  
  /* border: 1px solid red; */
}
.targetLayer {
  border: solid 5px #f00;
}
.borderLight {
  width: 63vh;
  height: 57.8vh;
  position: absolute;
  left: -20px;
  right: 0;
  margin: auto;
  z-index: 9;
  border-radius: 50%;
  top: 14.8vh;
  animation: trgtLight 0.4s infinite;
}

@keyframes trgtLight {
  0% {
    border: dotted 6px #2d0f0f;
  }
  50% {
    border: dotted 6px #ff0;
  }
  100% {
    border: dotted 6px #2d0f0f;
  }
}

.funTrgtBoard {
  width: 130%;
  float: left;
  border: 3px solid rgb(248 79 237 / 61%);
  border-radius: 15px;
  background-color: rgb(76 22 153 / 13%);
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 25px 10px;
  left: -33%;
  position: relative;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
}

table.HistoryTblTargetNonTimer.ftTBg tr td {
  background: linear-gradient(#e4100f, #571417);
}
table.HistoryTblTargetNonTimer tr td {
  color: #fff;
  background: linear-gradient(45deg, #29bf7a, #076438);
  text-align: center;
  padding: 11px 1px;
  font-size: 30px;
}
.funTrgtNon table.HistoryTblTargetNonTimer {
  width: 140%;
  float: left;
  position: relative;
  left: -43%;
  font-size: 16px;
  margin-top: 150px !important;
}
.jackpotImg img {
  width: 100%;
  padding: 30px;
}

 
 
.userFtarget {margin-right: 40px;} 



  .act.m_l_0 {margin-left: 0px !important;}

  

#FTwheelContainerOuter .konvajs-content, #FTwheelContainer .konvajs-content {margin: auto;}
.m_t_10vh {
  margin-top: 10vh;
}

.wheelBox_1 {
  float: left;
    width: 100%;
    position: relative;
}

.bottomFixFuntarget {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 150px;
  width: 100%;
  float: left;
}
.funTarget_chips .chp_img {
  width: 100px;
}

.targetBorder {
  width: 778px;
  height: 778px;
  border: solid 41px #f1a501;
  margin: auto;
  border-radius: 50%;
  box-shadow: inset 1px 1px 10px 14px rgb(0 0 0 / 50%);
  position: relative;
}
.targetBorderLights {
  width: 748px;
  height: 748px;
  border: dotted 16px #ffffff;
  margin: auto;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: -25px;
  animation: targetLights 0.5s infinite;
} 
img.targetTitleImg {
  width: 440px;
}
 
.indiTargetJackpot.jackPotNon {top:32vh; left:39.4%}
 

.indiTargetJackpot img.act {
  transition: all 0.5s; 
  margin-left: 20px;}

  .indiTargetJackpot {
    border-radius: 100%;
    z-index: 10;
    position: absolute;
    overflow: hidden;
    top: -5vh;
    left: 50px;
    /* width: 30vh; */
    /* height: 30vh; */
    right: 0;
    margin: auto;
}
.indiTargetJackpot img.jack_1 {
  width: 350px;
  height: 350px;
  position: absolute;
  z-index: 5000;
  margin-top: 2px;
  margin-left: 20px;
  background-color: radial-gradient(black, transparent);
  /* background-color: red; */
  top: -10px;
  left: 4px;
}
.indiTargetJackpot .jockpotBox {
    background: radial-gradient(farthest-side at 20% 15%,#7d7d7d, black, black);
    z-index: 9;
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;
    top: 0;
    bottom: 0;
    margin-top: 125px;
}
.indiTargetJackpot .funTargetWinImg {
    position: absolute;
    right: 0; 
    width: 130px;
    left: 0vh;
}
.indiTargetJackpot .funTargetWinImg img {width: 100%; float: left;}

.target_chips {width: 20%;float: left;}
.target_table {width: 60%;float: left;}
.target_btns {width: 20%;float: left;}

.indiTargetJackpot div#marqueeTag img {
    padding: 5px;
    width: 80px;
    width: 80px;
    margin-top: 6px;
}

.indiTargetJackpot div#jackpotImg {
  position: absolute;
  background: #ffb000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding: 8vh; */
  align-items: center;
  box-shadow: inset 0px -4px 6px 5px rgb(0 0 0 / 50%);
  border-radius: 50%;
}
.indiTargetJackpot div#jackpotImg img {
  width: 150px;
  height: 150px;
}
.winPopTarget { 
  display: none;
  position: fixed;
  right: 0;
  width: 600px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, hsl(34, 87%, 32%));
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px; 
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: targetWin 3s 1; 
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
.popBorder {
  display: flex;
  justify-content: center;
}
@keyframes targetWin{
  /* 0% { transform: translate(-1000px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px); } */

  0% { transform: translate(0px, 0px); }
  20% { transform: translate(0px, 350px); }
  80% { transform: translate(0px, 350px); }
  100% { transform: translate(0px, 2000px); }
}
 
.jockpotBoxCover {
  background: linear-gradient(90deg, #CB8A2C, #FFF782, #CB8A2C);
  width: 210px;
  height: 210px;
  border-radius: 50%;
  z-index: 99;
  position: absolute;
  top: 120px;
  left: 94px;
  box-shadow: 1px 1px 50px 32px rgb(0 0 0 / 50%);
}
.funTrgtNon {
    background: radial-gradient(#006e76, #005242);
    padding: 30px;
}
.funTrgtNon .userLbl {
  background: #168f57;
}

.funTrgtNon button.fd.ar_bt_1:disabled {
  background: linear-gradient(#b7b7b7, #707070, #484848);
  cursor: no-drop;
  border: solid 2px #cfcfcf;
}

/****************** NEW WHEEL START ********************************/

* {box-sizing: border-box;}
.ft_wheelAnim img { width: 100%;}
.ft_wheelAnim {
    position: relative;
    margin: auto; 
    border-radius: 50%;  
}
.ft_wheelAnimOne {
  width: 681PX;
  height: 600PX;
  margin: auto;
  left: 60px;
  right: 12px;
  top: 25px;
  position: absolute;
  z-index: 0;
}


.ft_wheelBorder {
  margin: auto;
  position: relative;
  z-index: 9;
  width: 720px;
}
.ft_wheelWraper {
    width: 800px;
    height: 800px;
    position: relative;
    margin: auto;
}

.ft_wheelBorder img {
  width: 769px;
  height: 725px;
}

/****************** NEW WHEEL END ********************************/

.betCoverFTW {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.addCursorPointer{
  cursor: pointer;
}


.ft_chip0 {
  position: absolute;
  color: black;
  font-size: 25px;
  left: 72px;
  top: 28px;
  margin: 0px;
  text-align: center;
}

.ft_chip1 {
  color: black;
  position: absolute;
  top: 30px;
  font-size: 25px;
  margin: 0px;
  left: 70px;
}

.ft_chip2 {
  color: black;
  position: absolute;
  left: 65px;
  font-size: 25px;
  margin: 0px;
  top: 30px;
}

.ft_chip3 {
  color: black;
  position: absolute;
  top: 30px;
  font-size: 25px;
  margin: 0px;
  left: 62px;
}

.ft_chip4 {
  color: black;
  position: absolute;
  top: 30px;
  font-size: 25px;
  margin: 0px;
  left: 60px;
}

.ft_chip5 {
  color: black;
  position: absolute;
  right: 56px;
  top: 30px;
  font-size: 25px;
  margin: 0px;
}

.ft_chip6 {
  color: black;
  position: absolute;
  right: 52px;
  top: 30px;
  font-size: 25px;
  margin: 0px;
}

.ft_chip7 {
  color: black;
  position: absolute;
  right: 63px;
  top: 30px;
  font-size: 25px;
  margin: 0px;
}


.chipImg{
  cursor: pointer;
}

.chp_img_over:hover { cursor: pointer; transform: scale(1.10); }

.overlay-text {
  text-align: center;
  position: absolute;
  /* top: 50%; */
  right: 19%;
  /* transform: translate(50%, -70%); */
  font-size: 25px;
  width: 100px;
  /* background: gray; */
  top: 32px;
  color:black !important;
}


img.chp_img:hover { cursor: pointer; transform: scale(1) !important; }


.ftt_scrollImages{
  animation: ftt_transBox 4s 0s linear infinite;
}

@keyframes ftt_transBox {
  0% { transform: translateX(-1900px); }
  100% { transform: translateX(0px); }
}


.ftt_overallBox {
  width: 204px;
  height: 199px;
  position: absolute;
  overflow: hidden;
  top: 245px !IMPORTANT;
  border-radius: 50%;
  left: 610px;
  z-index: 10;
  display: block;
}


.ftt_mainTrans {
  width: 1980px;
  height: 230px;
  left: 0;
  margin: auto;
  top: 100px;
  margin-top: -20px;
  margin-left: -10px;
}

.indiTargetJackpot.jackPotNon {top:32vh; left:39.4%}
 

.indiTargetJackpot img.act {
  transition: all 0.5s; 
  margin-left: 20px;}

  .indiTargetJackpot {
    border-radius: 100%;
    z-index: 10;
    position: absolute;
    overflow: hidden;
    top: -5vh;
    left: 50px;
    /* width: 30vh; */
    /* height: 30vh; */
    right: 0;
    margin: auto;
}
.indiTargetJackpot img.jack_1 {
  width: 350px;
  height: 350px;
  position: absolute;
  z-index: 5000;
  margin-top: 2px;
  margin-left: 20px;
  background-color: radial-gradient(black, transparent);
  /* background-color: red; */
  top: -10px;
  left: 4px;
}
.indiTargetJackpot .jockpotBox {
    background: radial-gradient(farthest-side at 20% 15%,#7d7d7d, black, black);
    z-index: 9;
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;
    top: 0;
    bottom: 0;
    margin-top: 125px;
}
.indiTargetJackpot .funTargetWinImg {
    position: absolute;
    right: 0; 
    width: 130px;
    left: 0vh;
}
.indiTargetJackpot .funTargetWinImg img {width: 100%; float: left;}

.target_chips {width: 20%;float: left;}
.target_table {width: 60%;float: left;}
.target_btns {width: 20%;float: left;}

.indiTargetJackpot div#marqueeTag img {
    padding: 5px;
    width: 80px;
    width: 80px;
    margin-top: 6px;
}

.indiTargetJackpot div#jackpotImg {
  position: absolute;
  background: #ffb000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding: 8vh; */
  align-items: center;
  box-shadow: inset 0px -4px 6px 5px rgb(0 0 0 / 50%);
  border-radius: 50%;
}
.indiTargetJackpot div#jackpotImg img {
  width: 150px;
  height: 150px;
}