
.FGAfunTargetTimerBalanceLabel{
    width:15vw;
    min-width: 150px;
    height: 40px;
    border: 2px solid rgba(255, 255, 255,0.3);
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 60px;
    right: 10px;
    border-radius: 5px;
    display: flex;
}
.FGAfunTargetTimerBalanceLabel .logo{
    width:38px;
    height: 38px;
    float: left;
    background-image: url("../../../../assets/lobby/icons/profileIcon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.FGAfunTargetTimerBalanceLabel .value{
    width:calc(15vw - 40px);
    height: 26px;
    margin-top: 6px;
    float: right;
    color: #ffffff;
    font-size: 20px;
    text-indent: 5px;
}