@keyframes motion1 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  45% {
    left: 85%;
    top: 85%;
  }

  50% {
    left: 80%;
    top: 85%;
  }

  100% {
    left: 5.5%;
    top: 86%;
  }
}


@keyframes motion2 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }


  100% {
    left: 9.5%;
    top: 86%;
  }
}


@keyframes motion3 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }

  100% {
    left: 13.5%;
    top: 86%;
  }
}

@keyframes motion4 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }

  100% {
    left: 17.5%;
    top: 86%;
  }
}

@keyframes motion5 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }

  100% {
    left: 21.5%;
    top: 86%;
  }
}

@keyframes motion6 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }

  100% {
    left: 25.5%;
    top: 86%;
  }
}

@keyframes motion7 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  50% {
    left: 85%;
    top: 85%;
  }

  100% {
    left: 29.5%;
    top: 86%;
  }
}

@keyframes motion8 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 33.5%;
    top: 86%;
  }
}

@keyframes motion9 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 37.5%;
    top: 86%;
  }
}

@keyframes motion10 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 41.5%;
    top: 86%;
  }
}

@keyframes motion11 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 45.5%;
    top: 86%;
  }
}

@keyframes motion12 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 49.5%;
    top: 86%;
  }
}

@keyframes motion13 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 53.5%;
    top: 86%;
  }
}

@keyframes motion14 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 57.5%;
    top: 86%;
  }
}

@keyframes motion15 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 61.5%;
    top: 86%;
  }

}

@keyframes motion16 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 65.5%;
    top: 86%;
  }
}

@keyframes motion17 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 69.5%;
    top: 86%;
  }
}

@keyframes motion18 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }


  100% {
    left: 73.5%;
    top: 86%;
  }
}

@keyframes motion19 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 77.5%;
    top: 86%;
  }
}

@keyframes motion20 {
  0% {
    left: 87.5%;
    top: 55%;
  }

  40% {
    left: 87%;
    top: 82.9%;
  }

  100% {
    left: 81.5%;
    top: 86%;
  }
}


@keyframes motions1 {
  100% {
    left: 5.5%;
    top: 86%;
  }
}


@keyframes motions2 {
  100% {
    left: 9.5%;
    top: 86%;
  }
}


@keyframes motions3 {
  100% {
    left: 13.5%;
    top: 86%;
  }
}

@keyframes motions4 {
  100% {
    left: 17.5%;
    top: 86%;
  }
}


@keyframes motions5 {
  100% {
    left: 21.5%;
    top: 86%;
  }
}

@keyframes motions6 {
  100% {
    left: 25.5%;
    top: 86%;
  }
}

@keyframes motions7 {
  100% {
    left: 29.5%;
    top: 86%;
  }
}

@keyframes motions8 {
  100% {
    left: 33.5%;
    top: 86%;
  }
}

@keyframes motions9 {
  100% {
    left: 37.5%;
    top: 86%;
  }
}

@keyframes motions10 {
  100% {
    left: 41.5%;
    top: 86%;
  }

}

@keyframes motions11 {
  100% {
    left: 45.5%;
    top: 86%;
  }

}

@keyframes motions12 {
  100% {
    left: 49.5%;
    top: 86%;
  }
}

@keyframes motions13 {
  100% {
    left: 53.5%;
    top: 86%;
  }

}

@keyframes motions14 {
  100% {
    left: 57.5%;
    top: 86%;
  }

}

@keyframes motions15 {
  100% {
    left: 61.5%;
    top: 86%;
  }

}

@keyframes motions16 {
  100% {
    left: 65.5%;
    top: 86%;
  }
}

@keyframes motions17 {
  100% {
    left: 69.5%;
    top: 86%;
  }
}

@keyframes motions18 {
  100% {
    left: 73.5%;
    top: 86%;
  }

}

@keyframes motions19 {
  100% {
    left: 77.5%;
    top: 86%;
  }
}

@keyframes motions20 {
  100% {
    left: 81.5%;
    top: 86%;
  }
}



@keyframes glowing {
  0% {
    box-shadow: 0 0 5px 5px #ff3c00;
  }

  50% {
    box-shadow: 0 0 10px 10px #b6afaf;
  }

  100% {
    box-shadow: 0 0 0 0 #ff0000;
  }
}


html,
body {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

/* .point {
    position: absolute;
   
    width: 35px;
    height: 35px;
    margin-left: -10px;
    margin-top: -5px;
    border-radius: 100%;
    background-color: rgb(195, 9, 9);
    animation: motion 8000ms infinite    linear  infinite;
    background-size: cover;
    transform-origin: 50% 50%;
    color: white;
     display: flex; 
    justify-content: center; 
     align-items: center; 
    font-size: 20px;
    font-style:italic;
    font-weight: bold;
    
    
    
  } */


/* .selected{
   background-color:    linear  infinite-gradient(to bottom, #1300bf, #000000);
  } */
.selected {
  background-color: #1300bf;
  /* Fill Color 1 */
  color: #000000;
  /* Fill Color 2 */
}

.matched {
  background-color: rgba(16, 118, 3, 0.903);
}

.takebuttonimg {
  position: absolute;
  top: 10%;
  left: 7%;
  color: #ffffff00;
  font-size: 500px;
  z-index: 50;

}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.text_overlaybonusdup {
  position: absolute;
  color: #ffffff;
  font-size: 38px;
  width: 265px;
  top: 142px;
  left: 78px;
  text-align: center;
  font-weight: 900;
  margin: auto;
  /* background: #00fff5; */
}

.shuffle {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 41.5%;
  left: 35.5%;

}


.dstxt {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: 65%;
}

.fdkeno {
  width: 100%;
  float: left;
  position: relative;
}

.point {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 100px;
  margin-left: -10px;
  margin-top: -5px;
  border-radius: 100%;
  background-color: rgb(195, 9, 9);
  background-size: cover;
  transform-origin: 50% 50%;

}

.pointsre {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 100px;
  margin-left: -10px;
  margin-top: -5px;
  border-radius: 100%;
  background-color: rgb(195, 9, 9);
  background-size: cover;
  transform-origin: 50% 50%;
  display: none;

}

.tble {
  width: 50%;
  height: 50%;
  color: #000;
}

.point1 {
  animation: motion1 500ms 0s;
}

.point2 {
  animation: motion2 500ms 0.5s;
}

.point3 {
  animation: motion3 500ms 1s;
}

.point4 {
  animation: motion4 500ms 1.5s;
}

.point5 {
  animation: motion5 500ms 2s;
}

.point6 {
  animation: motion6 500ms 2.5s;
}

.point7 {
  animation: motion7 500ms 3s;
}

.point8 {
  animation: motion8 500ms 3.5s;
}

.point9 {
  animation: motion9 500ms 4s;
}

.point10 {
  animation: motion10 500ms 4.5s;
}

.point11 {
  animation: motion11 500ms 5s;
}

.point12 {
  animation: motion12 500ms 5.5s;
}

.point13 {
  animation: motion13 500ms 6s;
}

.point14 {
  animation: motion14 500ms 6.5s;
}

.point15 {
  animation: motion15 500ms 7s;
}

.point16 {
  animation: motion16 500ms 7.5s;
}

.point17 {
  animation: motion17 500ms 8s;
}

.point18 {
  animation: motion18 500ms 8.5s;
}

.point19 {
  animation: motion19 500ms 9s;
}

.point20 {
  animation: motion20 500ms 9.5s;
}

.pointMain {
  position: absolute;
  top: -2000px;
  width: 85px;
  height: 85px;
  margin-left: -10px;
  margin-top: -22px;
  border-radius: 100%;
  background-size: cover;
  transform-origin: 50% 50%;
  z-index: 8;
}



.disableBtns {
  cursor: no-drop;
  opacity: 0.6;
}

.takebt {
  /* position: absolute; */
  width: 250px;
  height: 100px;
  /* top: 40%; */
  /* left: 20%; */
}

.points1 {
  animation: motions1 10ms;
}

.points2 {
  animation: motions2 10ms;
}

.points3 {
  animation: motions3 10ms;
}

.points4 {
  animation: motions4 10ms;
}

.points5 {
  animation: motions5 10ms;
}

.points6 {
  animation: motions6 10ms;
}

.points7 {
  animation: motions7 10ms;
}

.points8 {
  animation: motions8 10ms;
}

.points9 {
  animation: motions9 10ms;
}

.points10 {
  animation: motions10 10ms;
}

.points11 {
  animation: motions11 10ms;
}

.points12 {
  animation: motions12 10ms;
}

.points13 {
  animation: motions13 10ms;
}

.points14 {
  animation: motions14 10ms;
}

.points15 {
  animation: motions15 10ms;
}

.points16 {
  animation: motions16 10ms;
}

.points17 {
  animation: motions17 10ms;
}

.points18 {
  animation: motions18 10ms;
}

.points19 {
  animation: motions19 10ms;
}

.points20 {
  animation: motions20 10ms;
}

.displaymessages {
  position: absolute;

}

.activeChip {
  border: solid 5px #fff500;
  border-radius: 50%;
}


.bgbt {

  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 600px
}

.videoanimate {
  position: absolute;
  background-color: #888;
  width: 11%;
  top: 37%;
  left: 28.5%;
  background-color: transparent;
  z-index: 500;
  position: absolute;

}



.videoanimateball {
  position: absolute;
  width: 1011px;
  top: 40%;
  left: 30%;
  z-index: 100;

}

.image {
  height: 238px;
  width: 146px;
}

.winratio_heading {
  color: white;
  font-size: 26px;
}
.winratio_prize {
  position: absolute;
  left: 290px;
  top: -18px;
  /* width: 17px; */
  /* height: 160px; */
  color: white;
  font-size: 27px;
}


.kenoratio_table{
  display: none;
}
.keno_winratio {
  position: absolute;
  top: 90px;
  left: 1620px;
  width: 475px;
  z-index: 5;
}
/* .i_symbol {
  cursor: pointer;
  width: 69px;
  height: 63px;
  background: transparent;
  border: none;
}
.keno_payTable {
  position: absolute;
  top: 25px;
  left: 1797px;
  width: 70px;
  height: 70px;
} */
.kn_winpop {
  margin: auto;
  position: relative;
  top: 18%;
  left: 1%;
  right: 25%;
  background-image: url("../../../../../../assets/games/win_popup/win_popup.png");
  padding: 0px 25px 25px 25px;

  border-radius: 6px;
  animation: kn_popup_anim 3s 1;
  z-index: 1000;
  height: 700px;
  background-repeat: no-repeat;
  width: 1000px;
}


@keyframes kn_popup_anim {
  0% {
    transform: scale(0.01);
  }

  20% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.01);
  }
}

.kn_win_txt {
  width: 174px;
  position: absolute;
  font-size: 43px;
  font-family: 'Century_Gothic';
  font-weight: bold;
  color: #000000;
  text-align: center;
  top: 64.5%;
  left: 46%;
  transform: translate(-50px, -50px);
}

.popup_backkeno {
  display: none;
  position: fixed;
  background: #433e3e52;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0px;
  z-index: 999;
}

.kenobg {
  position: absolute;
  width: 100%;
  height: 1250px;
  background-image: url('../../../../../../assets/games/keno/keno_bg.png');
  background-size: cover;
  background-position: center;

}

.gameim {
  width: 3000px;
  height: 30px;
  top: 155px;
  left: -65px;

}

.namebg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  top: 155px;
  left: -65px;

}

/* .image-container {
    position: relative;
   } */
.Tube {
  position: absolute;
  top: 50.5%;
  left: 2%;
  height: 42%;
  width: 90%;
}

/* position: absolute;
    
    top: 38%;
    left: 13%;
    height: 44%;
    width: 75%; */
    .keno_InfoPanelBg {
      position: absolute;
      left: 3%;
      right: 57%;
      left: 1%;
      height: 224px;
      width: 467px;
      top: 374px;
      left: -33px;
  }

@keyframes scaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}


.addanimationtotext {
  animation: scaleUp 0.5s ease-in-out infinite alternate;
}

.addanimationtotextstop {
  animation: ease-in-out;
}

.showblinkgtext {
  animation: scaleUp 0.5s ease-in-out infinite alternate;
}

.betplus {
  position: absolute;
  /* cursor: pointer; */
  top: 185px;
  left: 332px;
  /* z-index: 100; */
  background: no-repeat;
  border: none;
  width: 55px;
  height: 55px;
}
.betminus {
  position: absolute;
  /* cursor: pointer; */
  top: 181px;
  left: 34px;
  /* z-index: 100; */
  background: none;
  border: none;
  width: 55px;
  height: 55px;
}

.another {
  position: absolute;
  width: 5%;
  height: 4%;
  top: 81.5%;
  left: 26%;

}

.buttake {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 11%;
  height: 8%;
  top: 92.5%;
  left: 8%;
  background: transparent;
  border: none;
}

.butdup {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 11%;
  height: 8%;
  top: 92.5%;
  left: 44%;
  background: transparent;
  border: none;
}

.butsele {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 5%;
  height: 4%;
  top: 92.5%;
  left: 34%;
  background: transparent;
  border: none;
}

.butstrt {
  position: absolute;
  font-family: "Luckiest Guy";
  cursor: pointer !important;
  width: 5%;
  height: 4%;
  top: 92.5%;
  left: 58%;
  background: transparent;
  border: none;
}
.butbet {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 11%;
  height: 8%;
  top: 92.5%;
  left: 60%;
  background: transparent;
  border: none;
}
.clear {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 5%;
  height: 4%;
  top: 92.5%;
  left: 21%;
  background: transparent;
  border: none;
}

.disablebetbtn {
  opacity: 0.2;
  cursor: no-drop;
}

.keno_InfoTxt {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
  width: 381px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  top: 409px;
  padding: 10px;
  left: 10px;
  height: 150px;
  text-align: center;
  font-family: 'Century_Gothic';
}

.emptyPointer {
  cursor: none !important;
}

.butext {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 150px;
  height: 150px;
  top: 1%;
  right: 1%;
  background: transparent;
  border: none;
  z-index: 1520;
}

.starskeno1 {
  position: absolute;
  margin-left: 512px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno2 {
  position: absolute;
  margin-left: 597px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno3 {
  position: absolute;
  margin-left: 680px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno4 {
  position: absolute;
  margin-left: 763px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno5 {
  position: absolute;
  margin-left: 845px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno6 {
  position: absolute;
  margin-left: 928px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno7 {
  position: absolute;
  margin-left: 1402px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno8 {
  position: absolute;
  margin-left: 1485px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno9 {
  position: absolute;
  margin-left: 1570px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno10 {
  position: absolute;
  margin-left: 1656px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno11 {
  position: absolute;
  margin-left: 1739px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno12 {
  position: absolute;
  margin-left: 1820px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.starskeno13 {
  position: absolute;
  margin-left: 1900px;
  margin-top: -60px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
}

.keno_exit_Txt {
  position: absolute;
  color: white;
  font-size: 20px;
  font-weight: 900;
  top: 50%;
  /* justify-content: center; */
  /* align-items: center; */
  text-wrap: wrap;
  /* width: 129px; */
  /* text-align: center; */
  left: 50%;
  /* height: 105px; */
  /* background: green; */
  display: inline-block;
  /* word-break: break-word; */
  /* padding: 40px; */
  align-content: center;
  transform: translate(-50%, -50%);
}

.text_overlaygr span {
  position: absolute;
  color: #ffffff;
  font-size: 25px;
  font-weight: 900;
  top: -2px;
  left: -4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000;
}

.text_overlayclear span {
  position: absolute;
  color: #ffffff;
  font-size: 25px;
  font-weight: 900;
  top: 55%;
  left: 54%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_overlaydb span {
  color: black;
  font-size: 25px;
  font-weight: 900;
  position: absolute;
  top: 3px;
  left: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.text_overlayd span {
  color: white;
  font-size: 25px;
  font-weight: 900;
  position: absolute;
  top: 56%;
  left: 60%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_overlayt span {
  color: black;
  font-size: 25px;
  font-weight: 900;
  position: absolute;
  top: 66%;
  left: 350%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_overlays span {
  color: black;
  font-size: 25px;
  font-weight: 900;
  position: absolute;
  top: 59%;
  left: 52%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_overlayst span {
  position: absolute;
  color: black;
  /* cursor: pointer !important; */
  font-size: 25px;
  font-weight: 900;
  top: 4px;
  width: 100%;
  height: 100%;
  left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  /* background-color: red; */
}

.take_txt span {
  width: 195px !important;
  left: 43% !important;
}

.take_txt_anim span {
  width: 167px;
  left: 61%;
}

.userframe {
  position: relative;
  top: 55%;
  margin-top: 29%;
  left: -26%;
  margin-left: 2%;
  width: 303px;
  height: 4%;
}

.text_overlaywin {
  position: relative;
  top: 2px;
  left: 11px;
  width: 411px;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ffffff;
  font-weight: 900;
  /* background: blue; */
}

.text_overlaywinningbet {
  position: absolute;
  top: 183px;
  left: 73px;
  color: #ffffff;
  font-size: 38px;
  font-weight: 900;
  width: 250px;
  /* background-color: red; */
  text-align: center;
}

.text_overlaybonus {
  position: absolute;
  /* top: -44px; */
  left: 1858px;
  top: 100px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
  width: 266px;
  /* background: green; */
  text-align: center;
}

.text_overlaywinning {
  position: absolute;
  margin-top: -80px;
  left: 1844px;
  color: #ffffff;
  font-size: 38px;
  font-weight: 900;
  width: 295px;
  /* background-color: red; */
  text-align: center;
}

.text_overlaybetpannel {
  position: absolute;
  left: 60px;
  top: 353px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
  width: 310px;
  /* background-color: red; */
  text-align: center;
}

.kenobetpannel {
  color: #ffffff;
  font-weight: bold;
  font-family: "SAGONABOOKBOLD";
  font-size: 45px;
  font-style: italic;
  margin-right: 5.5px;
  margin-left: -138%;
  margin-top: -11%;
  margin-bottom: 19px;
  width: 114px;
  height: 80px;
  box-shadow: inset 0px 0px 10px #888;
  outline: none;
  z-index: 55;
  font-family: 'arial';
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dupglow {  
  width: 250px;
  height: 100px;
  animation: scaleUp 0.5s ease-in-out infinite alternate;
}

#takebutt.blink {
  animation: blink 1s infinite;
}

.betokBtn {
  position: relative;
  /* top: -90%; */
  width: 250px;
  font-size: 25px;
  color: white;
  height: 100px;
  /* left: -48%; */
  text-align: center;
  animation: scaleUp 0.5s ease-in-out infinite alternate;
}

.textbetok {
  animation: scaleUp 0.5s ease-in-out infinite alternate;
}

.usercontainer {
  position: absolute;
  top: 84%;
  left: 90%;
}

.userid {
  position: absolute;
  top: 60%;
  right: 30%;
  color: #ffffff;
  font-size: 28px;
  /* background: red; */
  width: 214px;
  text-align: center;
}

.htmlweb {

  width: 50%;
  height: 50%;
}

.fill-color-mixed {
  background-image: linear-gradient(to bottom, #a13108, #f9a233);
}

.fill-color-mixedb {
  background-image: linear-gradient(to bottom, #1300bf, #000000);
}

.fill-color-mixedg {
  background-image: linear-gradient(to bottom, #1f9400, #0be50b);
}

.fill-color-mixedr {
  background-image: linear-gradient(to bottom, #ff1021, #ff0000);
}

.fill-color-mixedo {
  background-image: linear-gradient(to bottom, #FFA500, #FF4500);
}

.fill-color-mixedgran {
  background-image: linear-gradient(to bottom, #1f9400, #0be50b);
}

.fill-color-mixedgclick {
  background-image: linear-gradient(to bottom, #1f9400, #0be50b);
}

.fill-color-mixedgtak {
  background-image: linear-gradient(to bottom, #1f9400, #0be50b);
}

.fill-color-mixedred {
  background-image: linear-gradient(to bottom, #ff1021, #ff0000);
}

.text_overlaybet span {
  position: absolute;
  top: 4%;
  left: -159%;
  color: #ffffff;
  font-size: 25px;
  font-weight: 900;

}

.text_overlaywinner {
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  /* background-color: red; */
  text-align: center;
}

.text_overlaywinner span {
  left: 128%;
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
}

.text_overlaybalance {
  position: absolute;
  top: 127px;
  left: 77px;
  color: #ffffff;
  font-size: 38px;
  font-weight: 900;
  width: 268px;
  /* background: red; */
  text-align: center;
}

.kenonam {
  position: absolute;
  /* left: 37%; */
  width: 100%;
  /* height: 300px; */
}
.betpannel {
  position: absolute;
  width: 428px;
  height: 212px;
  margin-top: 106px;
}
.betpannelbonus {
  position: absolute;
  left: 1800px;
  top: 95px;
  width: 380px;
  height: 170px;
  margin-top: 40px;
}

.win1 {
  position: absolute;
  top: 61%;
  right: 78%;
  /* background: red; */
  width: 430px;
  height: 260px;
}

.win {
  position: relative;
  top: 20px;
  left: 180px;
  width: 400px;
  height: 220px;
}

.winner {
  margin-top: 20px;
}

.tbbg {
  display: flex;
  flex-direction: row;
  /* justify-content: end; */
  top: 12.5%;
  left: 23%;
  position: absolute;
}

.keno_removepointer {
  /* cursor: pointer; */
  width: 200px;
  height: 200px;
  margin-top: 10px;
  position: relative;
}

.tubeImg {
  z-index: 1;
}

.kenocage {
  position: absolute;
  top: 22%;
  left: 79%;
  /* z-index: 1; */
  width: 1000px;
  border: none;
  height: 524px;
  background: transparent;
}
.betTable {
  position: absolute;
  top: 20.8%;
  left: 31.5%;
  z-index: 1;
  margin-left: 10px;
  margin-top: -12px;
}

.disabledDiv {
  opacity: 0.6;
  pointer-events: none;
}

.kenoCover {
  position: absolute;
  width: 1301px;
  height: 799px;
  background-color: #8880;
  z-index: 999;
  display: none;
}

.kenoCover_betpannel {
  position: absolute;
  left: -16px;
  top: -70PX;
  width: 451px;
  height: 309px;
  /* background-color: #f40037; */
  z-index: 999;
  display: none;
}

/* .kenoanimatio {
  position: absolute;
  
} */

.kenoanimatio {
  position: absolute;
  top: 242px;
  left: 1602px;
}


.kenoanimatiogif {
  position: absolute;
  top: 239px;
  left: 1602px;
}

.topPannel {
  position: relative;
  width: 100%;
  height: 85%;
  background-color: #1f9400;
}

.bottomPannel {
  position: relative;
  width: 100%;
  height: 15%;
  background-color: #0128ec;
}

.leftPannel {
  position: relative;
  /* width: 30%; */
  height: 100%;
  background-color: #114a16;
}

.rightPannel {
  position: relative;
  /* left:30%;
  width: 70%; */
  height: 100%;
  background-color: #7fca85;
}

.buttonPannel {
  position: relative;
  width: 100%;
  height: 50%;
  background-color: #e5e113;
}

.infoPannel {
  position: relative;
  /* top: 50%; */
  width: 100%;
  height: 50%;
  background-color: #f3640b;
}

.leftInfoPanel {
  position: relative;
  width: 100%;
  height: 60%;
  background-color: #8b000a;
}

.chipPannel {
  position: relative;
  width: 100%;
  height: 40%;
  top: 60%;
  background-color: #ef949a;
}

.logoPannel {
  position: relative;
  width: 100%;
  height: 15%;
  background-color: #2c1012;
}

.animPannel {
  position: relative;
  width: 100%;
  top: 15%;
  height: 85%;
  background-color: #857c7c;
}

.gBonusImg {
  position: absolute;
  /* left: -153px; */
  width: 428px;
  height: 212px;
  top: 50px;
}
.kenonam img {
  margin: auto;
  display: block;
  top: -110px;
  position: absolute;
  left: 40%;
}

.chipscontainer {
  width: 450px;
  margin-top: 180px;
}

.betAmt {
  margin-top: 25px;
}

.leftInfo {
  position: relative;
  width: 450px;
  height: 1020px;
  left: 93px;
  /* background: #f5f5dc87; */
  top: 20px;
}

.keno_pid_val {
  position: relative;
  right: 143px;
  /* display: flex; */
  font-size: 29px;
  color: white;
  font-weight: bold;
  font-family: 'Century_Gothic';
  top: 10px;
  text-align: right;
  /* background: blue; */
}
.incrBtn {
  width: 60px;
  height: 60px;
}

.decrBtn {
  width: 60px;
  height: 60px;
}

.keno_InfoPanel {
  margin-top: 30px;
  position: absolute;
}

.betBtnImg {
  position: absolute;
}

/* .payTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 10px #8d2c1b solid;
}


#payTable td, #payTable th {
  border: 5px solid #cd7f32;
  text-align: center;
  padding: 2px;
  color: #000;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}

#payTable tr:nth-child(even) {
  background-color: #ffbf00;
  color: #000;
}
#payTable tr:nth-child(odd) {
  background-color: #eba937;
  color: #000;
}
#tableHead th {
  background-color: #cd7f32;
  width: 150px !important;
} */

.cageVideo {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: 990;
  /* display: none; */
  top: 150px;
  right: 250px;
}

.cageImage {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: 1000;
  top: 240px;
  right: 250px;
}

.kenocageimages {
  position: absolute;
  top: 634px;
  left: 1700px;
  width: 548px;
  height: 238px;
}

.text_overlaydouble{
   color: black;
  font-size: 25px;
  font-weight: 900;
  position: absolute;
  top: 3px;
  left: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.cageanimation {
  position: absolute;
  top: 255px;
  left: 1585px;
}

.butdouble {
  position: absolute;
  font-family: "Luckiest Guy";
  width: 11%;
  height: 8%;
  top: 92.5%;
  left: 47%;
  background: transparent;
  border: none;
}
.kn_sparkAnim {
  /* display: none; */
  width: 2350px;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 1275px;
  transform: scale(2,2);
}

.keno_starblink{
  animation: scaleUp  0.5s ease-in-out infinite alternate;  
}
