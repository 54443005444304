.cardDiv img {
  width: 100%;
  transition: all 0.5s;
}

.cardDiv:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

.jokerCard {
  position: absolute;
  top: 25%;
  left: 40.5%;
  width: 19%;
}

.animateimages0 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.1s;
  /* height: 100%; */
}


.animateimages1 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.2s;


}

.animateimages2 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.3s;

}

.animateimages3 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.4s;

}

.animateimages4 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.5s;

}

.animateimages5 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.6s;

}

.animateimages6 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.7s;
}

.animateimages7 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.8s;
}

.animateimages8 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 0.9s;
}

.animateimages9 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 1.0s;

}

.animateimages10 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 1.1s;

}

.animateimages11 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 1.2s;

}

.animateimages12 {
  position: absolute;
  top: 25%;
  left: 40.5%;
  opacity: 0.7;
  width: 19%;
  animation: cardsandbr 150ms ease-in-out 1.3s;
}


@keyframes cardsandbr {
  0% {

    transform: translate(0px, -70px);
  }

  100% {
    opacity: 0.02;
    transform: translate(0px, 90px);
  }
}

.cardDiv {
  padding: 5px;
  flex-grow: 1;
  position: relative;
  cursor: pointer;
  /* background-image: url("../../../../../../assets/games/andarBaharTimer/cardGlow.png"); */
}

.ab_btnPanel {
  width: 100%;
  float: left;
}

.ab_ins {
  width: 100%;
  float: left;
  text-align: center;
  padding: 0px 10px;
  background: #363d6a;
  height: 281px;
  border-radius: 10px;
  box-shadow: 5px 5px 9px rgb(0 0 0 /50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
}

.ab_userLbl img {
  width: 40px;
  margin-right: 5px;
}

.ab_userLbl {
  background: #000000a8;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 15px;
  color: #fff;
  border: solid 1px #ffffff36;
  float: right;
  width: 280px;
  right: 15px;
  font-size: 30px;
  position: relative;
  display: flex;
}

.ab_betok {
  position: relative;

}

.buttoncancel {
  width: 300px;
}

.ab_buttondouble {
  position: relative;
  transform: rotate(-180deg);
}

.ab_buttonBet {
  width: 300px;
}


.ab_playerBox,
.ab_dealerBox {
  margin-left: 80px;
  display: unset;
}

.Ab_title {
  width: 400px;
  float: left;
  text-align: center;
  margin-left: 230px;
  margin-top: 10px;
}

.ab_gldDiv img {
  width: 100%;
}

.ab_gldDiv {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 36px;
  bottom: 0;
  left: -50px;
  right: 0;
  margin: auto;
  text-align: center;
  cursor: pointer;
  display: flex;
  background: #00800000;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

.ab_gldDiv span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: #000;
  font-weight: bold;
  line-height: 78px;
  font-size: 25px;
  cursor: pointer;
}

.cardBox .ab_dealerBox img {
  margin-left: -80px;
  box-shadow: 1px 1px 22px -5px rgb(0 0 0);
  height: 190px;
  position: relative;
  z-index: 5;
}

.cardBox .ab_dealerBox img:nth-last-child(1) {
  height: 190px;
  animation: dealerCard 0.5s 1;
}

.ab_andarBox {
  height: 220px;
  animation: andarcardDealing 0.3s 1;
  position: absolute;
  width: 170px;
  left: 18px;
  top: 10px;
}

.ab_andarBox_load {
  height: 220px;
  position: absolute;
  width: 170px;
  left: 18px;
  top: 10px;
}

.ab_baharBox {
  height: 220px;
  animation: baharcardDealing 0.3s 1;
  position: absolute;
  width: 170px;
  left: 26px;
  top: 22px;
}

.ab_baharBox_load {
  height: 220px;
  position: absolute;
  width: 170px;
  left: 26px;
  top: 22px;
}

@keyframes andarcardDealing {
  0% {
    height: 10px;
    width: 10px;
    transform: translate3d(990px, -550px, 600px);
  }
}

@keyframes baharcardDealing {
  0% {
    height: 10px;
    width: 10px;
    transform: translate3d(310px, -550px, 400px);
  }
}

.cardBox .ab_cards img {
  margin-left: -80px;
  box-shadow: 1px 1px 22px -5px rgb(0 0 0);
  height: 190px;
  position: relative;
  z-index: 5;
}

.cardBox .ab_cards img:nth-last-child(1) {
  height: 190px;
  animation: playerCard 0.5s 1;
}

@keyframes playerCard {
  0% {
    height: 20px;
    transform: translate3d(1800px, -1200px, 1000px);
  }
}

label.playerCheck input {
  float: left;
  width: 50px;
  height: 50px;
  outline: solid 2px rgb(221, 181, 0);
  margin-right: 10px;
}

label.playerCheck {
  color: #fff;
  cursor: pointer;
  text-shadow: 2px 2px 3px rgb(0 0 0);
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.cardsCover {
  position: absolute;
  background: #dddddd00;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: none;
}

.font_2vh {
  font-size: 2vh;
}

.ab_Cover {
  z-index: 99;
  /* display: none; */
  background: #ff000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 150;
}

.z_320 {
  z-index: 320;
}

.z_1020 {
  z-index: 1020;
}

.Ab_title .titleImg {
  height: 220px;
}


.andarBaharbg {
  background-size: 100% 100%;
  width: 100%;
  float: left;
  height: 100%;
  background-image: url('../../../../../../assets/games/andarBaharTimer/ab_bg.png');
  /* padding: 30px; */
  /* height: 1250px; */
  position: fixed;
}

.ab .ab_Bal {
  background: #363D6A;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 /20%);
}

.ab .ab_Bal.fd div:nth-child(1) {
  background: #ddd;
  text-align: center;
  background: #222848;
  font-size: 28px;
  border-radius: 8px;
  padding: 8px 0px;
  color: #fdcd2f;
}

.ab .ab_Bal.fd div:nth-child(2) {
  font-size: 28px;
  color: #fff;
  text-align: center;
  padding: 13px 0px;
  background: transparent;
}

.anbhr_balance {
  display: flex;
  float: left;
  position: relative;
  height: 160px;
  top: 10px;
  width: 500px;
}

.score {
  position: absolute;
  font-size: 30px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
  color: #ffffff;
  width: 162px;
  line-height: 160px;
  text-align: center;
  margin: auto;
  padding: unset;
}

.scoreTxt {
  position: absolute;
  /* background: red; */
  color: black;
  top: 55px;
  width: 205px;
  left: 200px;
  line-height: 60px;
  text-align: center;
  font-size: 40px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
}

.WINNER {
  position: absolute;
  font-size: 28px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
  color: #ffffff;
  width: 170px;
  line-height: 165px;
  /* background: #ff0000c9; */
  text-align: center;
  float: right;
  left: 340px;
  height: 160px;
}

.glowcard {
  position: absolute;
  top: 86%;
  left: 40%;
}

.num8_k {
  position: absolute;
  top: 86%;
  left: 57%;
}

.andarsubttle {
  position: absolute;
  top: 19px;
  left: 75px;
  width: 100px;
}

.baharsubttle {
  position: absolute;
  top: 24px;
  left: 75px;
  width: 100px;
}

.anbhr_winner {
  position: relative;
  display: flex;
  height: 160px;
  float: right;
  top: 10px;
}

.playCardCloseGame button img {
  width: 100%;
}

.playCardCloseGame button {
  background: transparent;
  width: 70px;
  height: 70px;
  float: right;
  /* margin-top: 70px; */
  /* margin-right: 30px; */
  /* border-radius: 5px; */
  border: none;
  z-index: 1024;
  position: relative;
}

.ab_btnPanel button {
  width: 47%;
  float: left;
  font-weight: bold;
  height: 80px;
  padding: 0px !important;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 28px;

  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgb(0 0 0 /50%);
}

.ab_btnPanel .mabBtn_1 {
  background: #25af60;
}

.ab_btnPanel .mabBtn_2 {
  background: #FF0066;
}

.ab_btnPanel .mabBtn_3 {
  background: #25af60;
  margin-left: 27%;
}


.winPopAndarBahar {
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px;
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: andarBahar 2s 1;
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}

.popBorder {
  display: flex;
  justify-content: center;
}

@keyframes andarBahar {
  0% {
    transform: translate(-1000px, 0px);
  }

  20% {
    transform: translate(0px, 0px);
  }

  80% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(2000px, 0px);
  }
}


.ab_btnPanel .mabBtn_1:disabled {
  background: #90ab9c;
}

.ab_btnPanel .mabBtn_2:disabled {
  background: #c1819a;
}

.ab_btnPanel .mabBtn_3:disabled {
  background: #90ab9c;
}

.ab_cards span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: 'Century_Gothic';

  background: url("../../../../../../assets/gold_coin.svg");
  background-size: 80px;
  background-repeat: no-repeat;
  /* background: url("../../../../../../assets/gold_coin.svg"); */
  /* background: linear-gradient(#f59407,#f7f812, #f7f812, #f59407);     */
  /* border-radius: 50%; */
}

.buttonscontainer {
  position: relative;
}

.exitBtn {
  position: relative;
  z-index: 200;
  margin-right: 25px;
}

.clearBtn {
  position: relative;
  margin-right: 25px;
}

.ab_doubleBtn {
  position: relative;
  margin-right: 25px;
}

.betLbl {
  position: relative;
  margin-right: 25px;
}

.chipstext0 {
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  z-index: 50;
  top: 27px;
  left: 27px;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext1 {
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  top: 27px;
  left: 27px;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext2 {
  position: absolute;
  top: 27px;
  left: 27px;
  font-size: 20px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext3 {
  position: absolute;
  top: 26px;
  left: 22px;
  font-size: 20px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext4 {
  position: absolute;
  top: 27px;
  left: 23px;
  font-size: 20px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext5 {
  position: absolute;
  top: 27px;
  left: 19px;
  font-size: 18px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext6 {
  position: absolute;
  top: 27px;
  left: 19px;
  font-size: 20px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chipstext7 {
  position: absolute;
  top: 27px;
  left: 17px;
  font-size: 16px;
  color: #ffffff;
  font-family: 'ChaparralPro_Regular';
  font-weight: bolder;
}

.chip0 {
  position: relative;
  /* top: 55%; */
  /* left: 3.7%; */
  /* font-size: large; */
}

.chip1 {
  position: relative;
  /* top: 80%;
  left: 6.5%; */
}

.chip2 {
  position: relative;
  /* top: 116%;
  left: 10%; */
}


.chip3 {
  position: relative;
  /* top: 170%;
  left: 14%; */
}


.chip4 {
  position: absolute;
  top: 278%;
  left: 73%;
}

.chip5 {
  position: relative;
  top: 738%;
  left: 1185%;
}

.chip6 {
  position: relative;
  top: 548%;
  left: 1510%;
}

.chip7 {
  position: absolute;
  top: 205%;
  left: 426%;

}

.titleline {
  position: absolute;
  top: -10%;
  left: 0%;
  width: 100%;
}
.titleandar {
  position: relative;
  top: 90px;
  left: -8%;
  width: 45%;
}

.titlebahar {
  position: absolute;
  top: 90px;
  left: 63%;
  width: 45%;
}

.titlelinebottom {
  position: absolute;
  top: 97%; 
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.heart {
  position: relative;
  /* top: 15%; */
  /* left: 23%; */
  z-index: 20;
  cursor: pointer;
  width: 160px;
  height: 160px;
  /* background: yellow; */
}

.spade {
  position: relative;
  /* top: 15%; */
  /* left: 32%; */
  z-index: 20;
  cursor: pointer;
  width: 160px;
  height: 160px;
  /* background: red; */
}

.club {
  position: relative;
  /* top: 15%; */
  /* left: 71%; */
  z-index: 20;
  cursor: pointer;
  width: 160px;
  height: 160px;
}

.dimond {
  position: relative;
  /* top: 15%; */
  /* right: -28%; */
  z-index: 20;
  cursor: pointer;
  width: 160px;
  height: 160px;
}

.buttontake {
  position: absolute;
  top: 103%;
  left: 18%;
}


.buttonexit {
  /* position: relative; */
  width: 300px;
}

.textoverEXIT {
  font-size: 35px;
  color: #fdd21c;
  font-weight: bolder;
  /* position: absolute; */
  transform: translate(-50%, -50%);
  top: 47%;
  left: 50%;
  width: 90%;
  /* background: yellow; */
  font-family: Pretoria;
  text-align: center;
  position: absolute;
}

.textoverbetok {
  font-size: 35px;
  color: #f8d53f;
  font-weight: bolder;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-family: Pretoria;
  /* background: yellow; */
}

.textovertake {
  font-size: 35px;
  color: #ff0000;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Pretoria;
}

.textovercancelbet {
  font-size: 35px;
  color: #ffffff;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  text-align: center;
  font-family: Pretoria;
  /* background: beige; */
}

.textoverdouble {
  font-size: 35px;
  color: #ffffff;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Pretoria;
}

.cardscontainer {
  position: relative; 
  display: flex;
  flex-direction: row;
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
}

.fd.ab_cards {
  position: absolute;
  display: flex;
  top: 5%;
  width: 70%;
  left: 15%;
}

.pannelcards {
  position: relative;
  top: 10%;
  left: -8%;
  display: flex;
  flex-direction: row;
  z-index: 20;
  width: 800px;
}

.fstcrd {
  margin-right: 10%;
  z-index: 20;
}

.fstcrd_bg {
  width: 250px;
  height: 410px;
}

.jokerCard_bg {
  width: 250px;
  height: 400px;
}

.sestcrd {
  margin-right: 10%;
  /* display: flex; */
}

.trdcrd {
  margin-right: 5%;
  /* cursor: pointer; */
  z-index: 20;
  display: flex;
  position: relative;
}

.timer_bg {
  position: absolute;
  top: 20%;
  left: 38%;
  width: 180px;
  height: 180px;
}

img#timer {
  width: 180px;
  height: 180px;
}

.timer_txt {
  position: absolute;
  width: 76px;
  font-size: 65px;
  font-family: 'SAGONABOOKBOLD';
  /* background: red; */
  z-index: 1000;
  text-align: center;
  top: 50px;
  left: 54px;
  font-weight: bold;
  color: white;
}

.ab_Chps {
  position: relative;
  top: -150px;
  z-index: 10;
  height: 314px;
  left: 290px;
}

.chip_bg0 {
  position: relative;
  top: 70px;
  right: 0px;
  display: flex;
  left: -165px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;

}

.chip_bg1 {
  position: absolute;
  top: 190px;
  right: 0px;
  display: flex;
  left: -55px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg2 {
  position: absolute;
  top: 265px;
  right: 0px;
  display: flex;
  left: 90px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg3 {
  position: absolute;
  top: 280px;
  right: 0px;
  display: flex;
  left: 250px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg4 {
  position: absolute;
  top: 280px;
  right: 0px;
  display: flex;
  right: 840px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg5 {
  position: absolute;
  top: 260px;
  right: 0px;
  display: flex;
  left: 1545px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg6 {
  position: absolute;
  top: 200px;
  right: 0px;
  display: flex;
  left: 1695px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg7 {
  position: absolute;
  top: 70px;
  right: 0px;
  display: flex;
  left: 1810px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.chip_bg8 {
  position: absolute;
  bottom: -126px;
  right: 0px;
  display: flex;
  left: -245px;
  width: 130px;
  height: 130px;
  /* cursor: pointer; */
  justify-content: center;
  align-items: center;
}

.ab_goldcoin img {
  width: 100%;
}

.ab_goldcoin {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.suit_goldcoin {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  margin: auto;
}

.ab_goldcoin span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: #000;
  font-weight: bold;
  font-size: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: 'SAGONABOOKBOLD';
  background: linear-gradient(#f59407, #f7f812, #f7f812, #f59407);
  border-radius: 50%;
}

.boxRight p.ab_goldcoin {
  left: -38px;
}

.buttonList {
  position: relative;
  /* bottom: 25px; */
  justify-content: center;
  /* left: 60px; */
  align-items: center;
  display: flex;
  width: 100%;
}

.andar_goldCoin {
  position: relative !important;
  top: -40px;
  display: flex;
  align-items: center;
  left: 0px;
}

.andarBet {
  position: relative;
  left: 8px;
}

.baharBet {
  position: relative;
  left: 5px;
}

.abh_betBox {
  width: 195px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  text-align: center;
  color: #000;
  font-weight: bold;
  font-size: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: 'Century_Gothic';
  background: linear-gradient(#f59407, #f7f812, #f7f812, #f59407);
}

.bahar_goldCoin {
  position: relative !important;
  top: -41px;
  left: -1px;
  align-content: center;
}

.one_six {
  position: absolute;
  background-color: #9a5b0b00;
  width: 132px;
  height: 80px;
  left: 319px;
  top: 421px;
  cursor: pointer;
  /* border-radius: 40%; */
}

.bet7 {
  position: absolute;
  width: 90px;
  height: 80px;
  background-color: #25af6000;
  left: 413px;
  top: 455px;
  cursor: pointer;
}

.eight_k {
  position: absolute;
  background-color: #9a5b0b00;
  width: 135px;
  height: 77px;
  top: 419px;
  left: 455px;
  cursor: pointer;
}

.bottom_num_goldCoin {
  position: relative !important;
  top: 43px;
  display: flex;
  align-items: center;
  left: 67px;
  align-content: center;
}

.a6_goldCoin {
  position: relative !important;
  top: 43px;
  display: flex;
  align-items: center;
  left: -65px;
  align-content: center;
}

.bottom_num7_goldCoin {
  position: relative !important;
  top: 51px;
  left: 0px;
  display: flex;
  align-items: center;
  align-content: center;
}

.ab_chip_active {
  color: #FBEC0F;
  animation: winBox 0.5s infinite;
  border: solid 6px;
  border-radius: 50%;
  position: relative;
}

#ab_chip_10 {
  width: 95px;
}

#ab_chip_25 {
  width: 95px;
}

#ab_chip_25 {
  width: 95px;
}

#ab_chip_50 {
  width: 95px;
}

#ab_chip_100 {
  width: 95px;
}

#ab_chip_500 {
  width: 95px;
}

#ab_chip_1000 {
  width: 95px;
}

#ab_chip_5000 {
  width: 95px;
}

#ab_chip_0 {
  width: 95px;
}

.totalBetAmt {
  position: absolute;
  text-align: center;
  font-size: 32px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
  line-height: 40px;
  white-space: normal;
  color: white;
  top: 57%;
  left: 17%;
  transform: translate(-50px, -50px);
  background: #66339900;
  width: 360px;
  /* height: 50px; */
  text-align: center;
}

.topLable {
  position: relative;
  width: 100%;
  height: 20%;
  display: flex;
  /* top: 0px; */
  background: #0072ff00;
}
.winnerTxt {
  position: absolute;
  color: black;
  top: 44px;
  width: 240px;
  left: 80px;
  line-height: 66px;
  text-align: center;
  font-size: 40px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
  margin: auto;
  padding: unset;
  float: right;
}

.top_right {
  display: flex;
}

.title_layout {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 20px;
}

.ab_disBtn {
  opacity: 0.7;
  cursor: no-drop;
  pointer-events: none;
}

.suitImg {
  width: 160px;
}

.baharBetArea {
  position: absolute;
  background: #fd4a0200;
  width: 240px;
  height: 75px;
  top: 415px;
  left: 4px;
}

.andarBetArea {
  position: absolute;
  background: rgb(61 213 26 / 0%);
  width: 237px;
  height: 83px;
  bottom: -6px;
  left: 0px;
  /* background: gray; */
}

.dimond_hit_area {
  position: absolute;
  width: 160px;
  height: 160px;
  background-color: #ff000000;
  top: 0px;
  left: 0px;
  border-radius: 50%;
}

.infoBox {
  position: relative;
  width: 41%;
  height: 30px;
  top: 260px; 
  word-wrap: break-word;
  line-break: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
}

.infoTxt {
  font-size: 30px;
  font-family: 'SAGONABOOKBOLD';
  font-weight: bold;
  text-align: center;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
}

.animateimages {
  div#animatecards {
    position: relative;
    top: 32%;
    left: 124%;
  }
}

.jokerCardRuslt {
  position: absolute;
  width: 229px;
  left: 340px;
  top: 124px;
  height: 280px;
  /* background: darkgreen; */
}

.resJokerCard_anim {
  position: absolute;
  width: 170px;
  left: 29px;
  top: 25px;
  height: 220px;
  display: none;
}

.historyHolder {
  position: absolute;
  width: 520px;
  height: 110px;
  /* background: yellow; */
  left: 39%;
  bottom: 23%;
}

.hisCard_0 {
  position: absolute;
  width: 90px;
  height: 110px;
  left: 1%;
}

.hisCard_1 {
  position: relative;
  width: 150px;
  height: 190px;
  /* right: 7%; */
  /* background: gray; */
}

.hisCard_2 {
  position: relative;
  width: 150px;
  height: 190px;
  /* left: 6%; */
  /* top: 0px; */
  /* background: green; */
}

.hisCard_3 {
  position: relative;
  width: 150px;
  height: 190px;
  /* right: 5%; */
}

.cardLine1 {
  position: absolute;
  height: 110px;
  width: 6px;
  color: #000;
  background: #ffd215;
  left: 5%;
  top: 0%;
}

.cardLine2 {
  position: absolute;
  height: 110px;
  width: 6px;
  color: #000;
  background: #ffd215;
  left: 27%;
  top: 0%;
}

.cardLine3 {
  position: absolute;
  height: 110px;
  width: 6px;
  color: #000;
  background: #ffd215;
  left: 49%;
  top: 0%;
}

.cardLine4 {
  position: absolute;
  height: 110px;
  width: 6px;
  color: #000;
  background: #ffd215;
  left: 71%;
  top: 0%;
}

.cardLine5 {
  position: absolute;
  height: 110px;
  width: 6px;
  color: #000;
  background: #ffd215;
  left: 93%;
  top: 0%;
}

.resAndarCard {
  position: absolute;
  width: 210px;
  height: 237px;
  top: 140px;
  left: 22px;
}

.resBaharCard {
  position: absolute;
  width: 220px;
  height: 250px;
  left: 15px;
  top: 130px;
}

.ab_timer_glow {
  position: absolute;
  border: 20px solid;
  border-color: yellow;
  border-radius: 50%;
  width: 180px;
  animation: borderAnim_ab 1.2s infinite;
  height: 180px;
}

@keyframes borderAnim_ab {
  from {
    border: solid 12px #fffe00dd;
  }

  to {
    border: solid 12px transparent;
  }
}

.cardDeckImg {
  position: absolute;
  align-items: end;
  right: -240px;
  top: 10px;
  width: 70px;
  height: 120px;
  z-index: 150;
  transform: rotate(45deg);
}

.abbuttonglow {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 2s infinite ease-in-out;
  position: absolute;
  top: -23px;
  left: -25px;
  width: 412px;
  height: 170px;
  pointer-events: none;
}

.btnImgSize {
  height: 120px;
}

.hidebuttonglow {
  position: absolute;
  top: -36px;
  left: -45px;
  width: 363px;
}

img.betButton {
  position: relative;
}

.abt_overlay_text {
  position: absolute;
  font-size: 26px;
  color: black;
  position: absolute;
  font-weight: 800;
  font-family: 'SAGONABOOKBOLD';
  cursor: pointer;
}

.plr_dtls {
  position: absolute;
  top: 180px;
  font-size: 20px;
  font-family: 'SAGONABOOKBOLD';
  color: #fff;
  left: 20px;
}

.suitBetArea {
  position: relative;
  width: 100%;
  height: 60%;
  /* background: #7fffd400; */
  top: 14px;
  left: -10px;
}

.ab_gameArea {
  position: relative;
  width: 100%;
  /* background: #00800047; */
  /* height: 85%; */
  margin-top: 15px;
}

.ab_btn_height {
  height: 120px;
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 300px;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.chips_ab_timer {
  all: unset;
  transform: scale(0.9);
}


.ab_active {
  color: #ffef00;
  /* animation: winBox 0.5s infinite; */
  /* border: solid 7px; */
  border-radius: 50%;
  /* position: absolute; */
  /* box-sizing: border-box; */
  box-shadow: 1px 1px 10px 12px rgb(255 231 0) !important;
}

.ab_chipImg {
  width: 100px;
  height: 100px;
}

.colSize {
  /* width: 100%; */
  /* height: 100%; */
}

.leftSuitRow {
  position: relative;
  /* top: 8%; */
  left: 20%;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 75%;
}

.rightsuitRow {
  position: relative;
  /* top: 6.5%; */
  right: -14%;
  width: 75%;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.leftHistRow {
  position: relative;
  /* top: 44%; */
  left: 42%;
  background: #ffff0000;
  width: 51%;
  height: 190px;
  justify-content: space-evenly;
  top: 10px;
}

.rightHistRow {
  position: relative;
  top: 10px;
  right: -13%;
  /* background: rgb(172, 172, 124); */
  width: 51%;
  height: 190px;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

/* The animation code */
@keyframes imgHover {
  from {
    scale: 1;
  }

  to {
    scale: 1.1;
  }
}

/* The element to apply the animation to */
.showHoverEff {
  animation-name: imgHover;
  animation-duration: 1s;
}

img#andar_glow {
  position: absolute;
  left: -33px;
  bottom: -45px;
  width: 320px;
  height: 167px;
  display: none;
}

.a_6_glowcard {
  position: absolute;
  right: 336px;
  bottom: -18px;
  display: flex;
  width: 154px;
  height: 104px;
  display: none;
}

.glowcard7 {
  position: absolute;
  right: 259px;
  bottom: -45px;
  display: flex;
  width: 164px;
  height: 166px;
  display: none;
}

.glowcard_8k {
  position: absolute;
  right: 200px;
  bottom: -18px;
  display: flex;
  width: 153px;
  height: 105px;
  display: none;
}

.baharbetGlow {
  position: absolute;
  left: -36px;
  bottom: -45px;
  width: 320px;
  height: 167px;
  display: none;
}

.abGlowAnim {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 2s infinite ease-in-out;
  pointer-events: none;
  position: absolute;
  left: -36px;
  bottom: -45px;
  width: 320px;
  height: 167px;
}

.clubbetGlow {
  position: absolute;
  left: -50px;
  bottom: -48px;
  width: 260px;
}

img#hisCardBg_0 {
  width: 91%;
  position: absolute;
}

img#hisCard_0 {
  position: absolute;
  width: 60%;
  top: 50px;
  left: 21px;
}

img#hisCardBg_3 {
  position: relative;
  width: 91%;
}

img#hisCard_3 {
  position: absolute;
  left: 23px;
  width: 60%;
  top: 25%;
}

.hisCard_4 {
  position: relative;
  width: 150px;
  height: 190px;
  /* left: 10%; */
  /* display: flex; */
}

img#hisCard_2 {
  position: absolute;
  left: 23px;
  width: 60%;
  top: 25%;
}

img#hisCardBg_2 {
  position: relative;
  width: 91%;
}

img#hisCardBg_1 {
  width: 91%;
  position: absolute;
}

img#hisCard_1 {
  position: absolute;
  width: 60%;
  top: 50px;
  left: 21px;
}

/* .cardbetGlow {
  position: absolute;
  width: 189px !important;
  height: 200px;
  left: -24%;
  bottom: -20px;
  display: none;
} */

.winglow {
  display: block;
  position: absolute;
  display: flex;
  width: 535px;
  bottom: -19px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 2s infinite ease-in-out;
  right: -29px;
  height: 197px;
}

.hidewinglow {
  position: absolute;
  display: flex;
  width: 511px;
  bottom: -15px;
  display: none;
}

.andarbetGlowAnim {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 2s infinite ease-in-out;
  pointer-events: none;
}

.cardbetGlow {
  position: absolute;
  width: 141px !important;
  height: 190px;
  left: -2%;
  bottom: 3px;
  /* display: none; */
  border: yellow 15px solid;
  border-radius: 15px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation: blink normal 2s infinite ease-in-out;
  pointer-events: none;
}

.showGlow {
  display: block;
}

.hideGlow {
  display: none;
}

.abBgGlow {
  position: absolute;
  width: 350px;
  height: 500px;
  left: -50px;
  top: -50px;
}

.winPopandb { 
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px; 
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: matkaWin 2s 1;
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
.popBorderandb {
  display: flex;
  justify-content: center;
}