.FGAfunTargetBetPanel{
    width: calc(100% - 10px);
    height:calc(20vh - 10px);
    position: absolute;
    /* bottom: 10px; */
    /* top:calc(80vh + 5px); */
    left: 5px;
    display: flex;
}

.FGAfunTargetBetPanel .buttonZero:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonZero{
    width:calc(10vw - 1px);
    height: 100%;
}


.FGAfunTargetBetPanel .buttonZero .valueOne{
    width: 15vh;
    height: 40%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonZero .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonOne:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonOne{
    width:calc(10vw - 1px);
    height: 100%;
}

.FGAfunTargetBetPanel .buttonOne .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonOne .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonTwo:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonTwo{
    width:calc(10vw - 1px);
    height: 100%;
}

.FGAfunTargetBetPanel .buttonTwo .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonTwo .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonThree:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonThree{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonThree .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonThree .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonFour:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonFour{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonFour .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonFour .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonFive:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonFive{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonFive .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonFive .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonSix:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonSix{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonSix .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonSix .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonSeven:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonSeven{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonSeven .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonSeven .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonEight:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonEight{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonEight .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonEight .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .buttonNine:hover{
    transform: scale(0.9);
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.FGAfunTargetBetPanel .buttonNine{
    width:10%;
    height: 100%;
}

.FGAfunTargetBetPanel .buttonNine .valueOne{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
    /* padding-top: 10px; */
}
.FGAfunTargetBetPanel .buttonNine .valueTwo{
    width: 100%;
    height: 50%;
    color: black;
    font-size: 25px;
    text-align: center;
}


.FGAfunTargetBetPanel .UnSelected{
    transform: scale(1);
}

.FGAfunTargetBetPanel .selected{
    transform: scale(0.85);
}


.class1{
    color: red;
}
.class2{
    color: blue;
}