.eurpRltCover {
    position: fixed;
    /* background: rgba(0, 0, 0, 0.61); */
    background: transparent;
    top: 93px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: none;
}

.roulette table.eurpRouletteTimerTable tr td.red {
    background: #f00;
}

.roulette {
    margin-top: 0px !important;
}

.eurpRTimerWinPop {
    position: fixed;
    right: 0;
    width: 800px;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    height: -moz-fit-content;
    height: fit-content;
    font-size: 28px;
    margin: auto;
    z-index: 999;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: solid 12px #edcc4a;
    text-align: center;
    box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
    animation: eur_TimerResult 3s 1;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    font-weight: bold;
    box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}

@keyframes eur_TimerResult {
    0% {
        transform: translate(-500px, 0px);
    }

    20% {
        transform: translate(0px, 0px);
    }

    80% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(2000px, 0px);
    }
}

.euroRltTimer {
    background: linear-gradient(45deg, #3e1495, #0e002c);
}

.eurpRouletteTimerTable {
    margin-top: -25px !important;
}

.euroRltTimer .wheelandhis .fun_chips .funBal {
    background: transparent;
}

.euroRltTimer .wheelandhis .fun_chips .funBal div:nth-child(1) {
    background: linear-gradient(90deg, #140239, #8d4cf7, #180441);
    padding: 5px 0px;
    color: #fff;
    font-weight: bold;
}

.euroRltTimer .wheelandhis .fun_chips .funBal div:nth-child(2) {
    background: #2100005e;
    padding: 4px 0px;
}

.euroRltTimer .fun_btns button.ar_bt_1 {
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    border: solid 3px #f3d550;
    margin-top: 11px;
    color: #000;
    font-weight: bold;
    padding: 23px;
}

.euroRltTimer .fun_btns button.ar_bt_1:disabled {
    background: linear-gradient(90deg, #4a4742, #bbbbbb, #4a4742);
    border: solid 3px #bdbdbd;
}

.erpRltChps.fd .am_chip img {
    width: 108px;
    margin-top: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.erpRltChps.fd .am_chip {
    width: 33.33%;
    float: left;
    cursor: pointer;
}

.erpRltChps.fd {
    display: flex;
    flex-wrap: wrap;
}

.eurpTmrIns {
    width: 100%;
    float: left;
    background: linear-gradient(90deg, #140239, #8d4cf7, #180441);
    border: solid 1px #1f1f1f;
    padding: 10px;
    font-size: 30px;
    color: #fff;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.euroRltTimer .userLbl {
    background: linear-gradient(90deg, #140239, #8d4cf7, #180441);
    padding: 15px;
    width: 370px;
    margin-bottom: 15px;
    color: #fff;
    /* border: solid 1px #ffffff36; */
    font-size: 28px;
    display: flex;
    height: 87px;
    justify-content: flex-start;
    align-items: center;
}

.erpRltChps img.activeChip {
    border: solid 5px #f5d851;
}

/****************** NEW WHEEL START ********************************/

* {
    box-sizing: border-box;
}

.ert_wheelAnim img {
    width: 100%;
}

.ert_wheelAnim {
    position: relative;
    width: 480px;
    height: 480px;
    margin: auto;
    border-radius: 50%;
}

.ert_wheelAnimOne {
    width: 480px;
    height: 480px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    transform: scale(1.78, 0.82);
    margin-top: 45px;
}

.ert_wheelAnimParent {
    width: 480px;
    height: 480px;
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    -webkit-animation: ert_spin 25s linear infinite;
    -moz-animation: ert_spin 25s linear infinite;
    animation: ert_spin 25s linear infinite;

}

@-moz-keyframes ert_spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes ert_spin {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes ert_spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.ert_ballBg {
    position: absolute;
    width: 480px;
    height: 480px;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-100px);
}



img.ert_rollBall {
    position: absolute;
    left: 0;
    right: 0;
    transition: all 0.5s;
    transform: translateY(-165px);
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    box-shadow: 2px 2px 9px -1px rgb(0 0 0 / 50%);
    border-radius: 50%;
}


.ert_bollWrap {
    width: 480px;
    height: 480px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.ert_wheel_poll {
    margin: auto;
    position: absolute;
    z-index: 2;
    transform: scale(1.4, 1.3);
    top: 45px;
    left: -4px;
}

.ert_wheelBorder {
    margin: auto;
    position: absolute;
    width: 1250px;
    height: 560px;
}

.ert_WheelWraper {
    width: 1250px;
    height: 560px;
    position: relative;
    margin: auto;
    transform: scale(0.7, 0.8);
    left: -150px;
}

.ert_wheel_poll img {
    width: 1250px;
    height: 560px;
}

/****************** NEW WHEEL END ********************************/


.ert_wheelBorder_out {
    margin: auto;
    position: absolute;
    width: 1250px;
    height: 560px;
    /* transform: scale(0.79, 0.8); */
    top: -1px;
}

.ert_wheelBorder_out img {
    width: 1250px;
    height: 560px;
}

.mainVal::after,
.boxCntr::after,
.btmVal::after,
.cntrVal::after,
.leftVal::after,
.boxLeft::after,
.boxBtm::after,
.boxCrnr::after,
.boxTop::after,
.boxRight::after {
    background: #58000000;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 90;
}

.FGAamericanRoulettee {
    padding: 0px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;

}

.eropean-img {

    width: 100%;
    position: unset;
    left: 320px;
    margin: 0px;
    cursor: pointer;
}


.ert_overlay-text {
    text-align: center;
    position: relative;
    top: 50%;
    right: 50%;
    transform: translate(50%, -446%);
    font-size: 25px;
    color:black !important;
}

.ert_histTxt {
    text-align: center;
  }

  .ert_table{
    margin-top: 0;
  }