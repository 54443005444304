.FGAfunTargetTimerInfoBoard{
    width:15vw;
    min-width: 160px;
    height: 15vh;
    min-height: 80px;
    border: 2px solid rgba(0, 0,0,0.8);
    border-radius: 5px;
    position: absolute;
    right:10px;
    top:110px;
    background-color: rgba(0, 0,0,0.6);
    color: #ffffff;
    text-align: center;
    font-size: 18px;
}