.FGAfunSoratRightButtonPanel{
    width: 180px;
    height: 240px;
    position: absolute;
    right: 20px;
    top: 40vh;
}


.FGAfunSoratRightButtonPanel .buttonZoom:hover{
    background-color: #b52f3f;
}
.FGAfunSoratRightButtonPanel .buttonZoom{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;

}
.FGAfunSoratRightButtonPanel .buttonOne:hover{
    background-color: #b52f3f;
}
.FGAfunSoratRightButtonPanel .buttonOne{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;

}


.FGAfunSoratRightButtonPanel .buttonTwo{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    background-color: #f40037;
    border-radius: 2px;
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;

}

.FGAfunSoratRightButtonPanel label{
    width: 120px;
    height: 40px;
    color: black;
    background-color: blanchedalmond;
    margin-top: 5px;
    font-size: 18px;

}
.FGAfunSoratRightButtonPanel input{
    width: 40px;
    height: 25px;
    margin-left: 20px;
    margin-top: 5px;

}

.contFlex {
    display: flex;
    flex-direction: column;
    height: 82vh;
}

.contFlex_95vh {
    display: flex;
    flex-direction: column;
    height: 95vh;
}
.contFlex_93vh {
    display: flex;
    flex-direction: column;
    height: 93vh;
}