
button.windoClose {
    border: none;
    background: transparent;
    position: fixed;
    right: 15px;
    top: 15px;
  }
  button.windoClose img {
    width: 40px;
  }
  .btn_1.btn_logout {
    background: linear-gradient(0deg, #e70415, #ff6c62);
    font-size: 18px;
    border: solid 1px #ff6969;
  }
  .btn_1.btn_myAcount {
    background: linear-gradient(#996828, #fbea9a, #996828);
    font-size: 18px;
    border: solid 1px #653b0a;
  }
  .timerDis {
    color: #fff;
    display: flex;
    justify-content: center;
    /* padding-top: 10px; */
    width: 70px;
    height: 70px;
    border: solid 2px;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    background: #ddd0;
  }
  
  .btnsRoute a:hover {
    background: yellow;
  }
  .btnsRoute a {
    all: unset;
    background: #ddd;
    margin-right: 15px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.5s;
  }
  
  .gameOne {
    background: #ddd;
  }
  
  @keyframes iconAnim {
    0%{transform: rotate(0deg)}
    100%{transform: rotate(360deg)}
  }
  .bg_gray {
    background: #1e2c36;
  }
  .tpLogoMenu {
      box-shadow: 0px 0px 15px -2px rgb(0 0 0 / 50%);
      z-index: 1;
  }
  .btnsLgRg {
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .play_cover {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 600px; 
    background: rgba(0,0,0,0.6);
    transition: all 0.5s;
    z-index: 80;
  }
  
  .play_bt1 {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(45deg, #f7c749, #996402, #f7c749);
    color: var(--clr_ff); 
    border: none;
    width: 104px;
    margin: auto;
    right: 0;
    left: -800px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 38px;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 99;
  }
  .indie_game { 
    float: left;
    transition: all 0.5s;
    position: relative; 
    overflow: hidden;
    width: 100%;
    float: left;
  }
  .indie_game:hover .play_cover {
    left: 0px;
  }
  .indie_game:hover .play_bt1 {
    left: 0px;
  }
  .imgTitle {
    font-weight: bold;
    color: #cdd6d9;
    text-align: center;
    padding: 5px;
    width: 100%;
    float: left;
  }
  .col_custm_5 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  
  
  .coverLog.hideCover {
    opacity: 0;
    visibility: hidden;
  }
  .coverLog.showCover {
    opacity: 1;
    visibility: visible;
    }
    .coverLog {
    position: fixed;
    visibility: hidden;
    background: #000000c9;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 999;
    transition: all 0.5s;
    /* display: none; */
  }
  .userBlnc {
    display: flex;
    float: right;
  }
  .userBlnc .user {
    width: 36px;
    float: left;
    border-radius: 5px;
    margin-left: 5px;
  }
  .userBlnc .ub span:nth-child(1) {
    color: #feca43;
    font-weight: bold;
  }
  .userBlnc .ub span:nth-child(2) {
    color: #ddd;
  }
  .userBlnc .ub {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .j_end {justify-content: end;}
  .al_cntr {align-items: center;}
  .userBlnc .down img {width: 15px;}
  .down:hover .dropDn {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
  .userBlnc .down {
    margin-left: 15px;
    display: flex;
    cursor: pointer;
  }
  .userBlnc .dropDn {
    position: absolute;
    top: 68px;
    background: #f1f1f1;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(100px);
    right: 10px;
  }
  .z_5 {z-index: 5;}
  .z_10 {z-index: 10;}
  .userBlnc .dropDn ul li:hover {
    background: #ddd;
  }
  .userBlnc .dropDn ul li {
    list-style: none;
    width: 100px;
    padding: 8px;
    cursor: pointer;
    border-bottom: solid 1px #ddd;
    transition: all 0.5s;
  }
  .userBlnc .dropDn ul {
    all: unset;
  }
  
  .ind_loader {
    width: 100%;
    float: left;
  }
  .ind_loader .box_1Logo {
    width: 50px;
    position: relative;
    margin: auto;
    left: 0;
    right: 0;
  }
  .ind_loader .box_1Logo img:nth-child(2) {width: 100%;
  z-index: 2;position: relative;}
  .ind_loader .box_1Logo img:nth-child(1) {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 0; 
    animation: indAnim 2s infinite;
  }
  .ind_loader .box_2Logo {width: 65%;float: left;position: relative;}
  .ind_loader .box_2Logo img {width: 100%;float: left;}
  
  @keyframes indAnim {
    0%{transform: rotate(0deg)}
    100%{transform: rotate(360deg)}
  }
   
  
  .socketLoader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #1e2c36;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }