.col_33 {width: 33.333%;float: left;}
.col_left {width: 33.333%;}
.col_left {width: 33.333%;}
.topBox {display: flex; width: 100%;float: left;}
.topBox div { flex: 1; }

.in_1 {
    background: linear-gradient(#bb6800, #c8cc00, #bb6800);
    padding: 11px;
    border-radius: 9px;
    border: solid 1px #3e0202;
    text-align: center;
}
.matkaTmr .in_2 {
  padding: 1.6px 5px;
  border-radius: 6px; 
  text-align: left;
  float: left;
  width: 19%;
  color: #ffd400;
  margin-left: 5.5px;
  cursor: pointer;
  font-size: 25px;
  font-weight: 200;
} 

.matkaTmr .in_2:nth-child(odd) { background: #000000; }
.matkaTmr .in_2:nth-child(even) { background: #004e00; }
.topBox div:nth-child(odd) { background: #000000; }
.topBox div:nth-child(even) { background: #500000; }
.m_name {
    text-align: center;
    font-size: 45px;
    font-family: initial;
    color: #fbff00;
    font-weight: bold;
}
.Round_1 {
    width: 350px;
    height: 350px;
    background: #f35500;
    border-radius: 50%;
    border: solid 2px #ff0;
    margin: auto;
    margin-top: 50px;
}
.bdr_mtk {border: solid 1px #9e8200;}
 /* .chipBox {
   background: #340e00;
   width: 100%;
   float: left;
   border-radius: 3vh;
 } */
.bdr_matka {
  background: linear-gradient(45deg, #ed9a00, #d3c84f, #ed9a00);
  border-radius: 3vh;
  padding: 0.5vh;
}
.m_bg_1 {background: #340e00;}
.clr_ff {color: #fff;}
ul.chips_matka_timer li {
  width: 20%;
  float: left;
  list-style: none;
  text-align: center;
  /* display: flex; */
  height: 46% !important;
}
ul.chips_matka_timer {
  all: unset;
}

.secList_left {width:100%;float: left; }

.d-flex {display: flex;}
.bg_00 {background: #000;}
.text_left {text-align: left;}

 

.FGAfunMatkaClose{
  position:absolute;
  top:0.5vh;
  right:10px;
 z-index: 360;
}
 
 
.matkaBal div:nth-child(1) {
  background: linear-gradient(#830000, #580000);
  padding: 2px;
}
.matkaBal div:nth-child(2) {
  background: #210000;
}
.f_matka_btns button.mtkBt_1 { 
  background: linear-gradient(#4a0000, #b74949, #4a0000);
    border: none;
    border-radius: 5px;
    padding: 2px;
    width: 100%;
    float: left;
}
 
.wheel_HW {
  width : 100%;
  height : 100%;
  position : absolute;
  /* left : 50%; */
  /* top : 0vh; */
  /* transform : translateX(-50%);  */
}
.matkaScale {
  transform: scale(1.03);
    transform-origin: left;
    z-index: 99;
} 

.numberSet {
  width: 100%;
  float: left;
  border: solid 2px #00bb00;
  padding: 3px 5px 3px 0px;
  border-radius: 10px;
  background: #00000080;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.matka_m_t_vh {margin-top: 0.5vh;} 
.chips_matka_timer li:nth-child(6) {margin-left: 10%;}
.timer_Sec.matka_timerSec { 
      font-size: 32px;
      right: 0;
      top: 0;
      /* z-index: 99; */
      margin: auto;
      left: 0;
      right: 0;
      position: relative;
      float: none;
      align-items: center;
      margin-top: 0px;
      background: radial-gradient(#fd6984, #ff002e);
}
.akda { 
  height: 50px;
  justify-content: center;
  align-items: center;
}
.akda img {
  width: 80%;
}
.winImg {
  position: absolute;
  width: 23%; 
  text-align: center;
  right: 28px;
  top: 0;  
}
.winImg img {
  width: 100%;
}

div#winImg span {
  position: absolute;
  color: #f9d100;
  left: 0;
  right: 0;
  margin-top: 55px;
  font-size: 35px;
}
 
.akda_tm img { width: 80%;}
.akda_tm {
  cursor: pointer;
  width: 80px;
  float: left;
  background: #0000008c;
  color: #ff0;
  border: solid 1px #00bb00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indiMatkaTimer .matkaUserLbl {
  background: #cd0b2e;
  border-radius: 8px;
  padding: 5px;
  margin-top: 15px;
  color: #fff;
  border: solid 1px #ffffff36;
  width: 280px !important;
  font-size: 28px;
  display: flex;
  height: 62px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
}

.indiMatkaTimer .matkaRes {
  /* padding: 10px;
  color: #fff;
  border-radius: 20px;
  margin-top: 6px;
  background: #004e00;
  border: solid 3px #00bb00;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px; */

  padding: 10px;
  color: #fff;
  border-radius: 33px;
  margin-top: 6px;
  background: #003400;
  border: solid 3px #009100;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.indiMatkaTimer {
  background: #002600;
}

.matkaTimerWinPop {
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 7px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: matka_TimerResult 3s 1;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
@keyframes matka_TimerResult{
  0% { transform: translate(-500px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px); }
}
.indiMatkaTimer ul.chips_matka_timer li img {width: 90px;}
.indiMatkaTimer ul.chips_matka_timer li img.active {
  border: solid 7px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 0px 5px rgb(0 0 0 / 50%);
  border-radius: 50%;
}
.indiMatkaTimer .matkaBal {
  background: #cd0b2e;
}

.indiMatkaTimer .matkaBal div:nth-child(1) {
  background: linear-gradient(#cd0b2e, #740015);
  padding: 0.2vh;
}

.indiMatkaTimer .matkaBal div:nth-child(2) {
  background: #44000c;
}

.indiMatkaTimer button.fd.ar_bt_1:disabled {
  /* background: linear-gradient(#301b1b, #484848, #301b1b); */
  background: linear-gradient(#323232, #6c6c6c, #323232);
  cursor: no-drop;
  border: solid 3px #8b8b8b;
}

.indiMatkaTimer .f_matka_btns button.mtkBt_2 {
  width: 100%;
  float: left;
  background: linear-gradient(#680013, #cd0b2e, #680013);
  border: none;
  color: #fff;
  padding: 21px 5px;
  border-radius: 9px;
  text-transform: uppercase;
  font-size: 28px;
  transition: all 0.5s;
  border: solid 3px #ffffff38;
  /* ................. */
  font-family: 'Poppins';
  /* ................ */

}

.MatkaTimerClose img{
  width: 100%;
}
.MatkaTimerClose {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 360;
}

.MatkaTimerClose button {
  width: 58px;
  background: #cd0b2e;
  border: none;
  border-radius: 5px;
}
.titleImg.matkaTitle {height: 80px;}

/****************** NEW WHEEL START ********************************/

* {box-sizing: border-box;} 

.mtt_wheelNum1{
  position: absolute;
  width: 100%;
}

.mtt_wheelBg {
  position: relative;
  margin: auto;
  width: 745px;
  height: 755px;
  margin-top: -70px;
  /* transform: scale(1.1, 0.9); */
  left: 8px;
}
.mtt_wheelBorder_1 { width: 100%; float: left; }
.mtt_wheel_nos_1 {
  position: absolute;
  left: 47px;
  width: 641px;
  top: 88px;
}
.mtt_wheel_nos_2 {
  position: absolute;
  left: 122px;
  width:490px;
  top: 163px;
}
.mtt_wheel_nos_3 {
  position: absolute;
  left: 200px;
  width: 340px;
  top: 233px;
}
.mtt_matkaResPos {
  position: absolute;
  left: 267px;
  width: 200px;
  top: 70px;
  height: 250px;
}

.mtt_pivotNum {
  position: absolute;
  font-size: 52px;
  left: 325px;
  top: 370px;
  text-align: center;
  width: 100px;
  font-family: 'arial';
  color: #000;
}


/****************** NEW WHEEL END ********************************/


.matkaRes2 {
  padding: 8px;
  color: #fff;
  border-radius: 33px;
  margin-top: 5px;
  background: #003400;
  border: solid 3px #009100;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
}

.chipImg{
  cursor: pointer;
}


.matkaTimerRes2 {
  padding: 8px;
  color: #fff;
  border-radius: 33px;
  /* margin-top: 5px; */
  background: #003400;
  border: solid 3px #009100;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}

.m_t-70{
  margin-top: -70px;
}

.matkaTimerChips {
  padding: 10px;
  color: #fff;
  border-radius: 33px;
  /* margin-top: 6px; */
  background: #003400;
  border: solid 3px #009100;
  /* font-size: 28px; */
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  min-height: -14px;
  /* padding-bottom: 122px; */
}

.fmt_chipBox {
  /* background: #340e00; */
  width: 100%;
  float: left;
  border-radius: 3vh;
  height: 208px !important;
}


.chips_matka_timer{
  all: unset;
  /* min-height: 109px !important; */
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-top: 10px;
  /* padding-bottom: 10px; */
}
.mtt_overlay_text {
  text-align: center;
  position: relative;
  /* top: 20%; */
  /* right: 0%; */
  transform: translate(0%, -187%);
  font-size: 25px;
  /* bottom: -24%; */
  color: black;
  /* display: flex; */
  /* padding: unset; */
}

.chipCls{
  cursor: pointer;
}