.bg_moneywheel{
  background-image: url(../../../../../../assets/games/money_Wheel/moneyWheel_Bg.png);
  background-size: cover;
  /* padding: 3% 6% 4% 6%;  */
  display: flex;
  width: 100%;
  height: 1250px; 
}
.mw_Cover.block {display: block;}
.mw_Cover.none {display: none;}
.mw_Cover {
  display: block;
  position: fixed;
  top: 132px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: transparent;
  /* background: #ea0a0acf; */
  z-index: 300;
}

.emty_chip_container {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
.emty_chip_container2{
  position: fixed;
   bottom: 0;
   margin: auto;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100px;
   height: 100px;
   opacity: 0;
}

#emty_chip{
 
    width: 80px;
    pointer-events: none;
   
}


.emty_chip_0 {
  left: 4px;
  top: -144px;
}


.emty_chip_1 {
  right: 1px;
  top: -143px;
}

.emty_chip_2 {
  top: -144px;
  left: 0px;
  /* transform: rotate(8deg); */
}


.emty_chip_3 {
  top: -140px;
  left: 2px;
  position: relative;
  /* -webkit-transform: rotate(-23deg); */
  /* transform: rotate(-23deg); */

}


.emty_chip_4 {
  /* position: relative; */
  top: -142px;
  left: 1px;
  /* transform: rotate(-3deg); */
}



.emty_chip_5 {
  /* position: relative; */
  top: -137px;
  left: 0px;
  /* transform: rotate(4deg); */
}


.betCoverMWL {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.betCoverMWLJ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  /* width: 200px; */
}

.betCoverMWLJ_2 {
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 40;
  /* width: 200px; */
}


.bet_amount_label_0{
  color:black;
  font-size: 22px;
  font-family: 'verdana';
  position: relative;
  font-weight: bold;
}

.bet_amount_label_1 {
  position: relative;
  pointer-events: none;
  color: black;
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;
}

.bet_amount_label_2 {
  position: relative;
  /* top: 14px; */
  color:black;
  /* rotate: 37deg; */
  text-align: center;
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;
}

.bet_amount_label_3 {
  color:black;
  position: relative;
  /* top: 13px; */
  /* rotate: 40deg; */
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;
}

.bet_amount_label_4 {
  color:black;
  position: absolute;
  /* rotate: 264deg; */
  /* top: 14px; */
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;
}
.bet_amount_label_5{
  color:black;
  position: absolute;  
  /* rotate: 283deg; */
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;

}
.bet_amount_label_6 {
  pointer-events: none;
  color: black;
  position: absolute;
  /* top: 13px; */
  /* rotate: 291deg; */
  font-size: 22px;
  font-family: 'verdana';
  font-weight: bold;
}
.coinmw{
  position: absolute;
  top: 5%;
  left: 5%;
}

.mw_left_container{
  display: flex;
  flex-direction: column;
  width: 30%;
  position: relative;
  align-items: center;
}
/* .title_image {
  width: 755px;
  height: 140px;
} */

.fun_id {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  position: relative;
  width: 100%;
  top: -20px;
}

.fun_id_2 {
  /* position: absolute; */
  display: flex;
  align-items: center;
  top: 120px;
  left: 50px;
  width: 100%;
  margin-top: -85px;
}

.fun_id_tag {
  position: absolute;
  left: 36px;
  top: 40px;
  width: 30px;
  z-index: 3;
  display: flex;
}

.fun_ring{
  width: 130px;
  /* position: relative;
  z-index: 1;
  top: -26px; */
}
.fun_id_bg_label {
  position: relative;
  left: 100px;
  width: 480px;
  height: 150px;
  top: 0px;
}

.fun_balance_bg_label {
  position: relative;
  width: 480px;
  height: 150px;
  left: 90px;
  top: 17px;
  z-index: 1;
}
.fun_balanc_ring{
  width: 130px;
}

.fun_balance_tag {
  position: relative;
  top: -30px;
  width: 65px;
  z-index: 3;
  left: -92px;
}
  

.mw_winner {
  position: absolute;
  color: #ffffff;
  z-index: 5;
  font-size: 40px;
  /* top: -84px;
  left: 125px; */
  font-weight: bold;
  text-align: center;
  width: 320px;
}
.mw_user {
  position: absolute;
  color: #000000;
  z-index: 5;
  font-size: 30px;
  top: 50px;
  left: 65px;
  font-weight: bold;
  text-align: center;
  width: 240px;
  font-family: 'Century_Gothic_B';
}

.mw_close {
  position: absolute;
  top: 20px;
  right: 25px;
  border: none;
  width: 100px;
}
.mw_close button {
  width: 110px;
  height: 95px;
  border: transparent;
  border-radius: 8px;
  background-color: #ff000000;
}

.balance {
  position: relative;
  color: #ffffff;
  z-index: 5;
  font-size: 40px;
  top: -85px;
  left: 218px;
  font-weight: bold;
  text-align: center;
  width: 320px;
}
.history_container {
  position: relative;
  top: 140px;
  width: 560px;
  height: 215px;
  left: 0px;
  /* background-image: url(../../../../../../assets/games/money_Wheel/mw_history_bg.png); */
  background-image: url(../../../../../../assets/games/money_Wheel/mw_history_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.history_image {
  position: relative;
  display: flex;
  width: 400px;
}

.history_heading {
  color: #fbee06;
  position: relative;
  z-index: 1;
  left: 145px;
  top: -339px;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
}

.ul_container {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  width: 417px;
  position: relative;
  left: 66px;
  font-family: 'Candara';
  top: 80px;
  /* background: red; */
}
.ul_container_li {
  color: #000;
  margin: 5px;
  font-size: 40px;
  background-color: #ffca4b;
  width: 62px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  margin-left: 15px;
  padding-top: 0px;
  font-family: 'Century_Gothic_B';
}
.hisTxt{
  position: relative;
  top: 5px;
}
.bet_button_container {
  /* display: flex; */
  align-items: center;
  position: relative;
  top: 20px;
  left: 0px;
  justify-content: center;
  display: flex;
  width: 100%;
}

.bet_button_bg{ 
  width: 180px;
}

.bet_button {
  margin-right: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: center;
}
.double_button {
  margin-right: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: center;
}
.bet_button:disabled{
  cursor: no-drop;
}

.bet_button_heading {
  color: #ffffff;
  font-size: 21px;
  position: relative;
  top: -105px;
  /* left: 55px; */
  font-weight: bold;
  /* font-family: "Roboto"; */
}
.bet_amount_bg {
  position: absolute;
  right: -58px;
  top: 0;
  width: 50%;
}

.moneywheelbuttonglow{ 
  /* padding: auto; */
  border-radius: 100%;
  animation-name: moneywheelbutton; 
  animation-duration: 1s; 
  animation-iteration-count: infinite; 
}

.goldCoinmoneywheel_er img{ width: 100% ;}

.goldCoinmoneywheel_er {
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes moneywheelbutton {
  0% { box-shadow: 0 0 9px 12px rgb(255 238 0)  } 
  49% { box-shadow: 0 0 12px 12px rgb(255 238 0) }
  50% { box-shadow: 0 0 0px; }
  100% { box-shadow: 0 0 0px; }
}
.bet_amount_heading {
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  top: -93px;
  left: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: 'Century_Gothic_B';
}
.bet_amount_heading span{
  margin-left: 10px;
}

.wheel_number_container {
  width: 778px;
  text-align: center;
  position: relative;
  /* top: 10px; */
  transform: scale(1.1,1);
  top: 60px;
  left: 10px;
}


.wheel_outer_frame_container {
  position: absolute;
  top: -8px;
  width: 780px;
  left: 8px;
}

/* .wheel_outer_frame_container {
  position: absolute;
  top: 62px;
  width: 1035px;
  left: -9px;
} */
.wheelVal {
  position: absolute;
  font-size: 80px;
  font-family: 'Century_Gothic_B';
  /* margin-top: -79%; */
  left: 50px;
  color: white;
  z-index: 100;
  width: 195px;
  top: 98px;
}
.start_point_container {
  position: absolute;
  color: white;
  /* font-size: 80px; */
  z-index: 1;
  top: 420px;
  /* left: 245px; */
  width: 300px;
  left: 0;
  /* top: 0px; */
  right: 0;
  margin: auto;
  /* bottom: -98px; */
}
.start_point_image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.wheel_outer_frame {
  position: relative;
  /* top: -43px; */
  /* left: 15px; */
  width: 100%;
}

/* .ccc{
  animation: art_spin 25s linear infinite;
}
.art_spin{
  rotate: 360deg;
} */

.wheel_inner_frame_container {
  position: relative;
  width: 717px;
  top: 250px;
  left: 31px;
}

.wheel_inner_frame{
  /* width: 630px; */
  width: 639px;
   /* position: relative; 
     top: 20px;  */
    /* left: 76px; */
    /* width: 70%; */
    /* z-index: 0; */
    /* width: 593px; */

}

.bet_ratio_container {
  position: absolute;
  /* top: 200px; */
  left: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  bottom: 30px;
}

/* .bet_ratio_container {
  position: relative;
  top: -115px;
  left: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
} */


/* .winpopinmoneywheel{

  display: none;
  font-family: "Leelawadee UI";
  position: fixed;
  right: 0;
  width: 800px;
  left: -5px;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px;
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: dupPoupupMw 1s 1;
  transform: translate(1700px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);

} */


@keyframes dupPoupupMw {
  0% {
    transform: translate(-1500px, 0px);
  }

  20% {
    transform: translate(0px, 0px);
  }

  80% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(1700px, 0px);
  }
}



.goldCoin_mw {
  position: relative;
  width: 75px;
  height: 75px;  
  background-color: #FBEC0F;
  border-right: solid 2px #FBEC0F;
  border-bottom: solid 2px #FBEC0F;  
  /* left: 0px !important; */
  z-index: 75;
  /* right: 0;
  bottom: 0;
  top: 0; */
  margin: auto;
  color: #000;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#f59407,#f7f812, #f7f812, #f59407);
  border-radius: 50%;
}
  
  
.span.mw_Count {
  width: 90px;
  font-weight: bold !important;
  text-align: center;
  height: 90px;
  font-size: 22px;
  color: #000;
}
.moneywheelidcontainer {
  color: #ffffff;
  top: 5px;
  font-size: 18px;
  width: 100%;
  left: 100px;
  position: relative;
}
.sudharshan_Chakruimg{
  pointer-events: none;
  position: relative;
  left: 53px;
  top: -153px;
}
.dragon_Img {
  pointer-events: none;
  position: relative;
  left: 60px;
  top: -158px;
}

.jackpat_Bg{
  width: 200px;
}

.jack_pat_one_1_45 {
  color: #ffffff;
  position: absolute;
  width: 108px;
  height: 37px;
  top: 189px;
  left: 187px;
  font-size: 25px;
  font-style: italic;
  font-weight: bolder;
  background-color: #f1f1e930;
  padding-left: 28px;
  padding-top: 4px;
  padding-right: 25px;
  border-radius: 40%;
  margin: -4%;
}

.bet_ratios{
  color: #ffffff;
  position: relative;
  text-align: center;
  width: 70px;
  font-size: 20px;
  font-style: italic;
  font-weight: bolder;
  background-color: #f1f1e930;
  border-radius: 40%;
   
   
    
}
.one_20{
  top: 262px;
  left: 109px;
  transform: rotate(177deg);
}

.one_10{
  top: 221px;
  left: 70px;
  transform: rotate(187deg);

}

.one_5{
  top: 216px;
  left: 48px;
  transform: rotate( 179deg);
}

.one_3{
  top: 21px;
  left: -186px;
  left: 37px;
  transform: rotate(340deg);
}

.one_1{
  top: 18px;
    left: 68px;
    transform: rotate(355deg);
}

.mwh_jackpat{
    /* cursor: pointer; */
    width: 200px;
    height: 200px;
    margin-top: 10px;
    position: relative;  
}

.mw_jackpat_1{
  /* cursor: pointer; */
  width: 200px;
  height: 200px;
  margin-top: 10px;
  position: absolute;
  left: 249px;
 }

 area{
  cursor: pointer;

 }

 .wheel_container {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 100%; */
  width: 40%;
  /* height: 100%; */
}

.wheel_inner_outer_frame_container {
  /* top: 70px; */
  position: relative;
  width: 100%;
  /* height: 100%; */
}

.numbers_ratio_container {
  display: flex;
  flex-direction: column;
  width: 15%;
  top: 165px;
  position: relative;
  height: 100%;
  left: 20px;
}

.number_Twenty {
  /* cursor: pointer; */
  position: relative;
  top: 187px;
  left: -20px;
  width: 150px;
  height: 150px;
  transform: rotate(-117deg);
  /* rotate: 261deg; */
  /* rotate: -105deg; */
  background: #5f9ea000;
  z-index: 20;
  border-radius: 50%;
}

.number_20 {
  position: relative;
  width: 150px;
  top: -20px;
}

.number_Ten {
  position: relative;
  /* cursor: pointer; */
  left: 25px;
  top: 188px;
  transform: rotate(-93deg);
  height: 150px;
  z-index: 11;
  width: 150px;
  background: #0000ff00;
  border-radius: 50%;
}
.number_10 {
  width: 150px;
  position: relative;
  top: -20px;
}

.number_Five {
  /* cursor: pointer; */
  position: relative;
  top: 200px;
  left: 20px;
  width: 150px;
  height: 150px;
  /* rotate: -78deg; */
  transform: rotate(-78deg);
  z-index: 13;
  background: #7fffd400;
  border-radius: 50%;
}

.number_5 {
  width: 150px;
  position: relative;
  top: -22px;
}

.number_Three {
  /* cursor: pointer; */
  position: relative;
  top: 200px;
  left: -40px;
  width: 150px;
  /* rotate: 294deg; */
  transform: rotate(-56deg);
  /* rotate: -58deg; */
  height: 150px;
  background: #ff000000;
  border-radius: 50%;
}

.number_3 {
  top: -26px;
  position: relative;
  left: 0px;
  width: 150px;
  /* height: 180px; */
}
.number_One {
  position: relative;
  top: 170px;
  left: -149px;
  width: 150px;
  transform: rotate(-40deg);
  height: 150px;
  background: #ff000000;
  border-radius: 50%;
}
/* .number-One img{
  width: 170px;
  rotate: 41deg;
} */

.number_1 {
  top: -27px;
  position: relative;
  left: 0px;
  width: 150px;
}
.chips_clear_container {
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
}
.money_wheel_chips {
  display: flex;
  flex-wrap: wrap;
  width: 460px;
  height: 470px;
  /* background: gray; */
  position: relative;
  align-content: space-evenly;
}

.infotext_container {
    width: 550px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e4acf3d1;
    border-radius: 30px;
    position: relative;
    top: 60px;
    /* margin: 0px; */
    left: 0px;
    right: 0;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.infotext_container h1 {
  font-weight: 500;
  color: #ffffff;
  font-size: 25px;
}

.money_wheel_chipImg {
  transition: all 0.3s;
  height: 120px;
  /* background: rebeccapurple; */
}

.money_wheel_chipImg img {
  width: 120px;
}

.money_wheel_chipImg:hover{
  cursor: pointer;
  transform:scale(1.10)

}
.money_wheel_chipImg.active_chip img{
  transition: all 0.3s;
  border: solid 8px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);

}

/* img.chp_img:hover { cursor: pointer; transform: scale(1) !important; } */

.chip_size {
  width: 130px;
  margin: 20px;
}

img.chip_size:hover{cursor: pointer; transform: scale(1) !important;}

.mny_overlay_text {
  text-align: center;
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(54%, -483%);
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
}


.btn_container {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 145px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}


.win_bg_container {
  position: absolute;
  top: 0;
  left: 0;
  /* margin: 0px; */
  width: 50%;
}

.win_bg_text {
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: -93px;
  left: 30px;
  z-index: 1;
  width: 290px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.win_bg {
  width: 100%;
  position: relative;
  /* left: -170px; */
}

.clear_button_img {
  width: 180px;
  background: none;
}

.clear_button {
  color: #ffffff;
  font-size: 26px;
  position: relative;
  top: -27px;
  z-index: 1;
  font-weight: bold;
  background-color: transparent;
  border: none;
  outline: none;
}

.clear_button:disabled{
  cursor: no-drop;
}
.double_button:disabled{
  cursor: no-drop;
}


.clear_button h1{
  color: #ffffff;
    font-size: 26px;
    position: relative;
    top: 105px;
    font-weight: bold;
}

.winPopMoneyWheel { 
  display: none;
  position: fixed;
  right: 0;
  width: 600px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, hsl(34, 87%, 32%));
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px; 
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: targetWin 3s 1; 
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}

@keyframes targetWin{
  0% { transform: translate(-500px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px);}


  /* 0% { transform: translate(-1000px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px); } */

  /* 0% { transform: translate(0px, 0px); }
  20% { transform: translate(0px, 350px); }
  80% { transform: translate(0px, 350px); }
  100% { transform: translate(0px, 2000px); } */

  
  

}



.funTargetNonTimerCover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    /* background: #00000082; */
    z-index: 300; 
}

.sorat_chips {
  width: 20%;
  float: left;
}
.mw_table {
  width: 600%;
  float: left;
}
.moneywheel_btns {
  width: 20%;
  float: left;
  padding: 10px;
}
.sorat_leftPanl {
  width: 36%;
  display: flex;
  float: left;
}
.sorat_btnPanel {
  width: 36%;
  float: right;
  display: flex;
}
/* .btns-top {
  margin-top: -18vh;
} */
.moneywheel_chips {
  margin-top: 22vh;
}
.moneywheel_chips .col-4 {
  margin-top: 2vh;
}
.fl_1 {
  padding: 0 5px;
  flex: 1;
}
.sorat_leftPanl .funBal div:nth-child(1),
.sorat_leftPanl .funBal div:nth-child(2) {
  padding: 5px;
}

.col-4.Selects img {
  border: solid 3px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}

/* .panelTabs:hover [class*="pan"] {
  color: #f00;
} */
.trgeImg {
  animation: trgtBdr 0.4s infinite;
}
@keyframes trgtBdr {
  0% {
    border: dotted 5px #f00;
  }
  50% {
    border: dotted 5px #ff0;
  }
  100% {
    border: dotted 5px #f00;
  }
}

#FTwheelContainer {
  width: 70vh;
  height: 70vh;
  position: absolute;
  left: 50%;
  top: 10vh;
  transform: translateX(-50%);
  /* border: 1px solid red; */
}
#FTwheelContainerOuter {
  width: 70vh;
  height: 70vh;
  position: absolute;
  left: 50%;
  top: 13vh;
  transform: translateX(-50%);
  /* border: 1px solid red; */
}
.targetLayer {
  border: solid 5px #f00;
}
.borderLight {
  width: 63vh;
  height: 57.8vh;
  position: absolute;
  left: -20px;
  right: 0;
  margin: auto;
  z-index: 9;
  border-radius: 50%;
  top: 14.8vh;
  animation: trgtLight 0.4s infinite;
}

@keyframes trgtLight {
  0% {
    border: dotted 6px #2d0f0f;
  }
  50% {
    border: dotted 6px #ff0;
  }
  100% {
    border: dotted 6px #2d0f0f;
  }
}

.moneywheeelBoard {
  width: 130%;
  float: left;
  border: 1px solid rgb(177 147 0 / 80%);
  border-radius: 5px;
  background-color: rgb(0 0 0 / 43%);
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding: 25px 10px;
  left: -30%;
  position: relative;
}

/* table.HistoryTbl.ftTBg tr td {
  background: linear-gradient(#e4100f, #571417);
} */
/* table.HistoryTbl tr td {
  color: #fff;
  background: linear-gradient(#d12708, #f15e00);
  text-align: center;
  padding: 11px 1px;
  border: solid 1px #fff;
  font-size: 2vh;
}

table.HistoryTbl {
  width: 140%;
  float: left;
  position: relative;
  left: -40%;
  font-size: 16px;
  margin-top: 15px;
} */
/* .jackpotImg img {
  width: 100%;
  padding: 30px;
} */

@media only screen and (max-width: 1600px) { 
}
@media only screen and (max-width: 1200px) {  
  .sorat_leftPanl .funBal div:nth-child(1),
  .sorat_leftPanl .funBal div:nth-child(2) {
    padding: 1px;
    font-size: 14px;
  }
  .moneywheel_chips .chp_img {
    width: 100%;
  }
  /* .pan {
    background-size: 11vh;
  } */
  /* .panelTabs {margin-left: 10px; margin-right: 10px;} */
}
@media only screen and (max-width: 992px) {
  .moneywheel_chips .col-4 {
    margin-top: 2vh;
    padding: 3px;
  }
  /* .btns-top {
    margin-top: -18vh;
  } */
  .sorat_leftPanl .funBal div:nth-child(1),
  .sorat_leftPanl .funBal div:nth-child(2) {
    padding: 1px;
  }
  .moneywheeelBoard {
    padding: 10px 5px;
    font-size: 13px;
  }
  .moneywheel_chips {
    margin-top: 13vh;
  }
  /* table.HistoryTbl {
    width: 150%; 
    left: -50%;
    font-size: 13px; 
  } */
  .moneywheeelBoard {width: 150%;left: -50%;}

  /* table.HistoryTbl tr td {
    padding: 5px 1px;
  } */
  /* .panelTabs {margin-left: 3px; margin-right: 3px;} */
  .borderLight {
    width: 64vh;
    height: 58.8vh;
    left: -14px;
    top: 14.3vh;
  }
}
@media only screen and (max-width: 764px) { 
  .sorat_leftPanl .funBal div:nth-child(1), .sorat_leftPanl .funBal div:nth-child(2) {
    padding: 0px;
    font-size: 11px;
}
.userLbl {margin-top: 0px;}
.moneywheeelBoard {padding: 3px 5px;font-size: 13px;width: 165%;left: -65%;}
/* table.HistoryTbl {
  width: 165%;
  left: -65%;
  font-size: 11px;
} */
.moneywheel_chips {
  margin-top: 0vh;
}
.jackpotImg img {
  width: 100%;
  padding: 20px;
}
}
.userFtarget {margin-right: 40px;}
@media only screen and (max-width: 600px) {
  /* .pan {padding: 10px 2px;} */
  /* .pan1 {font-size: 15px;} */
  /* .panelTabs {margin-left: 1px; margin-right: 1px;padding: 0px;} */

  /* @keyframes trgtLight {
    0% {
      border: dotted 5px #2d0f0f;
    }
    50% {
      border: dotted 5px #ff0;
    }
    100% {
      border: dotted 5px #2d0f0f;
    }
  }

  @keyframes trgtBdr {
    0% {
      border: dotted 3px #f00;
    }
    50% {
      border: dotted 3px #ff0;
    }
    100% {
      border: dotted 3px #f00;
    }
  } */
  
}



  .act.m_l_0 {margin-left: 0px !important;}

  div#jackpotImg {
    position: absolute;
    background: radial-gradient(farthest-side at 20% 15%,#7d7d7d, black, black);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 8vh;
  }

#FTwheelContainerOuter, #FTwheelContainer {margin: auto;}
.m_t_10vh {
  margin-top: 10vh;
}
.moneyWheelPanel table tr td img {
  width: 50px;
}
.moneyWheelPanel {
  position: absolute;
  bottom: 0px;
}
.pointerTop { 
    position: relative;
    width: 5px;
    height: 40px;
    background: #f00;
    left: 0;
    right: 0;
    margin: auto;
    top: 39px;
    z-index: 99; 
}

.betBtnMW{
  display: block;
  position: absolute;
  cursor: pointer;
  transform: rotate(41deg);
}


.myDiv{
  position: relative;
  background: #2d0f0f;
}

.mw_title {
  margin-top: -60px;
  margin-left: 60px;
}

.empty_chip_6 {
  top: -141px;
  left: 0px;
  right: 10px;
  /* transform: rotate(18deg); */
}

.betOuter {
  position: absolute;
  z-index: 100000000;
  /* background: red; */
  width: 225%;
  left: -135px;
  top: 135px;
}

span.timerTxt {
  position: absolute;
  font-size: 35px;
  font-family: 'Century_Gothic';
  color: white;
  top: 43px;
  left: 338px;
  width: 100px;
  background: #0000ff00;
  height: 50px;
  margin: auto;
}

.mw_timer_Sec {
  top: 22px;
  left: 327px;
  /* background: yellow; */
  width: 150px !important;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  font-family: Century_Gothic_B;
  margin-right: 50px;
  border: solid 14px transparent;
  border-radius: 50%;
  /* background: #ffda2f00; */
  position: absolute;
  z-index: 50;
  color: white;
  font-size: 35px;
}
.closeBnt{
  width: 100px;
}

.mw_close {
  position: absolute;
  top: 20px;
  right: 25px;
  border: none;
  width: 100px;
}

.closeText {
  width: 100px;
  position: absolute;
  font-size: 25px;
  font-family: 'Century_Gothic_B';
  left: 8px;
  top: 33px;
  color: white;
}

@keyframes borderAnim_mw {
  from {border: solid 15px #fffe00dd; }
  to {border: solid 15px transparent; } 
}

.mw_timer_glow {
  position: absolute;
  width: 150px;
  height: 140px;
  border: 15px solid;
  border-color: yellow;
  border-radius: 50%;
  /* left: 35px; */
  /* top: 23px; */
  animation: borderAnim_mw 1.2s infinite;
}


.emptyPointer{
  cursor: none !important;
}

.z_450{
  z-index: 450;
}

.mw_disBtn {
  opacity: 0.7;
  cursor: no-drop;
  pointer-events: none;
}

.fun_balanc_icon {
  position: relative;
  left: 80px;
  z-index: 2;
}
.fun_winner_icon {
  position: relative;
  left: 40px;
  z-index: 2;
}

.mw_winpop {
  /* display: block; */
  /* max-width: 500px; */
  margin: auto;
  position: relative;
  top: 18%;
  left: -3%;
  right: 25%;
  background-image: url(../../../../../../assets/games/win_popup/win_popup.png);
  /* background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b); */
  padding: 0px 25px 25px 25px;
  /* box-shadow: 0 8px 17px 0 rgba(34, 33, 33, 0.2),0 6px 20px 0 rgba(0,0,0,.19)!important; */
  border-radius: 6px;
  animation: mw_popup_anim 3s 1;
  z-index: 1000;
  height: 700px;
  background-repeat: no-repeat;
  width: 1000px;
}


@keyframes mw_popup_anim {
  0% {
    transform: scale(0.01) ;
  }
  20% {
    transform: scale(1) ;
  }
 
  80% {
      transform: scale(1) ;
  }
  100% {
      transform: scale(0.01) ;
    }
}

.mw_win_txt {
  width: 174px;
  position: absolute;
  font-size: 43px;
  font-family: 'Century_Gothic';
  font-weight: bold;
  color: #000000;
  text-align: center;
  top: 64.5%;
  left: 46%;
  transform: translate(-50px, -50px);
}
.popup_back {
  display: none;
  position: fixed;
  background: #2b2a2a52;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0px;
  z-index: 999;
}

.centered {
  position: absolute;
  top: 47%;
  left: 41%;
  transform: translate(-50%, -50%);
}

.mwWinImg {
  position: absolute;
  right: 658px;
  top: 65px;
}
.mwWinImg img {
  float: left;
  transform: scale(2, 1.5);
}
.mw_sparkAnim {
  width: 100%;
  position: absolute;
  top: 1%;
  left: -5%;
  height: 100%;
  transform: scale(2, 2);
  z-index: 990;
  /* background: red; */
}

.mwt_chipImg{
  cursor: pointer;
}

.mwt_chipImg img {
  width: 70%;
  box-shadow: 1px 1px 13px 6px rgb(0 0 0/30%);
  border-radius: 50%;
}

.mwt_chip_text {
  text-align: center;
  transform: translate(-7%, -225%);
  font-size: 25px;
  color: black !important;
}

.activeChip {
  border: 7px solid #ffba00;
  box-shadow: 1px 6px 0 5px rgb(0 0 0/50%);
  border-radius: 50%;
}