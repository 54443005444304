div#single_Ch, div#triple_Label, div#triple_Ch, div#double_Ch {
    display: flex;
    float: left;
    flex-wrap: wrap;
}

div#single_Ch div.boxCh, div#triple_Label div.boxCh, div#triple_Ch div.boxCh, div#double_Ch div.boxCh {
    padding: 5px;
    width: 10%;
    border: solid 2px #e09500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
}
div.boxCh:nth-child(odd){
    background: #25ef78;
}
div.boxCh:nth-child(even){
    background: #f690ff;
}

.tripleChips .amChipCard img { width: 100%; float: left; }
.triple {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: #911915;
    z-index: 999;
    /*display: none;*/
  }

.trplChipCard img.active {
    border: solid 3px #ffba00;
    border-radius: 50%;
    box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}
.trplChipCard img{
    cursor: pointer;
    transition: all 0.5s;
    width: 88%;
    float: left;
}
.trplChips {
    display: flex;
}