.FGAfunTargetRightButtonPanel{
    width: 30vw;
    height: 40px;
    position: absolute;
    right: 20px;
    top: calc(80vh - 50px);
    display: flex;
}


.FGAfunTargetRightButtonPanel .buttonOne:hover{
    background-color: #b52f3f;
}
.FGAfunTargetRightButtonPanel .buttonOne{
    width: calc(33% - 10px);
    min-width: 65px;
    height: 100%;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;

}

.FGAfunTargetRightButtonPanel .buttonTwo:hover{
    background-color: #b52f3f;
}
.FGAfunTargetRightButtonPanel .buttonTwo{
    width: calc(33% - 10px);
    min-width: 65px;
    height: 100%;
    border: 1px solid black;
    background-color: #f40037;
    border-radius: 2px;
    margin-left: 5px;
    color: #ffffff;
    font-size: 15px;

}

.FGAfunTargetRightButtonPanel .buttonThree:hover{
    background-color: #b52f3f;
}
.FGAfunTargetRightButtonPanel .buttonThree{
    width: calc(33% - 10px);
    min-width: 70px;
    height: 100%;
    border: 1px solid black;
    background-color: #f40037;
    border-radius: 2px;
    margin-left: 5px;
    color: #ffffff;
    font-size: 15px;

}