#FTTwheelContainer{
    width: 70vh;
    height: 70vh;
    position: absolute;
    left: 50%;
    top: 7vh;
    transform: translateX(-50%);
    /* border: 1px solid red; */
}
#FTTwheelContainerOuter{
    width: 70vh;
    height: 70vh;
    position: absolute;
    left: 50%;
    top: 10vh;
    transform: translateX(-50%);
    /* border: 1px solid red; */

}
