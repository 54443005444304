.FGAartRightButtonPanel{
    width: 180px;
    height: 240px;
    position: absolute;
    right: 20px;
    top: 40vh;
}

.americanRouletteCover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    /* background: #00000082; */
    z-index: 999;
}

.FGAartRightButtonPanel .buttonZoom:hover{
    background-color: #b52f3f;
}
.FGAartRightButtonPanel .buttonZoom{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;

}
.FGAartRightButtonPanel .buttonOne:hover{
    background-color: #b52f3f;
}
.FGAartRightButtonPanel .buttonOne{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;
}

.FGAartRightButtonPanel .buttonTwo{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    background-color: #f40037;
    border-radius: 2px;
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;

}

.FGAartRightButtonPanel label{
    width: 100px;
    height: 40px;
    color: #000000;
    background-color: blanchedalmond;
    margin-top: 5px;

}
.FGAartRightButtonPanel input{
    width: 40px;
    height: 25px;
    margin-left: 20px;
    margin-top: 5px;

}


table.HistoryTbl {
  width: 130%;
  margin-left: -33%;
  table-layout: fixed;
  margin-top: 0%;
  font-size: 16px;
}