.funTargetTitle{
    width:35vw;
    height: 9vh;
    background-image: url("../../../../assets/games/funTarget/funTargetTitle.gif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    left: 30vw;
    /* border: 1px solid orangered; */
}