.FGAfunTargetTimerBetAmount{
    width:calc(50vw - 60vh);
    height: calc(50vw - 60vh);
    position: absolute;
    left:5px;
    top:20vh;
}
.FGAfunTargetTimerBetAmount .rowOne{
    width: 100%;
    height: 33%;
    display: flex;
}
.FGAfunTargetTimerBetAmount .rowOne .betChipOne:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowOne .betChipOne{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}
.FGAfunTargetTimerBetAmount .rowOne .betChipTwo:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowOne .betChipTwo{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}
.FGAfunTargetTimerBetAmount .rowOne .betChipThree:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowOne .betChipThree{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_10.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}





.FGAfunTargetTimerBetAmount .rowTwo{
    width: 100%;
    height: 33%;
    display: flex;
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipOne:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipOne{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_50.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipTwo:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipTwo{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_100.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipThree:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowTwo .betChipThree{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_500.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}


.FGAfunTargetTimerBetAmount .rowThree{
    width: 100%;
    height: 33%;
    display: flex;
}
.FGAfunTargetTimerBetAmount .rowThree .betChipOne:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowThree .betChipOne{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_1k.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;

}
.FGAfunTargetTimerBetAmount .rowThree .betChipTwo:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowThree .betChipTwo{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_5k.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}
.FGAfunTargetTimerBetAmount .rowThree .betChipThree:hover{
    transform: scale(0.9);
}
.FGAfunTargetTimerBetAmount .rowThree .betChipThree{
    width: calc(33% - 10px);
    height: calc(100% - 10px);
    background-image: url("../../../../assets/games/funTarget/chips/chip_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 5px;
}


.FGAfunTargetTimerBetAmount .UnSelected{}
.FGAfunTargetTimerBetAmount .selected{
    border: 1px solid red;
}