* {
    box-sizing: border-box;
  }
  
  body.moving {
    cursor: move;
    margin: 0;
    padding: 0;
  }
  
  .roulette {
    display: block;
    width: 80%; 
    z-index: 999;
    margin: 0px; 
    left: 0;
    position: relative;
    right: 0;
    margin: auto; 
    margin-top: 20px;
    padding-top:10px;
}
   
  
  .roulette table, tr {
    border-collapse: collapse;
    /*border-spacing: 0;*/
  }
  
  
  .roulette td {
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #666;
    width: 6%; 
    text-align: center;
    vertical-align: middle;
    /* font-weight: bold; */
    font-size: 32px;
    color: white;
    white-space: nowrap;
  }
  
  /* elements */
  
  .roulette .sector {
    background: #0000007d;
    color: #fff;
  }
  
  .roulette .sector .vt {
    font-size: 15px;
  }
  
  
  .roulette .num span {
    display: block;
    /*transform: rotate(270deg);*/
  }
  
  .roulette .zero {
    width: 70px;
    font-size: 56px;
  }
  
  .roulette .empty {
    border: none;
  }
  
  /* colors */
  
  .roulette .green {
    background: #067a21;
    color: #fff;
    vertical-align: bottom;
  }
 
  .roulette .red {
    background: #f00;
    color: #fff;
  }
  
  .roulette .black {
    background: #000;
    color: #fff;
  }
  
  /* cells hovers */
  
  .roulette .sector.hover, .roulette .num.hover {
    /* font-size: 26px; */
    -moz-box-shadow: inset 0 0 5px #FFF, inset 0 0 5px #FFF;
    -webkit-box-shadow: inset 0 0 5px #FFF, inset 0 0 5px #FFF;
    box-shadow: inset 0 0 5px #FFF, inset 0 0 5px #FFF;
  }
  
  .roulette .sector.hover .vt {
    font-size: 17px;
  }
  
  .roulette .sector.hover {
    font-size: 22px;
    background: #1C9022 !important;
    color: #FBEC0F !important;
  }
  
  .roulette .green.hover {
    font-size: 68px;
    background: #3CC042 !important;
    color: #FBEC0F !important;
  }
  
  .roulette .red.hover {
    background: #C00 !important;
    color: #FBEC0F !important;
  }
  
  .roulette .black.hover {
    background: #000 !important;
    color: #FBEC0F !important;
  }
  
  /* others */
  
  .hidden {
    display: none;
  }
  
  .roulette table tr td {
    position: relative;
    cursor: pointer;
  } 
  .mainVal {
    border-right: solid 2px #FBEC0F;
    border-bottom: solid 2px #FBEC0F;
    bottom: -1px !important;
    right: -1px;
}
  .mainVal, .boxCntr {
    position: absolute;
    /* background: #ddd; */
    background: transparent;
    width: 100%;
    float: left;
    top: 0;
    bottom: 0;
    z-index: 80;
    display: grid;
    align-items: center;
    flex-direction: row;
    text-align: center;
    font-size: 18px;
  }
  
  .btmVal, .boxBtm {
    width: calc(100% - 20px);
    height: 20px;
    /* background: #3c52c0; */
    background: transparent;
    font-size: 0px;
    margin-bottom: 10px;
    position: absolute;
    z-index: 90;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -10px;
  }
  
  .rightSd, .boxRight {
    position: absolute;
    width: 20px;
    background:transparent;
    height: calc(100% - 10px);
    top: 0;
    z-index: 90;
    right: -10px;
  }
  
  .leftVal, .boxLeft {
    width: 20px;
    height: calc(100% - 10px);
    position: absolute;
    /* background: #080; */
    background: transparent;
    font-size: 0px;
    top: 0;
    z-index: 90;
    left: -10px;
  }
  
  .cntrVal, .boxCrnr {
    width: 20px;
    /* background: #f00; */
    background: transparent;
    font-size: 0px;
    position: absolute;
    z-index: 100;
    left: -10px;
    height: 20px;
    bottom: -10px;
  }
  
  .center_content {
    /* display: grid; */
    align-items: center;
    flex-direction: row;
    text-align: center;
  }
  
  img.coin_img {
    width: 15px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }
  
  .mainVal:hover, .boxCntr:hover {
    -webkit-box-shadow: inset 0 0 20px #ffffffa6;
    box-shadow: inset 0 0 20px #ffffffa6;
    color: #FBEC0F;
  }
  
  .shadow .mainVal, .shadow .boxCntr {
    color: #FBEC0F;
  }
  
  .shadow {
    -webkit-box-shadow: inset 0 0 20px #ffffffa6;
    box-shadow: inset 0 0 20px #ffffffa6;
    color: #FBEC0F;
  }
  
 
.betsDiv {
  position: fixed;
  background: #18222e;
  width: 250px;
  padding: 15px;
  margin: 11px;
  bottom: 0;
  line-height: 29px;
  color: #fff;
}

.roulette table {
  height: 425px;
  margin-top: 0;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
.roulette .sector.black_box{
  background-image: url('../../../assets/games/americanRoulette/black_box.svg') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}

.roulette .sector.red_box {
  background-image: url('../../../assets/games/americanRoulette/red_box.svg') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}
.roulette tr td {
  border: solid 3px #ff0;
}
.zeroTbl {
  margin-top: 0px !important;
  height: 100% !important;
}
 
.mainVal.zero_1 {
  width: 100%;
  height: 50%;
  float: left;
  position: relative;
  border-bottom: solid 2px #ff0; 
}
.mainVal.zero_2 {
  width: 100%;
  height: 50%;
  float: left;
  position: relative;
}
.mainVal.zero {
  width: 100%;
  height: 100%;
  float: left;
  font-size: 20px;
  position: relative;
}

/* td.num.green h1:nth-child(1) {
  font-size: 20px;
  position: absolute;
  height: 50%;
  top: 0;
  width: 100%;
  padding-top: 3vh;
} */
td.num.green h1:nth-child(1) {
  font-size: 30px;
  position: absolute;
  height: 50%;
  top: 0;
  width: 100%;
  /* padding-top: 3vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* td.num.green h1:nth-child(2) {
  padding-top: 3vh;
  top: 50%;
  width: 100%;
  font-size: 20px;
  position: absolute;
  height: 50%;
} */
td.num.green h1:nth-child(2) { 
  top: 50%;
  width: 100%;
  font-size: 30px;
  position: absolute;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.er_0 {
  width: 100%;
  height: 100%;
  float: left;
  font-size: 20px;
  position: absolute;
  top: 0;
}

.Roulette_ins {
    width: 100%;
    float: left;
    text-align: center;
}
.Roulette_ins .ins {
    width: 86%;
    /* float: left; */
    background: linear-gradient( #a7a7a7, #fff);
    border: solid 1px #ddd;
    padding: 1vh;
    font-size: 16px;
    color: #000;
    margin: auto;
}
.chipsTable .amChipCard img {
  width: 75px;
  float: left; 
  margin: 10px;
}
.float_right {float: right;}
.d_flex {display: flex;}
 
