.dragGame {   
    position: relative;
    background-image: url(../../../../../../assets/games/dragon_tiger/DT_BG.png);  
    background-size: cover;
    /* padding: 3% 6% 4% 6%;  */
    display: flex;
    width: 100%;
    height: 1250px;
    
}

.leftDrag {
    width: 20%;
    float: left;
    position: relative;
}

.middleDrag {
    width: 100%;
    float: left;
    position: absolute;
    z-index: 100;
}

.rightDrag {
    width: 100%;
    float: left;
    top: 925px;
    position: absolute;
    /* left: 0%; */
    /* z-index: 2000000; */
}

/* display: none;  Align New UI */
.dtBox {
    width: 100%;
    float: left;
    /* padding: 0% 2%; */
    position: relative;
}

.dt_board {
    width: 100%;
    position: relative;
}

.boardUpBox {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    /* padding: 0% 5%; */
}

.topHistoryBox {
    height: 290px;
    float: left;
    width: 100%;
}

/* display: none;  Align New UI */
.dt_leftBtns {
    width: 15%;
    float: left;
}

.dt_betPanel {
    width: 100%;
    position: relative;
    z-index: 25;
    margin: auto;
    top: 25px;
}

.dt_betPanel div[class*="col-"] {
    padding-left: 2px;
    padding-right: 2px;
}

.dt_rightBtns {
    position: relative;    
    display: flex;
    top: 195px;    
    align-items: baseline;
    margin: auto;
    width: 760px;
    justify-content: space-evenly;
    height: 105px;
}

.dt_leftBtns img {
    width: 15vh;
    float: right;
    cursor: pointer;
}

.dt_leftBtns img:nth-last-child(1) {
    margin-bottom: 5vh;
}

.dealBtn {
    /* cursor: pointer; */
    background: none;
    border: none;
    width: 250px;
    height: 90px;
}

.betDTbox img {
    width: 100%;
    /* height: 120%; */
}

/* .betDTbox:hover {
    background: lightblue;
    border-radius: 20px;
} */

.betDTbox .betPlace {
    justify-content: center;
    display: flex;
    width: 100%;
    /* cursor: pointer; */
    transition: all 0.5s;
    position: relative;
    height: 160px;
}

.betDTbox.nameBet img {
    width: 100%;
    height: 100%;
}

.betDTbox.nameBet {
    height: auto;
}

.tieBetBox {
    width: 240px;
    margin: auto;
    border-radius: 20px;
}

.skew_left {
    transform: skew(-5deg, 0deg);
}

.skew_right {
    transform: skew(5deg, 0deg);
}

.tieBet:hover {
    background: lightblue;
}

.tieBet {
    /* background: #0d3f44; */
    /* border-radius: 3px; */
    /* border: solid 0.5px #155258; */
    justify-content: center;
    display: flex;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s;
    /* bottom: 0px; */
    /* padding: 30px 15px; */
    position: absolute;
    height: 100%;
}

.p_lr_0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.m_t_9vh {
    margin-top: 9vh;
}

/* .dt_chipBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 27%;
    top: 6%;
    z-index: 2;
    width: 500px;
} */
.dt_chipBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    z-index: 2;
    bottom: 0;
    margin: auto; 
    left: 0;
    right: 0; 
}
.dt_chipBox img {
    width: 100%;
    float: left;
    width: 95px;
}

.dt_Chips {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dt_Chips img {
    transition: all 0.5s;
}
.dt_Chips span {
    position: absolute;
    font-size: 20px;
    font-family: 'Century_Gothic';
    font-weight: bold;
    color: #000000;
    text-align: center;
}

img.chipImage {
    position: absolute;
    width: 90px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}

.betDTbox span {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}

.betDTbox.nameBet img.chipImage {
    width: 100px;
}

.dt_chipBox button {
    background: transparent;
    border: none;
}

.cardWinBox {
    text-align: center;
    top: -15px;
}

img.dragon_card {
    height: 300px;
    transition: all 0.5s;
    transform: translate(690px, 320px);
    animation: drcard 0.5s 1;
    /* z-index: 99; */
    position: absolute;
    border-radius: 10px;
}
img.dragon_card_onLoad {
    height: 300px;   
    position: absolute;
    border-radius: 10px;
    top: 320px;
    left: 690px;
}
img.tiger_card_onLoad {
    height: 300px;   
    position: absolute; 
    border-radius: 10px;
    left: 700px;
    top: 319px;
}

@keyframes drcard {
    0% {
        height: 50px;
        transform: translate(1400px, 280px);
    }

    100% {
        height: 300px;
        transform: translate(690px, 320px);
    }
}

img.tiger_card {
    height: 300px;
    transition: all 1.0s;
    transform: translate(700px, 319px);
    animation: trcard 0.5s 1;
    border-radius: 10px;
}


@keyframes trcard {
    0% {
        height: 50px;
        transform: translate(110px, 280px);
    }

    /* 50% {height: 22.5vh; transform: translate(78vh,45vh);}    */
    100% {
        height: 300px;
        transform: translate(700px, 319px);
    }
}

.hitory_panel {
    width: 81%;
    background: #ddd;
    border: solid 15px #8c8988;
    overflow: hidden;
    margin: auto;
}

.hitory_panel table {
    width: 100%;
    float: left;
    border-collapse: collapse;
    height: 100%;
}

.hitory_panel table tr th {
    flex-grow: 1;
    background: #042c38;
    color: #b6b7b7;
    font-weight: bold;
    font-size: 28px;
    height: 60px;
}

.hitory_panel table tr td {
    text-align: center;
    /* border-bottom: solid 1px #878787; */
    font-size: 30px;
    color: #fff;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    width: 10%;
    font-family: 'arial';
}

/* .hitory_panel table tr {background: #346933;border-bottom: solid 1px #07300652;}  */
.hitory_panel table tr {
    background: #042c38;
    border-bottom: solid 2px #9da39d69;
}

/* .hitory_panel table tr td:nth-child(2){background: #ddd;} */
.hitory_panel table tr td:nth-child(3) {
    background: #123e49;
}

.hitory_panel table tr th:nth-child(2),
.hitory_panel table tr td:nth-child(3) {
    background: #123e49;
}

.hitory_panel table tr td:nth-child(6) {
    background: #123e49;
}

.filter_panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.boxFilt {
    background: #ddd;
    border-radius: 48px;
    cursor: pointer;
    text-align: center;
    padding: 0.1vh 3vh;
    margin-top: 0.9vh;
    font-size: 1.8vh;
}

.m_t_3vh {
    margin-top: 3vh;
}

.historyBottom {
    width: 100%;
    position: absolute;
    background: #ddd;
    bottom: 0;
}

.hitory_panel.fd {
    width: 100%;
}

.hide {
    display: none;
}

.show {
    display: block;
}



.betPlace.active_win {
    color: #FBEC0F;
    animation: winBox 0.5s infinite;
    animation: actId 0.5s infinite;
    /* animation: winBox 0.5s infinite; */
    border-radius: 15px;
    border-style: solid;
    /* border-color: #fbec0f; */
    border-width: 5px;
    border: solid 3px #ffba00;
    box-shadow: 1px 6px 37px 9px rgb(255 181 0 / 50%);
    box-shadow: 1px 6px 17px 9px rgb(255 181 0);
}


@keyframes actId {
    0% {
        background: #bcb444;
    }

    100% {
        background: #FBEC0F;
    }
}

span.t_Count {
    width: 90px;
    font-weight: bold !important;
    text-align: center;
    height: 90px;
    font-size: 22px;
    color: #000;
}

div.betBoxCover {
    /* display: block; */
    background: transparent;
    z-index: 99;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;    
    width: 100%;
    height: 1300px;
}

.tieBet span {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}

.histImg {
    /* width: 35px; */
    height: 35px;
}

.cardDeck_2 {
    width: 10vh;
    /* display: none;  Align New UI */
}

.cardDeck_1 {
    width: 6vh;
    position: absolute;
    left: 19vh;
    right: 0;
    margin: auto;
    top: 2vh;
    z-index: 1;
    /* display: none;  Align New UI */
}

/* @media screen and (min-width : 1260px), screen and (min-height: 880px) {
    .dtBox {
        padding: 0% 0%;
    }
}  */

.betsAndButtons {
    background: #0a3135;
    border-radius: 50px;
    padding: 13px;
    border: solid 2px #e6d08a;
}

.betsAnd_1 {
    border-radius: 60px;
    border: solid 10px #321101;
}

/* .betsAnd_2 {
    margin-top: 0%;
    position: relative;
} */

.betsAnd_2 {
    top: 230px;
    position: absolute;
}

img.dt_bottom {
    /* bottom: 359px; */
    /* top: 843px; */
    width: 37%;
    /* margin-top: -220px; */
    position: absolute;
    left: 31%;
    /* height: 100%; */
    /* SCALE: 0.38; */
}

/* .chipBox_img {
    left: 0px;
    top: -20px;
} */

.left_info {
    position: relative;
    /* top: 10%; */
    font-family: sans-serif;
    /* right: 16%; */
}

/* .profile_img {
    width: 250px;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    left: 0;
    float: left;
} */
.balace_img {
    width: 430px;
    position: relative;
    height: 110px;
}
.winner_img {
    width: 430px;
    position: relative;
    height: 110px;
}

.pid_val {
    position: relative;
    left: 80px;
    display: flex;
    font-size: 20px;
    color: white;
    font-family: 'Century_Gothic';
    top: 80px;
}

.user_name {
    position: relative;
    left: -25%;
    top: 40PX;
    height: 100px;
    display: flex;
}

.balanceImg {
    position: relative;
    left: 55%;
    width: 65%;
    margin-top: 5px;
    border: solid 2px #082a24;
}

.profile_name {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    right: 0;
    bottom: 0;
    left: -200px;
    margin: auto;
    /* float: left; */
    width: 630px;
}

/* .profile_name_txt {
    position: absolute;
    font-size: 25px;
    font-family: sans-serif;
    width: 230px;
    text-align: center;
    justify-content: end;
    display: flex;
    align-items: center;
} */

.historyTop {
    position: relative;
}

.bet_txt {
    background: #210919;
    width: 322px;
    font-size: 30px;
    padding: 5px;
    text-align: center;
    margin: auto;
}
.win_txt {
    position: absolute;
    width: 315px;
    font-size: 40px;
    font-weight: bold;
    padding: 15px;
    left: 19%;
    height: 75px;
    top: 12px;
    font-family: sans-serif;
}
.bottom_bg {
    width: 85%;
    position: relative;
    top: -60px;
    left: 6%;
}

.info_Board {
    width: 350px;
    position: relative;
    top: -50px;
    left: -40px;
}

.info_text {
    position: relative;
    left: 35px;
    font-size: 1em;
    color: wheat;
    top: 15%;
    /* text-align: center; */
}

.info_header {
    position: absolute;
    font-size: 1.1em;
    color: wheat;
    text-size-adjust: auto;
    width: 185px;
    text-align: center;
    width: 250px;
}

.bottom_images {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
}

.centered_chips {
    position: absolute;
    top: 35%;
    left: 35%;
}

.centered_betTxt {
    position: relative; 
    bottom: 0;
    left: 0;
    right: 0;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preChip {
    position: relative;
    /* left: 10%; */
    /* padding-right: 25px; */
    background: transparent;
    margin-right: 25px;
}

.nxtChip {
    position: relative;
    /* left: -130%; */
    /* padding-left: 25px; */
    background: transparent;
    margin-left: 25px;
}

.btns_position {
    position: relative;
    float: right;
    /* right: 20%; */
}

img.dragonWinImg {
    width: 70%;
    left: 5%;
    position: relative;
    top: 5%;
}

img.tigerWinImg {
    width: 60%;
    left: -5%;
    position: relative;
    top: 20%;
}

.dt_chipTxt {
    position: absolute;
    /* display: flex; */
    font-size: 27px;
    /* align-items: center; */
    text-align: center;
    /* top: 33px; */
    /* left: 10px; */
    color: black;
    /* z-index: 99999999999; */
    /* padding-left: 42px; */
    padding-top: 33px;
    font-weight: bold;
    width: 110px;
}

.balance_lbl {
    position: relative;
    display: flex;
    width: 360px;
    top: -35px;
    height: 100px;
    left: 290px;
    /* text-align: center; */
    align-items: center;
}

.balace_txt {
    position: absolute;
    left: 31%;
    font-size: 40px;
    font-weight: bold;
    font-family: sans-serif;
    width: 310px;
    top: 30px; 
}

.betDrbox {
    /* position: relative; */
    /* width: 200px; */
    position: relative;
    height: 60%;
    top: 10%;
    width: 60%;
}

.drImg {
    position: relative;
    width: 100%;
    /* height: 85%; */
    /* top: 65px; */
}

.tieImg {
    position: relative;
    width: 100%;
    height: 102%;
}

.tigerImg {
    position: relative;
    width: 100%;
    /* height: 116%; */
    /* top: 65px; */
}

.betPlace {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    /* cursor: pointer; */
}

.top_bet_panel {
    position: relative;
    display: flex;
    align-items: center;
    /* top: 60px; */
    margin-top: 2%;
}

.btm_bet {
    position: relative;
    /* width: 80%; */
    display: flex;
    /* left: 10%; */
    padding-left: 2%;
    padding-right: 2%;
}

.m_t_12vh {
    margin-top: 15px;
}

.doubleBtn {
    position: relative;
    margin-top: 100px;
    /* margin-left: 50px; */
    float: right;
    left: 120px;
    z-index: 50;
}

.betBtn {
    position: relative;
    /* margin-top: -330px; */
    /* display: flex; */
    float: left;
    /* left: 70px; */
    z-index: 99;
}


.betPlace.topBetImg {
    height: 320px;
}

/* .dragonWinImg {
    display: flex;
    position: fixed;
} */

.dragonImg {
    width: 495px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.dragonWinCard {
    position: absolute;
    /* background: red; */
    left: -375px;
    top: -250px;
}

.col-4.dragonWinImg {
    display: flex;
    top: -10vh;
    left: 4%;
}

.col-4.tigerWinImg {
    display: flex;
    top: -10vh;
    right: 6%;
}

.tigerImg {
    width: 470px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.tigerBetImg {
    position: relative;
    width: 100%;
    height: 102%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.newBetBtn {
    position: relative;
    margin-top: 40px;
    /* margin-left: 50px; */
    float: right;
    left: 120px;
    z-index: 110;
}

.rebetBtn {
    position: relative;
    margin-top: 40px;
    z-index: 110;
    display: flex;
}

.disBtn {
    opacity: 0.7;
    cursor: no-drop;
    pointer-events: none;
}

.disBetPanel {    
    cursor: no-drop;
    pointer-events: none;
}

button#betBtn {
    border: none;
    background: transparent;
}

input:hover,
select:hover {
    border-left: solid 3px #9a650300 !important;
}

.img_point {
    cursor: pointer;
}

/* .dt_infoTxt {
    position: absolute;
    width: 68%;
    font-size: 25px;
    top: 63%;
    left: 15%;
    z-index: 9999;
    height: 50px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
} */
.dt_infoTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 678px;
    top: 10px;
    text-align: center;
    font-size: 25px;
    font-family: 'Poppins';
}
.undoBtn {
    position: relative;
    z-index: 50;
    float: right;
    width: 250px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.newGameBtn {
    position: relative;
    z-index: 50;
    float: right;
    width: 250px;
    background: none;
    border: none;
    /* margin-top: 25px; */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: flex;
}

.leftBtns {
    /* bottom: 180px; */
    /* float: right; */
    /* left: 70px; */
    /* height: 100%; */
    position: relative;
    top: 184px;
    width: 730px;
    /* background: red; */
    justify-content: space-evenly;
    display: flex;
    align-items: baseline;
}

.rightBtns {
    /* top: 100px; */
    float: left;
    /* right: -40px; */
}

.undoBtnTxt {
    font-family: 'Century_Gothic_B';
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    display: inline-block;
    -webkit-background-clip: text;
    background-clip: text;
    color: black;
}
.clrBtnTxt {
    font-family: 'Century_Gothic_B';
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    display: inline-block;
    -webkit-background-clip: text;
    background-clip: text;
    color: rgb(255, 255, 255);
}

.betBtnAlign {
    position: relative;
    z-index: 50;
    width: 250px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.betLmtTxt {
    position: absolute;
    top: 36px;
    left: 15px;
    width: 286px;
    /* text-align: center; */
    font-size: 25px;
    font-family: sans-serif;
}
.cls_cen {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 70px;
    /* margin-top: 10px; */
}

.dragontigerClose button {
    all: unset;
    position: absolute;
    z-index: 1024;
    cursor: pointer;
    width: 105px;
    right: 0;
    top: 30px;
    right: 30px;
    background: transparent;
}

.drtBetBtns {
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
}

img.fd.chipBox_img {
    height: 250px;
    width: 1050px;
    left: 60px;
}

.fr {
    float: right;
}

div#dbig {
    position: relative;
    top: -80px;
    /* left: -25px; */
    right: 0;
}

div#tbig {
    position: relative;
    top: -80px;
    /* left: 30px; */
    right: 0;
}

div#dsml {
    bottom: 90px;
    position: relative;
}

div#tsml {
    bottom: 90px;
    position: relative;
}

.goldCoin_dr {
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 75;
}

.goldCoin_dr span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    color: #000;
    font-weight: 100;
    font-size: 25px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* font-family: cursive; */
    font-family: Verdana;
    background: linear-gradient(#f59407, #f7f812, #f7f812, #f59407);
    border-radius: 50%;
}

.dr_bt_active {
    animation-name: dr_btnActive;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    background: linear-gradient(#c72722, #b11815, #840101, #960000, #ae0000);
    padding: 18px 20px;
    border-radius: 15px;
}

@keyframes dr_btnActive {
    0% {
        box-shadow: 0 0 9px 12px rgb(255 238 0)
    }

    49% {
        box-shadow: 0 0 12px 12px rgb(255 238 0)
    }

    50% {
        box-shadow: 0 0 0px;
    }

    100% {
        box-shadow: 0 0 0px;
    }
}

.dr_bt_1 {
    background: linear-gradient(#d9fc44, #fad247, #f79917, #f83d01, #7c1b00);
    border: none;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 35px;
    border: solid 5px #d9fd44;
    font-family: Century_Gothic_B;
}

.dr_bt_take {
    /* background: linear-gradient(#ff6666, #ff4e4e, #ff3e3e, #ff2c2c, #ff0202); */
    background: linear-gradient(#d9fc44, #fad247, #f79917, #f83d01, #7c1b00);
    border: none;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 35px;
    border: solid 5px #d9fd44;
    font-family: Century_Gothic_B;

}

.dr_bt_prev {
    /* background: linear-gradient(#52e3d8, #10c2b9, #0dada9, #078087, #01363e); */
    background: linear-gradient(#d9fc44, #fad247, #f79917, #f83d01, #7c1b00);
    border: none;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 35px;
    border: solid 5px #d9fd44;
    font-family: Century_Gothic_B;
}

.dr_bt_undo {
    background: linear-gradient(#e35dad, #b60c83, #8d107f, #5b117f, #29093f);
    /* background: linear-gradient(#ff6666, #ff4e4e, #ff3e3e, #ff2c2c, #ff0202); */
    /* background: #6a0100; */
    border: none;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 35px;
    border: solid 5px #ffc000;
    font-family: Century_Gothic_B;
}

.dr_bt_newGame {
    display: none;
    /* background: linear-gradient(#85aefc, #365ef0, #4844e3, #582dd8, #651ace); */
    background: linear-gradient(#ff6666, #ff4e4e, #ff3e3e, #ff2c2c, #ff0202);
    border: none;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 35px;
    border: solid 5px #d9fd44;
    font-family: Century_Gothic_B;
}


div#dr {
    position: relative;
    bottom: 160px;
}

div#tr {
    position: relative;
    bottom: 160px;
}

div#ti {
    position: relative;
    bottom: 160px;
}


.dbig_img::after,
.tbig_img::after,
.dsml_img::after,
.tsml_img::after,
.dr_img::after,
.tr_img::after,
.ti_img::after {
    background: #58000000;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 90;
}

.dt_timer_Sec {
    all: unset;
    position: absolute;
    right: 0;
    top: 100px;
    right: 75px;
    background: transparent;
    /* background-image: url(../../../../../../assets/games/dragon_tiger/dt_timer_bg);     */
    background-image: url(../../../../../../assets/games/dragon_tiger/dt_timer_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 220px;
    height: 220px;
}

.dt_time_txt {
    display: none;
    color: white;
    font-size: 55px;
    font-family: 'Century_Gothic_B';
    justify-content: center;
    align-items: center;
    display: flex;
    top: 73px;
    position: relative;
    left: 53px;
    width: 120px;
    text-align: center;
}
/* .dt_timer_Sec.timerYelloBorder { 
        animation: borderAnim_sorat 1.2s infinite;
      } */
@keyframes borderAnim_dt {
    from {
        border: solid 20px #fff000;
    }

    to {
        border: solid 20px transparent;
    }
}

.dt_timer_glow {
    position: absolute;
    width: 156px;
    height: 160px;
    border: 15px solid;
    border-color: #fff000;
    border-radius: 50%;
    left: 34px;
    top: 28px;
    animation: borderAnim_dt 1.2s infinite;
}

.cursor_none{
    cursor: none !important;
}


.dt_win_txt {
    width: 174px;
    position: absolute;
    font-size: 43px;
    font-family: 'Century_Gothic';
    font-weight: bold;
    color: #000000;
    text-align: center;
    top: 64.5%;
    left: 46%;
    transform: translate(-50px, -50px);
}
.dt_popup_back {
    display: none;
    position: fixed;
    background: #433e3e52;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0px;
    z-index: 999;
}

.dt_winpop {
    /* display: none; */
    /* max-width: 500px; */
    margin: auto;
    position: relative;
    top: 20%;
    left: 0%;
    right: 25%;
    background-image: url(../../../../../../assets/games/win_popup/win_popup.png);
    /* background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b); */
    padding: 0px 25px 25px 25px;
    /* box-shadow: 0 8px 17px 0 rgba(34, 33, 33, 0.2),0 6px 20px 0 rgba(0,0,0,.19)!important; */
    border-radius: 6px;
    animation: flip-scale-down-diag-2 2s 1;
    z-index: 1000;
    height: 700px;
    background-repeat: no-repeat;
    width: 1000px;
}


@keyframes flip-scale-down-diag-2 {
    0% {
      transform: scale(0.01) ;
    }
    20% {
      transform: scale(1) ;
    }
   
    80% {
        transform: scale(1) ;
    }
    100% {
        transform: scale(0.01) ;
      }
  }



/* .dt_winpop{

    display: none;
    font-family: "Leelawadee UI";
    position: fixed;
    right: 0;
    width: 800px;
    left: -5px;
    right: 0;
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    height: -moz-fit-content;
    height: fit-content;
    font-size: 40px;
    margin: auto;
    z-index: 999;
    padding: 21px;
    justify-content: center;
    align-items: center;
    color: #000;
    border: solid 6px #edcc4a;
    text-align: center;
    box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
    animation: dt_popup_anim 1s 1;
    transform: translate(1700px, 0px);
    flex-direction: column;
    top: 0;
    bottom: 0;
    font-weight: bold;
    box-shadow: 1px 1px 62px 3px rgb(236 203 74);
  
  } */
  
  
  @keyframes dt_popup_anim {
    0% {
      transform: translate(-1500px, 0px);
    }
  
    20% {
      transform: translate(0px, 0px);
    }
  
    80% {
      transform: translate(0px, 0px);
    }
  
    100% {
      transform: translate(1700px, 0px);
    }
  }
  

  .bet_lmt_info {
    justify-content: center;
    position: relative;
    align-items: center;
    align-content: center;
    transform: translate(0px, 80px);
}

div#tbig_img {
    left: -5px;
}

.dt_sparkAnim {
    width: 2350px;
    position: absolute;
    top: -5%; 
    left: 0%;
    height: 1275px;
     transform: scale(2,2); 
    z-index: 990;
    /* background: red; */
}

.rightChip, .leftChip{
    width: 95px;
    height: 90px;
    /* background: red; */
}

.btnDiv{
    width: 250px;
    height: 150px;
    background: #210919;
}

img.dt_title {
    position: absolute;
    left: 10%;
    width: 80%;
    height: 100%;
    top: 17px;
}

.chipValue {
    position: absolute;
    font-size: 30px;
    color: black;    
    width: 94px;
    height: 78px;    
    text-align: center;    
    bottom: 0;
}