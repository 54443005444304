.americanRltTimerCover {
    display: none;
    position: fixed;
    top: 85px;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    /* background: #00000082; */
    z-index: 1020;
}
.z_1050 {z-index: 1050;}
.z_1500 {z-index: 1500;}
#ARNTwheelContainer .konvajs-content, #ARNTwheelContainerOuter .konvajs-content {margin: auto;}
.ARNTwheelContainer, #ARNTwheelContainerOuter {
    transition: all 0.5s;
}

#amRoutTimerWheel { 
    transition: all 0.5s ease 0s; 
    transform-origin: center top;
    margin: auto;
    left: 0;
    right: 0; 
    margin-top: 40px;
}
.ht_100 {
    height: 100px;
}
.americanRouletteTimerMain {
    background: #002000 url(../../../../../../assets/pattern.svg); 
}
.americanRouletteTimerIns {
    width: 100%;
    float: left;
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    border: solid 3px #f3d650;
    padding: 10px;
    font-size: 30px;
    color: #000;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.americanTimerWinPop {
    position: fixed;
    right: 0;
    width: 800px;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    height: -moz-fit-content;
    height: fit-content;
    font-size: 28px;
    margin: auto;
    z-index: 999;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: solid 12px #edcc4a;
    text-align: center;
    box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
    animation: amr_TimerResult 3s 1;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    font-weight: bold;
    box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
@keyframes amr_TimerResult{
    0% { transform: translate(-500px, 0px); }
    20% { transform: translate(0px, 0px); }
    80% { transform: translate(0px, 0px); }
    100% { transform: translate(2000px, 0px); }
}
.americanRouletteTimerMain .amChipCard img {
    width: 67%;
    cursor: pointer;
    transition: all 0.5s;
    margin-top: 15px;
}

.americanRouletteTimerMain .amChipCard img.active {
    border: solid 3px #ffba00;
    border-radius: 50%;
    box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}
.americanRouletteTimerMain .wheelandhis .fun_chips .funBal {
    background: transparent;
}
.americanRouletteTimerMain .wheelandhis .fun_chips .funBal div:nth-child(1) {
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    padding: 5px 0px;
    color: #000;
    font-weight: bold;
}
.americanRouletteTimerMain .wheelandhis .fun_chips .funBal div:nth-child(2) {
    background: #2100005e;
    padding: 4px 0px;
}
.americanRouletteTimerMain .fun_btns button.ar_bt_1 {
    background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
    border: solid 3px #f3d550;
    margin-top: 11px;
    color: #000;
    font-weight: bold;
    padding: 23px;
}
.americanRouletteTimerMain .fun_btns button.ar_bt_1:disabled {
    background: linear-gradient(90deg, #4a4742, #bbbbbb, #4a4742);
    border: solid 3px #bdbdbd;
}

 
.americanRouletteTimerMain table.american_HistoryTbl tr td {
    background: #bbbbbb;
}

.americanRouletteTimerMain .userLbl {
    background: linear-gradient(90deg, #4a4742, #bbbbbb, #4a4742);
    border-radius: 5px;
    padding: 15px;
    width: 370px;
    margin-bottom: 15px;
    color: #000;
    /* border: solid 1px #ffffff36; */
    font-size: 25px;
    display: flex;
    height: 87px;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
}

/****************** NEW WHEEL START ********************************/

* {box-sizing: border-box;}
.art_wheelAnim img { width: 100%;}
.art_wheelAnim {
    position: relative;
    width: 480px;
    height: 480px;
    margin: auto; 
    border-radius: 50%;  
}
 
.art_wheelAnimOne {
    width: 480px;
    height: 480px;
    margin: auto;
    left: 0;
    right: 0;
    top: 7px;
    position: absolute;
    transform: scale(1.61, 0.74);
    margin-top: 17px;
}
.art_wheelAnimParent {
    width: 480px;
    height: 480px;
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    -webkit-animation:art_spin 25s linear infinite;
    -moz-animation:art_spin 25s linear infinite;
    animation:art_spin 25s linear infinite;
    
}
@-moz-keyframes art_spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes art_spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes art_spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.art_ballBg {
    position: absolute;
    width: 480px;
    height: 480px;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-100px);
}

img.art_rollBall {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.5s;
  transform: translateY(-165px);
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  box-shadow: 2px 2px 9px -1px rgb(0 0 0 / 50%);
  border-radius: 50%; 
}

.art_bollWrap {
  width: 480px;
  height: 480px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.art_WheelWraper {
  width: 1250px;
  height: 560px;
  position: relative;
  margin: auto;
  transform: scale(0.8, 0.9);
  margin-top: 5px;
  left: -150px;
}
 
.art_wheelBorder {
  margin: auto; 
  position: absolute;
}

.art_wheelBorder img {
    width: 1250px;
    height: 560px;
}

.art_wheelBorder_out {
    margin: auto;
    position: absolute;
    width: 1250px;
    height: 560px;
    /* transform: scale(0.79, 0.8); */
    top: -1px;
}

.art_wheelBorder_out img {
    width: 1250px;
    height: 560px;
}

.art_wheelPoll {
    margin: auto;
    position: absolute;
    transform: scale(1.23, 1.2);
    top: 12px;
    left: -3px;
}
  
.art_wheelPoll img {
      width: 1250px;
      height: 560px;
}

/****************** NEW WHEEL END ********************************/

.mainVal::after, .boxCntr::after, .btmVal::after, .cntrVal::after, .leftVal::after, .boxLeft::after, .boxBtm::after, .boxCrnr::after, .boxTop::after, .boxRight::after {
    background: #58000000;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 90;
  }
  .exit-timer{
    display: flex;
    flex-direction: row;
    align-items: center;
}



.chipImg{
    cursor: pointer;
  }

  .art_overlay-text {
    text-align: center;
    position: absolute;
    /* top: 50%; */
    /* right: 50%; */
    /* transform: translate(50%, -196%); */
    font-size: 25px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* display: flex; */
    top: 45px;
    color:black !important;
}


.art_goldCoin {
    width: 70px;
    height: 70px;
    top: 0;
}

.art_goldCoin span {
    top: 19px;
    color: #000;
    font-weight: 700;
    font-size: 30px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
 
.art_goldCoin img {
    width: 100%;
}

.tableAmerican {
    margin-top: 60px !important;
}
.histHead{
    text-align: center;
}