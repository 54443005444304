
.fd.cards .cardDiv img {
    width: 100%;
    transition: all 0.5s;
  }
  .fd.cards .cardDiv:hover img {
    transform: scale(1.1);
    cursor: pointer;
  }
  .fd.cards .cardDiv {
    padding: 5px;
    flex-grow: 1;
    position: relative;
  }
  .fd.cards {
    display: flex;
  }
  .FGAfunCard .funCardChips {
    width: 100%;
    display: flex;
    justify-content: space-around;
    float: left;
}
  .FGAfunCard .chipCard {transition: all 0.5s;width: 9%;float: left; cursor: pointer;} 
  .FGAfunCard .chipCard img {width: 80%;cursor: pointer;transition: all 0.5s;}
.chipCard:hover img {transform: scale(1.1);}
.chipCard img.active {
        border: solid 5px #ffba00;
        border-radius: 50%;
        box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}
  .funCardTable { width: 100%;float: left;}
  .funCardTable .AddCards {width: 60%;float: left;}
  .funCardTable .winnerTable {width: 20%;float: left;padding: 0px 10px;
  }
  .fc_buttonList {
    width: 20%;
    float: left; 
    padding: 5px;
}
  .funCardTable .winnerTable table {width: 100%;float: left; 
    border-left: solid 2px rgb(8 23 53);
      border-top: solid 2px rgb(8 23 53);
  background: #363D6A;
       text-align: center;
       box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
      height: 550px;}
  .funCardTable .winnerTable table tr th {
    border-right: solid 2px rgb(8 23 53);
    border-bottom: solid 2px rgb(8 23 53);
      width: 33.33%;
      border-collapse: collapse;
      font-size: 30px;
      color: #fff;
      height: 50px;
}
.funCardTable .winnerTable table tr td {
  border-right: solid 2px rgb(8 23 53);
  border-bottom: solid 2px rgb(8 23 53);
  color: #fff; 
  font-size: 28px;
  vertical-align: top;
  line-height: 49px;
}
.funCardTable .AddCards .cardBox {
  width: 100%;
  float: left;
  border: solid 2px rgb(8 23 53);
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  height: 210px;
  position: relative;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  background: #363D6A;
}
.funCardTable .AddCards .cardBox span.boxName {
	font-size: 65px;
	color: #222952;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	width: fit-content;
	top: 0;
	bottom: 0;
	height: fit-content;
  z-index: 1;
}
 
.F_card_btnPanel {
    width: 100%;
    float: left; 
}
.F_Card_ins {
  width: 100%;
  float: left;
  text-align: center;
  padding: 0px 10px;
  background: #363d6a;
  height: 281px;
  border-radius: 10px;
  box-shadow: 5px 5px 9px rgb(0 0 0 /50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
}

.fcard_userLbl img { width: 40px; margin-right: 5px; }
.fcard_userLbl {
  background: #000000a8;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 15px;
  color: #fff;
  border: solid 1px #ffffff36;
  float: right;
  width: 280px;
  right: 15px;
  font-size: 30px;
  position: relative;
  display: flex;
}
.betamt span { color: #fdcd2f; }
.betamt {
  width: 20%;
  float: left;
  display: flex;
  align-items: center;
  background: #363d6a;
  /* border: solid 2px #59639f; */
  margin-left: 15px;
  margin-top: 52px;
  padding: 18px;
  border-radius: 10px;
  color: #fff;
  margin-top: 60px;
  margin-left: 180px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
}
.cardDeck {width: 20%;float: left;z-index: 99;position: relative;}
.cardDeck img{transform: rotate(-90deg);}
.playerBox, .dealerBox {margin-left: 80px;display: unset;}
.F_card_title {
  width: 400px;
  float: left;
  text-align: center;
  margin-left: 230px;
  margin-top: 10px;
}
.gldDiv img{ width: 100% ;}
.gldDiv {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  margin: auto;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: url("../../../../../../assets/gold_coin.svg");
}
.gldDiv span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: #000;
  font-weight: bold;
  line-height: 78px;
  font-size: 25px;
  cursor: pointer;
}
 
.cardBox .dealerBox img {
  margin-left: -80px;
  box-shadow: 1px 1px 22px -5px rgb(0 0 0); 
  height: 190px; 
  position: relative;
  z-index: 5;
}
  
.cardBox .dealerBox img:nth-last-child(1) {
  height: 190px;
  animation: dealerCard 0.5s 1; 
} 
@keyframes dealerCard { 
  0% { height: 20px; transform: translate3d(3400px, -1200px, 1000px); }  
}   

.cardBox .playerBox img {
  margin-left: -80px;
  box-shadow: 1px 1px 22px -5px rgb(0 0 0); 
  height: 190px; 
  position: relative;
  z-index: 5;
}

  .cardBox .playerBox img:nth-last-child(1) {
    height: 190px;
  animation: playerCard 0.5s 1; 
  }
  
 @keyframes playerCard {
   0% { height: 20px; transform: translate3d(2800px, -1200px, 1000px);}  
 }  

 label.playerCheck input {
   float: left;
   width: 50px;
  height: 50px; 
  outline: solid 2px rgb(221, 181, 0);
  margin-right: 10px;
}

label.playerCheck {
  color: #fff;
  cursor: pointer;
  text-shadow: 2px 2px 3px rgb(0 0 0);
  font-size: 35px;
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
  
.cardsCover {
  position: absolute;
  background: #dddddd00;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: none;
}
.cardLeft {
  width: 120px;
  float: left;
}
img.cardDown {
  position: absolute;
  width: 120px;
  float: left;
} 

 
.font_2vh {
  font-size: 2vh;
}
.funCardCover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  /* background: rgba(0, 0, 0, 0.329); */
  z-index: 99;
  display: none;
}
.z_320 {z-index: 320;}
.z_1020 {z-index: 1020;}
.F_card_title .titleImg {height: 220px;}
 
 
.FGAfunCard {
  background-size: 100% 100%;
  width: 100%;
  float: left; 
  height: 100%;
  background: #222848;
  padding: 30px;
  height: 1250px;
}
.FGAfunCard .funBal {
  background: #363D6A;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 /20%);
}

.FGAfunCard .funBal.fd div:nth-child(1) {
  background: #ddd;
  text-align: center;
  background: #222848;
  font-size: 28px;
  border-radius: 8px;
  padding: 8px 0px;
  color: #fdcd2f;
}
.FGAfunCard .funBal.fd div:nth-child(2) {
  font-size: 28px;
  color: #fff;
  text-align: center;
  padding: 13px 0px;
  background: transparent;
}
.f_card_balance {
  width: 36%;
  display: flex;
  float: left;
  margin-top: 70px;
  margin-left: 40px;
}
.playCardCloseGame button img { width: 100%;}
.playCardCloseGame button {
  background: #FF0066;
  width: 70px;
  height: 70px;
  float: right;
  margin-top: 70px;
  margin-right: 30px;
  border-radius: 5px;
  border: none;
  z-index: 1024;
  position: relative;
}
.F_card_btnPanel button {
  width: 47%;
  float: left;
  font-weight: bold;
  height: 80px;
  padding: 0px !important;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 28px;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgb(0 0 0 /50%);
}
.F_card_btnPanel .mabBtn_1{
  background: #25af60;
}
.F_card_btnPanel .mabBtn_2{
  background: #FF0066;
}
.F_card_btnPanel .mabBtn_3{
  background: #25af60;
  margin-left: 27%;
} 


.winPopAndarBahar { 
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px; 
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: andarBahar 2s 1; 
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
.popBorder {
  display: flex;
  justify-content: center;
}
@keyframes andarBahar{
  0% { transform: translate(-1000px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px); }
}


.F_card_btnPanel .mabBtn_1:disabled {
  background: #90ab9c;
}
.F_card_btnPanel .mabBtn_2:disabled {
  background: #c1819a;
}
.F_card_btnPanel .mabBtn_3:disabled {
  background: #90ab9c;
}


.goldCoin_fcd img{ width: 100% ;}

.goldCoin_fcd {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.goldCoin_fcd span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: #000;
  font-weight: bold;
  font-size: 25px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: 'Century_Gothic';
 
  background: url("../../../../../../assets/gold_coin.svg");
  background-size: 110px;
  background-repeat: no-repeat;
}

.fc_overlay_text {
  text-align: center;
  position: relative;
  top: 20%;
  right: 0%;
  transform: translate(-9%, -266%);
  font-size: 25px;
  bottom: 24%;
  color:black !important ;
}