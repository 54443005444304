.pop {
    background: #171717f0;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.popOutWid {
    position: absolute;
    max-width: 350px;
    margin: auto;
    left: 0;
    right: 0;
    top: 80px;
}

.popBtn {
    background: linear-gradient(#83290b, #f8f27b);
    padding: 11px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}