.FGAamericanRouletteTitle{
    width:50vw;
    height: 90px;
    background-image: url("../../../../assets/games/americanRoulette/arTitle.gif");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 5px;
    left: 25vw;
}
.titleImg {margin-top: 10px; height: 100px;}
 