.history.history_AR li {
    background: #ffecd1 !important;
    text-align: center;
    font-size: 15px;
    border: solid 1px #ff9300;
    color: #000;
}

.FGAamericanRouletteClose {
    position: absolute;
    z-index: 1024;
    margin-top: 10px;
    margin-top: 10px;
    width: 50px;
    right: 0;
}

.FGAamericanRouletteClose button img {
    width: 100%;
    position: relative;
    /* left: -32px;
    top: 20px; */

    left: -10px;
    top: -33px;


}
.FGAamericanRoulette button img {
    width: 100%;
    position: relative;
    left: -20px;
    top: 10px;
}
.FGAamericanRouletteClose button {
    all: unset;
    width: 70px;
    height: 70px;
    cursor: pointer;
    float: right;
    margin-right: 10px;
}
.col_chips { width: 23%; float: left; }
.col_routlet { width: 60%; float: left; }
.col_btns { width: 17%; float: left; }
  
.rouletteTableCover{
    display: none;
}

.rouletteTableCoverForTake, .rouletteTableCover {
    position: fixed;
    top: 86px;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    /* background: #00000082; */
    z-index: 120;
}

@media only screen and (max-width: 800px) {
    .col_chips { width: 23%;}
.col_routlet { width: 54%; }
.col_btns { width: 23%; }
}

.active {
    color: #FBEC0F;
    animation: winBox 0.5s infinite;
}
.amChipCard img.active {
    border: solid 3px #ffba00;
    border-radius: 50%;
    box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}
.amChipCard img {
    width: 88%;
    cursor: pointer;
    transition: all 0.5s;
}
.box_cdlm {
    width: 140%;
}
.timer_Sec.timerYelloBorder { 
    animation: borderAnim 1s infinite;
}
@keyframes borderAnim {
    from {border: solid 4px #fffe00dd; }
    to {border: solid 4px #d60000dd; } 
}
.fun_routlet_table {
    width: 60%;
    float: left;
}


/*********************** European roulette start ******************/
.fun_chips{
    text-align: center;
      font-size: 30px;
      padding: 10px;
  }
   
  .left_panel{
    background: #0b1017;
  }
  .bg_blnc1{
    background: #070a0e;
    color: #f40037;
    padding: 10px;
    font-family: "digital";
  }
  .bg_blnc2{
    padding: 10px;
    color: #fff;
    font-family: "digital";
  
  }

  /*********************** European roulette end ******************/

  .FGATargetNonTimer {
    position: absolute;
    right: 30px;
    top: 12px;
    cursor: pointer;
    z-index: 999;
}

.FGATargetNonTimer img {
    width: 100px;
    height: 85px;
}

