.col_33 {width: 33.333%;float: left;}
.col_matka_left {width: calc(33.333% - 20px);}
.col_matka_left .secList {width: 100%;}
.col_matka_right {width: calc(33.333% + 20px); float: left;}
.col_left {width: 33.333%;}
.topBox {display: flex; width: 100%;float: left;}
.topBox div { flex: 1; }

.nonTimerMatka .in_1 {
    background: linear-gradient(#bb6800, #c8cc00, #bb6800);
    padding: 11px;
    border-radius: 9px;
    border: solid 1px #3e0202;
    text-align: center;
}
.nonTimerMatka .in_2 {
  padding: 1.8px 5px;
  border-radius: 6px;
  border: solid 2px #006e00;
  text-align: left;
  float: left;
  width: 19%;
  color: #ffd400;
  margin-left: 5.8px;
  cursor: pointer;
  font-size: 23px;
  font-weight: 200;
}
.nonTimerMatka .in_2:nth-child(odd) { background: #005400; }
.nonTimerMatka .in_2:nth-child(even) { background: #001e00; }
.nonTimerMatka .topBox div:nth-child(odd) { background: #005400; }
.nonTimerMatka .topBox div:nth-child(even) { background: #001e00; }
.m_name {
    text-align: center;
    font-size: 45px;
    font-family: initial;
    color: #fbff00;
    font-weight: bold;
}
.Round_1 {
    width: 350px;
    height: 350px;
    background: #f35500;
    border-radius: 50%;
    border: solid 2px #ff0;
    margin: auto;
    margin-top: 50px;
}
.bdr_mtk {border: solid 1px #9e8200;}
 .chipBox {
   background: #340e00;
   width: 100%;
   float: left;
   border-radius: 3vh;
 }
.bdr_matka {
  background: linear-gradient(45deg, #ed9a00, #d3c84f, #ed9a00);
  border-radius: 3vh;
  padding: 0.5vh;
}
.m_bg_1 {background: #340e00;}
.clr_ff {color: #fff;}
ul.chips_matka li:hover img {
  transform: scale(1.1);
}
/* ul.chips_matka li {
  width: 20%;
  float: left;
  list-style: none;
  text-align: center;
  margin-top: 3px;
} */
ul.chips_matka li {
  width: 20%;
  float: left;
  list-style: none;
  text-align: center;
  /* margin-top: 3px; */
  position: relative;
  height: 95px !important;
  cursor: pointer;
}
ul.chips_matka li img {
  width: 95px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  /* top: -49px; */
}
ul.chips_matka li img.active {
  border: solid 2px #ffba00;
  border-radius: 50%;
  box-shadow: 1px 6px 17px 9px rgb(255 181 0 / 50%);
}
ul.chips_matka {
  all: unset;
}

.secList {width: calc(100% - 50px);float: left; }
.spDp {
  cursor: pointer;
  width: 80px;
  float: left;
  background: #0000008c;
  color: #ff0;
  border: solid 1px #9e8200;
  display: flex;
}
.d-flex {display: flex;}
.bg_00 {background: #000;}
.text_left {text-align: left;}

.FGAfunMatkaClose button img {
  width: 100%;
  position: absolute;
  top: 0vh;
  /* right: 0; */
  right: 32px;
  z-index: 360;
}

.FGAfunMatkaClose{
  position:absolute;
  top:20px;
  right:10px;
 z-index: 360;
}
.FGAfunMatkaClose button{
  width: 60px;
  height: 50px;
  border: none;
  background: transparent;
  font-size: 30px;
}
.FGAfunMatkaClose button:hover{
background-color: transparent;
}
.matkaUserLbl img { width: 35px; margin-right: 5px; }
.matkaUserLbl {
  background: #000000a8;
  border-radius: 8px;
  padding: 18px;
  margin-top: 15px;
  color: #fff;
  border: solid 1px #ffffff36;
  width: 280px !important;
  font-size: 28px;
  display: flex;
  height: 62px;
  margin-bottom: 8px;
  /* justify-content: center; */
  align-items: center;
}

.matkaBal {
  background: #ff6600;
  text-align: center;
  color: #fff;
  padding: 4px;
  font-size: 28px;
  margin-bottom: 5px;
}
.matkaBal div:nth-child(1) {
  background: linear-gradient(#ff6600, #973c00);
  padding: 0.2vh;
}
.matkaBal div:nth-child(2) {
  background: #20200b;
}
.f_matka_btns button.mtkBt_1 { 
  background: linear-gradient(#4a0000, #b74949, #4a0000);
    border: none;
    border-radius: 5px;
    padding: 2px;
    width: 100%;
    float: left;
}
.f_matka_btns button.mtkBt_2 {
  width: 100%;
  float: left;
  background: linear-gradient(#800000, #4a0000, #800000);
  border: none;
  color: #fff;
  padding: 21px 5px;
  border-radius: 9px;
  text-transform: uppercase;
  font-size: 28px;
  transition: all 0.5s;
  border: solid 3px #ffffff38;
  /* ........................ */
  font-family: "poppins";
  /* ........................ */
}

.mtkBt_1.active, .mtkBt_2.active {
  box-shadow: 0px 0px 23px 6px rgb(255 82 0);
  animation: btnActive 0.5s infinite; 
}
@keyframes btnActive {
  0% { box-shadow: 0 0 15px rgb(255, 238, 0); background: rgb(221, 118, 1); }
  50% { box-shadow: 0 0 0px; background: red; }
  100% { box-shadow: 0 0 15px rgb(255, 238, 0); background:  rgb(221, 118, 1);}
}
.mtkBt_1:hover, .mtkBt_2:hover { background: linear-gradient(#350000, #800000, #4a0000); }


/* .wheel_HW {
  width : 60vh;
  height : 60vh;
  position : absolute;
  left : 50%;
  top : 0vh;
  transform : translateX(-50%); 
} */
.matkaScale {
  transition: all 0.5s;
  transform: scale(1.03);
    transform-origin: bottom;
    z-index: 99;
} 
.leftBox .secList.matkaScale {
  transform-origin: left;
  z-index: 9;
}
.dp_1, .sp_1 {  
  float: left;
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 72px;
}
.matka_m_t_vh {margin-top: 0.5vh;}
.matkaTitle {height: 80px;}
.chips_matka li:nth-child(6) {margin-left: 10%;}
.id_Numbers { width: 100%; float: left; font-size: 28px; color: #fff; text-align: left; }
.matkaWheelAuto {
  height: 700px; 
}
 
 
 
.in_2 span {
  color: #fff;
  float: right;
} 
.mt_matkaRes span{ line-height: 0; }
.mt_matkaRes {
  padding: 10px;
  color: #fff;
  border-radius: 33px;
  margin-top: 6px;
  background: #003400;
  border: solid 3px #009100;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}
.funMatkaCover, .matkaCover {
  position: fixed;
  /* background: #00000094; */
  background: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none;
}
.z_120 {z-index: 120;position: relative;}
.z_0 {z-index: 0;position: relative;}


.in_2.activeIn {
  background: linear-gradient(#c39329, #feef56, #c39329) !important;
  animation: blinkWin 0.5s infinite;
  color: #000;
}
.lh_3vh {line-height: 3vh;}
.lh_info {line-height: 1.5;}


.matkaWheelAuto .konvajs-content {
  margin: auto;
}
.m_t-75 {
  margin-top: -75px;
}
.m_t-50 {
  margin-top: -50px;
}
.matkaNonTimerTitle {
  margin-top: 25px;
  height: 100px;
}
.winPopMatka { 
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px; 
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: matkaWin 2s 1;
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}
.popBorder {
  display: flex;
  justify-content: center;
}
@keyframes matkaWin{
  0% { transform: translate(-500px, 0px); }
  20% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  100% { transform: translate(2000px, 0px); }
}
.nonTimerMatka {
  background: radial-gradient(#008300, #001c00);
} 

.nonTimerMatka .numberSet {
  border: solid 2px #006e00;
}

.nonTimerMatka .f_matka_btns button.mtkBt_2:disabled {
  background: linear-gradient(#767676, #404040, #767676);
}
.nonTimerMatka .f_matka_btns button.mtkBt_2 {
  background: linear-gradient(#ff8800, #fd4600, #ff8800);
}

/****************** NEW WHEEL START ********************************/

* {box-sizing: border-box;} 

.mt_wheelNum1{
  position: absolute;
  width: 100%;
}

.mt_wheelBg {
  position: relative;
  margin: auto;
  width: 745px;
  height: 800px;
  margin-top: -60px;
  transform: scale(1);
  left: 8px;
}
.wheelBorder_1 { width: 100%; float: left; }
.wheel_nos_1 {
  position: absolute;
  left: 47px;
  width: 641px;
  top: 88px;
}
.wheel_nos_2 {
  position: absolute;
  left: 124px;
  width:490px;
  top: 163px;
}
.wheel_nos_3 {
  position: absolute;
  left: 198px;
  width: 343px;
  top: 233px;
}
.matkaResPos {
  position: absolute;
  left: 267px;
  width: 200px;
  top: 70px;
  height: 250px;
}

.pivotNum {
  position: absolute;
  font-size: 52px;
  left: 325px;
  top: 370px;
  text-align: center;
  width: 100px;
  font-family: 'arial';
  color: #000;
}


/****************** NEW WHEEL END ********************************/

.mt_whellBottom{
  margin-top: -70px;
  width: 96% !important;
  left: 2%;
}

.mt_matkaRes span{ line-height: 0; }
.mt_matkaRes {
  padding: 10px;
  color: #fff;
  border-radius: 33px;
  margin-top: 6px;
  background: #003400;
  border: solid 3px #009100;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}

.matkaRes2 {
  padding: 8px;
  color: #fff;
  border-radius: 33px;
  margin-top: 5px;
  background: #003400;
  border: solid 3px #009100;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
}

.chipImg{
  cursor: pointer;
}

.mt_overlay_text {
  text-align: center;
  position: relative;
  /* top: 20%; */
  right: 0%;
  transform: translate(0%, -205%);
  font-size: 25px;
  /* bottom: -24%; */
  color: black !important;
}