.FGAfmntRightButtonPanel{
    width: 180px;
    height: 240px;
    position: absolute;
    right: 20px;
    top: 40vh;
}



.FGAfmntRightButtonPanel .buttonOne:hover{
    background-color: #b52f3f;
}
.FGAfmntRightButtonPanel .buttonOne{
    width: calc(100% - 5px);
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background-color: #f40037;
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;

}




.FGAfmntRightButtonPanel label{
    width: 100px;
    height: 40px;
    color: black;
    background-color: blanchedalmond;
    margin-top: 5px;

}
.FGAfmntRightButtonPanel input{
    width: 40px;
    height: 25px;
    margin-left: 20px;
    margin-top: 5px;

}
