td.num.green h3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 38%;
}

.EUR_Cover {
  position: fixed;
  background: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  display: none;
}

.z_350 {
  z-index: 350;
}

.indEuroulette_chips {
  background: #0b1017;
  text-align: center;
  font-size: 35px;
  padding: 10px;
  width: 20%;
  height: 660px;
}

.indEuroulette_chips .funBal div:nth-child(1) {
  background: #070a0e;
  color: #f40037;
  padding: 10px;
  font-family: "digital";
  font-size: 40px;
}

.indEuroulette_chips .funBal div:nth-child(2) {
  padding: 10px;
  color: #fff;
  font-family: "digital";
  background: transparent;
}

.indEuroulette_chips .funBal {
  background: transparent;
}

.indEuroulette_chips .amChipCard.am_chip {
  padding: 7px;
  width: 33.3%;
  float: left;
}

.indEuroulette_chips .amChipCard.am_chip img {
  width: 90px;
  float: none;
  margin: 0px;
}

.indErlt .roulette .red {
  background: #f40037;
}

.indErltBtns button.ar_bt_1:nth-child(odd) {
  background: radial-gradient(#00de36, #275001);
}

.indErltBtns button.ar_bt_1:nth-child(even) {
  background: radial-gradient(#de0032, #72011a);
}

.indErltBtns button.ar_bt_1 {
  width: 100%;
  /* font-size: 26px; */

  /* .............................. */
  font-size: 30px;
  font-family: 'Poppins';
  /* ........................... */

  color: #fff;
  padding: 27px;
  border: none;
  margin: 7px 0 7px 0;
}

.winPopEurpRlt {
  display: none;
  position: fixed;
  right: 0;
  width: 800px;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #9a5b0b, #f5d851, #9a5b0b);
  height: -moz-fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
  z-index: 999;
  padding: 21px;
  justify-content: center;
  align-items: center;
  color: #000;
  border: solid 6px #edcc4a;
  text-align: center;
  box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
  animation: targetWin 2.8s 1;
  transform: translate(-1000px, 0px);
  flex-direction: column;
  top: 0;
  bottom: 0;
  font-weight: bold;
  box-shadow: 1px 1px 62px 3px rgb(236 203 74);
}

.popBorder {
  display: flex;
  justify-content: center;
}

@keyframes targetWin {
  0% {
    transform: translate(-500px, 0px);
  }

  20% {
    transform: translate(0px, 0px);
  }

  80% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(2000px, 0px);
  }
}

.indErltBtns button.ar_bt_1:disabled {
  background: radial-gradient(#404040, #8d8d8d);
}

.eur_userLbl {
  background: #000;
  color: #fff;
  font-size: 22px;
  height: 92px;
  border: solid 2px #5b5b5b;
  border-radius: 5px;
  padding: 15px;
  width: 350px;
  margin-bottom: 15px;
  border: solid 2px #5b5b5b;
  display: flex;
  justify-content: center;
  align-items: center;
}

/****************** NEW WHEEL START ********************************/

* {
  box-sizing: border-box;
}

.er_wheelAnim img {
  width: 100%;
}

.er_wheelAnim {
  position: relative;
  width: 480px;
  height: 480px;
  margin: auto;
  border-radius: 50%;
}

.er_wheelAnimOne {
  width: 480px;
  height: 480px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  transform: scale(1.78, 0.82);
  margin-top: 45px;
}

.er_wheelAnimParent {
  width: 480px;
  height: 480px;
  margin: auto;
  left: 0;
  right: 0;
  position: relative;
  -webkit-animation: er_spin 25s linear infinite;
  -moz-animation: er_spin 25s linear infinite;
  animation: er_spin 25s linear infinite;

}

@-moz-keyframes er_spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes er_spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes er_spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.er_ballBg {
  position: absolute;
  width: 480px;
  height: 480px;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-100px);
}



img.er_rollBall {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.5s;
  transform: translateY(-165px);
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  box-shadow: 2px 2px 9px -1px rgb(0 0 0 / 50%);
  border-radius: 50%;
}


.er_bollWrap {
  width: 480px;
  height: 480px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}


/* .er_WheelWraper {
  width: 1250px;
  height: 560px;
  position: relative;
  margin: auto;
  
  transform: scale(0.7, 0.8);
  top: -40px;
} */
.er_WheelWraper {
  width: 1250px;
  height: 560px;
  position: relative;
  margin: auto;
  transform: scale(0.7, 0.8);
}


.er_wheel_poll img {
  width: 1250px;
  height: 560px;
}

.er_wheel_poll {
  margin: auto;
  position: absolute;
  z-index: 2;
  transform: scale(1.4, 1.3);
  top: 45px;
  left: -4px;
}

.er_wheelBorder {
  margin: auto;
  position: absolute;
  width: 1250px;
  height: 560px;
}

/****************** NEW WHEEL END ********************************/

#erRoutTimerWheel {
  transition: all 0.5s ease 0s;
  transform-origin: center top;
  margin: auto;
  left: 0;
  right: 0;
}

.eurpRouletteTable {
  top: 20px;
}

.roulette {
  margin-top: -20px !important;
  /* margin-top: 0px !important; */
}

.eurpRouletteTable {
  margin-bottom: 28px !important;
}

.mainVal::after,
.boxCntr::after,
.btmVal::after,
.cntrVal::after,
.leftVal::after,
.boxLeft::after,
.boxBtm::after,
.boxCrnr::after,
.boxTop::after,
.boxRight::after {
  background: #58000000;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 90;
}







.chip0 {
  position: absolute;
  color: red;
  font-size: 33px;
  right: 337px;
  top: 27px;
  margin: 0px;
}

.chip1 {
  color: red;
  position: absolute;
  right: 193px;
  top: 26px;
  font-size: 33px;
  margin: 0px;
}

.chip2 {
  color: red;
  position: absolute;
  right: 53px;
  top: 33px;
  font-size: 28px;
  margin: 0px;

}

.chip3 {
  color: red;
  position: absolute;
  right: 325px;
  top: 150px;
  font-size: 28px;
  margin: 0px;

}

.chip4 {
  color: red;
  position: absolute;
  right: 185px;
  top: 152px;
  font-size: 25px;
  margin: 0px;
}

.chip5 {
  color: red;
  position: absolute;
  right: 55px;
  top: 156px;
  font-size: 22px;
  margin: 0px;
}

.chip6 {
  color: red;
  position: absolute;
  right: 326px;
  top: 274px;
  font-size: 20px;
  margin: 0px;
}

.chip7 {
  color: red;
  position: absolute;
  left: 200px;
  top: 275px;
  font-size: 20px;
  margin: 0px;
}

.chipImg_er {
  cursor: pointer;
  height: 130px;
}

.er_overlay-tex {
  text-align: center;
  position: relative;
  top: 46%;
  right: 50%;
  /* transform: translate(50%, -202%); */
  transform: translate(50%, -365%);
  font-size: 25px;
  color:black !important ;
}

.chipsTable_eurotable {
  /* width: 75px; */
  float: left;
}

.table_ernt {
  margin-top: 33px !important;
}

.ernt_histTxt {
  text-align: center;
}