
  .tpLogoMenu {
      box-shadow: 0px 0px 15px -2px rgb(0 0 0 / 50%);
      z-index: 9;
  }
  .loginButtons {
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .loginButtons button {
    background: linear-gradient(45deg, #f7c749, #996402, #f7c749);
    font-size: 14px;
    width: 100px;
    border: solid 1px #653b0a;
    height: 40px;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
  }
