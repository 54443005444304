.funTargetCover.block {display: block;}
.funTargetCover.none {display: none;}
.funTargetCover {
    position: fixed;
    top: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    background:transparent;
    /* background: transparent; */
    /* background: #0000005c; */
    z-index: 300;
}
 
.FGAfunTargetTimerBetPanel{
    width: 100%;
    position: absolute;  
    float: left;
    display: flex;
}
.FGAfunTargetTimerBetPanel .buttonZero {
    float: left;
    background: linear-gradient(#0d0d0d,#fff,#0d0d0d,#fff,#0d0d0d);
    flex: 1 1;
    text-align: center;
    border-radius: 15px;
    height: 190px;
    padding: 6px;
    overflow: hidden;
    width: 180px;
    margin-left: 25px;
}

.FGAfunTargetTimerBetPanel .buttonZero .buttonZeroBg{
    background:  linear-gradient(#c1c1c1 45%,#404040 50%);
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
} 
.FGAfunTargetTimerBetPanel .buttonZero .buttonOneBg{ 
    background:  linear-gradient(#c9fd45 45%,#425a09 50%);
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
}
.funTTBet {flex: 1; padding: 0px 0.5vh;font-size: 18px;font-weight: bold;}
.funTTBet .valueTwo, .funTTBet .valueOne { height: 50%; width: 100%; float: left; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
}
.funTTBet .valueTwo {font-size: 45px;color: #fff;}
.funTTBet .valueOne {font-size: 60px;}
.borderLight_Ftarget {
    width: 66vh;
    height: 60.8vh;
    position: absolute;
    left: -2px;
    right: 0;
    margin: auto;
    z-index: 9;
    border-radius: 50%;
    top: 11.8vh;
    animation: trgt_time_Light 0.4s infinite;
}
.betPanelMrg {margin-top: 50px;}
@keyframes trgt_time_Light {
    0% {
      border: dotted 8px #2d0f0f;
    }
    50% {
      border: dotted 8px #ff0;
    }
    100% {
      border: dotted 8px #2d0f0f;
    }
  }
  .fun_trg_timer_btns {margin-top: -16vh;}
 
    @media only screen and (max-width: 764px) {
        .fun_btns {padding: 5px;}
        .fun_btns .fd.m_b_15 {margin-bottom: 5px;}
        .fun_btns .fd.m_t_15 {margin-top: 5px;}
    }

    /* .jackpotFTT {
        border-radius: 100%;
        z-index: 10;
        position: absolute;
        overflow: hidden;
        
        left: 0;

        right: 0;
        margin: auto;
    } */
    /* img.jack_1 {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 20;
    } */

    img.jack_1 {
        width: 209px;
        height: 204px;
        position: absolute;
        top: 279px;
        left: 624px;
        z-index: 1200;
    }

    .jockpotBox {
        background: radial-gradient(farthest-side at 20% 15%,#7d7d7d, black, black);
        z-index: 9;
        width: 300px;
        height: 300px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        margin: auto;
        top: 0;
        bottom: 0;
        margin-top: 15px;
    }
    .funTargetWinImg {
        position: fixed;
        right: 0;
        width: 800px;
        left: 0;
        right: 0;
        background: linear-gradient(90deg, #3d1160, #20034e, #3d1160);
        height: fit-content;
        font-size: 28px;
        margin: auto;
        z-index: 999;
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: solid 12px #cf96ff61;
        text-align: center;
        box-shadow: 1px 1px 44px 33px rgb(0 0 0/50%);
        /* animation: funtargetTimerResult 3s 1; */
        animation: funtargetTimerResult 2s 1;

        display: flex;
        flex-direction: column;
    }
   .funTargetWinImg span.cong {
        font-size: 40px;
        color: #ffc500;
    }
    @keyframes funtargetTimerResult{
        0% { transform: translate(0px, 0px); }
        20% { transform: translate(0px, 350px); }
        80% { transform: translate(0px, 350px); }
        100% { transform: translate(0px, 2000px); }
    }
    

    .target_chips {width: 20%;float: left;}
    .target_table {width: 60%;float: left;}
    .target_btns {width: 20%;float: left;}

    div#marqueeTag img {
        padding: 5px;
        width: 80px;
        width: 80px;
        margin-top: 6px;
    }

    div#jackpotImg {
        position: absolute;
        background: radial-gradient(farthest-side at 20% 15%,#7d7d7d, black, black);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        /* padding: 8vh; */
        align-items: center;
    }
    div#jackpotImg img {
      width: 150px;
      height: 150px;
    }
 .titleImg.trgeImg {
     height: 95px;
 }
 .targetTimerInd .table.HistoryTbl {
     margin-left: -33%;
 }
 .targetTimerInd {
     background: linear-gradient(45deg, #1a814c, #003a64);
 }
 .targetTimerLables .funBal_Target div:nth-child(1) {
    background: #ffc500;
    color: #000;
    text-align: center;
    padding: 15px;
    font-size: 28px;
    font-weight: bold;
}
.targetTimerLables .funBal_Target div:nth-child(2) {
    background: #ff6a00;
    color: #fff;
    padding: 5px;
    font-size: 28px;
    text-align: center;
}

.targetTimerLables {
    display: flex;
    width: 38%;
}
.targetTimer_btnPanel {
    width: 36%;
    float: right;
    display: flex;
}
.targetTimer_btnPanel button:disabled {
    background: linear-gradient(#a9a9a9, #1a1a1a);
    border: solid 5px #7a7a7a;
}
.targetTimer_btnPanel button {
    background: linear-gradient(#ffda00, #ff0000);
    font-size: 28px;
    font-weight: bold;
    border: none;
    padding: 27px;
    border-radius: 10px;
    border: solid 5px #ffc500;
    margin-bottom: 25px;
}

.targetTimerInd table.HistoryTbl tr td {
    background: linear-gradient(#d12708, #f15e00) !important;
}

.targetTimer_Sec {
    background: radial-gradient(#ffc500, #ff6a00);
    width: 125px;
    height: 125px;
    border-radius: 50%;
    text-align: center;
    font-size: 55px;
    font-weight: bold;
    color: #fff;
    border: solid 4px #ff2100dd;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 10px;
    float: right;
    margin-right: 50%;
    margin-top: 50px;
}

.targetTimerBorder {
    width: 778px;
    height: 778px;
    border: solid 41px #013c63;
    margin: auto;
    border-radius: 50%;
    box-shadow: inset 1px 1px 8px 19px rgb(0 0 0 / 50%);
    position: relative;
  }
  .targetTimerBorderLights {
    width: 748px;
    height: 748px;
    border: dotted 16px #ffffff;
    margin: auto;
    border-radius: 50%;
    position: absolute;
    left: -25px;
    top: -25px;
    animation: targetTimerLights 0.5s infinite;
    text-align: center;
  }

  @keyframes targetTimerLights {
      0% { border: dotted 16px #ffffff;}
      50% { border: dotted 16px #f1a501;}
      100% { border: dotted 16px #ffffff;}
  }

  img.targetTimerArrow {
    margin-left: 680px;
    right: 0;
    margin-top: -35px;
    width: 79px;
    /* z-index: 400; */
    z-index: 999 !important;
    position: relative;
}

.funBal_Target.fd {
    box-shadow: 1px 1px 9px 4px rgb(0 0 0/50%);
}



.targetTimerInd table.HistoryTbl tr td {
    color: #fff;
    background: linear-gradient(#d12708, #f15e00);
    text-align: center;
    padding: 11px 1px;
    border: solid 1px #fff;
    font-size: 30px;
}


.targetTimerInd .funTrgtNon table.HistoryTbl {
    width: 140%;
    float: left;
    position: relative;
    left: -43%;
    font-size: 16px;
    margin-top: 150px !important;
}
.targetTimerUser {
    color: #fff;
    font-size: 28px;
    background: #ddd;
    width: 350px;
    border-radius: 5px;
    padding: 14px;
    background: #17774f57;
    /* margin-left: -158px; */
    border: solid 3px #126b54;
    margin-top: 10px;
    margin-bottom: 25px;
}

.targetTimerClose {
    position: absolute;
    z-index: 1024;
    margin-top: 10px;
    margin-top: 10px;
    width: 50px;
    right: 0;
}
.targetTimerClose button img {width: 100%;}
.targetTimerClose button {
    all: unset;
    width: 60px;
    height: 60px;
    cursor: pointer;
    float: right;
    margin-right: 10px;
    background: linear-gradient(#ffc500, #ff6a00);
    border-radius: 5px;
    border: solid 5px #ff6a00;
}

.funTrgtTimerBoard {
    width: 130%;
    float: left;
    border: 3px solid rgb(177 147 0 / 80%);
    border-radius: 10px;
    background-color: rgb(0 0 0 / 43%);
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    padding: 25px 10px;
    left: -30%;
    position: relative;
    font-size: 28px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-left: -12px;
    height: 180px;
    align-items: center;
    margin-top: 70px;
}

.targetTimer_Sec.timerYelloBorder { 
    animation: borderAnim 1s infinite;
}
@keyframes borderAnim {
    from {border: solid 4px #fffe00dd; }
    to {border: solid 4px #d60000dd; } 
}
.bottomFixFuntarget_timer {
    position: relative;
    height: -moz-fit-content;
    height: fit-content; 
    width: 100%;
    float: left;
    margin-top: 116px;
}

.ftt_wheelWraper {
    width: 800px;
    height: 800px;
    position: relative;
    margin: auto;
  }
  
  .ftt_wheelBorder img {
    width: 769px;
    height: 835px;
  }


  /****************** NEW WHEEL START ********************************/

* {box-sizing: border-box;}
.ftt_wheelAnim img { width: 100%;}
.ftt_wheelAnim {
    position: relative;
    margin: auto;
    border-radius: 50%;
    top: -90px !important;
}
.ftt_wheelAnimOne {
  width: 750PX;
  height: 600PX;
  margin: auto;
  left: 60px;
  right: 12px;
  /* top: 35px; */
  position: absolute;
  z-index: 0;
}


.ftt_wheelBorder {
    margin: auto;
    position: relative;
    z-index: 9;
    width: 720px;
    top: -125px;
}

.ftt_wheelWraper {
  width: 800px;
  height: 800px;
  position: relative;
  margin: auto;
}

.ftt_wheelBorder img {
  width: 769px;
  height: 835px;
}

/****************** NEW WHEEL END ********************************/


.jackpotFTT {
    border-radius: 100%;
    z-index: 10;
    position: absolute;
    overflow: hidden;
    top: 180px;
    left: 55px;
    /* width: 30vh; */
    /* height: 30vh; */
    right: 0;
    margin: auto;
}

.ftt_overallBox {
    width: 204px;
    height: 199px;
    position: absolute;
    overflow: hidden;
    top: 245px !IMPORTANT;
    border-radius: 50%;
    left: 610px;
    z-index: 10;
    display: block;
}

  .ftt_mainTrans {
    width: 1980px;
    height: 230px;
    left: 0;
    margin: auto;
    top: 100px;
    margin-top: -20px;
    margin-left: -10px;
  }

  .ftt_mainTrans img { 
    width: 220px;
    height: 240px;
    padding: 0px;
  }
  
  .ftt_scrollImages{
    animation: ftt_transBox 4s 0s linear infinite;
  }

  @keyframes ftt_transBox {
    0% { transform: translateX(-1900px); }
    100% { transform: translateX(0px); }
  }

  .ftt_overlaytext{
    text-align: center;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
    font-size: 25px;
    color:black !important ;
  }
  .funtrgetcover {
    position: absolute;
    top: 5px;
    left: -7px;
    width: 2305px;
    height: 2000px;
    /* background: red; */
    z-index: 100;
}